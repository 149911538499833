import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Modal,
    TeamDetails,
    InviteMembers
} from 'components';
import {
    HomeContext,
    MemberContext,
    ModalContext,
    OrganizationContext,
    TeamContext
} from 'contexts';
import { MEMBER_PAGE_SIZE, ORGANIZATIONS_LIST_URL } from 'actions';
import { useLocation } from 'react-router-dom';

export const AddTeam = ({ open = false, onCancel }) => {
    const location = useLocation();
    const { setNotificationMessage } = useContext(HomeContext);
    const { setAddTeam } = useContext(ModalContext);
    const { doCreateTeam } = useContext(TeamContext);
    const { doGetOrganizations, setOrganizations } = useContext(OrganizationContext);
    const { allMembers, doGetMembers, setAllMembers } = useContext(MemberContext);

    const { handleSubmit, register, errors, trigger, formState, reset } = useForm();
    const [mems, setMems] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [memberLoading, setMemberLoading] = useState(false);
    const [tempAllMembers, setTempAllMembers] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (open) doGetMembers({
            // page: 0,
            // limit: MEMBER_PAGE_SIZE,
            isSearching: true,
            sort: 'name_asc',
            listing: true
        });
    }, [open])

    useEffect(() => {
        if (!tempAllMembers.length) {
            setTempAllMembers(allMembers);
        }
    }, [allMembers])

    useEffect(() => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }, [mems])
    /*eslint-enable */

    const isOrgTeam = () => location.pathname.indexOf(ORGANIZATIONS_LIST_URL) > -1;

    const doAddTeam = data => {
        const {
            teamName,
            teamDescription,
            teamOrg
        } = data;
        
        const payload = {
            name: teamName,
            description: teamDescription,
            orgId: teamOrg,
            isOrgTeam: isOrgTeam()
        };
        
        if (mems.length > 0) {
            // payload.members = mems.map(member => {
            //     return {
            //         memberId: member.id,
            //         email: member.id ? member.description : member.title,
            //         teamRoles: [
            //             {
            //                 roleName: data[`teamRole${member.id}`]
            //             }
            //         ]
            //     }
            // });
            // console.log(mems);
            payload.members = mems.map(mem => {
                const newMem = {
                    email: mem.id ? mem.description : mem.title,
                }
                if (!mem.isInviteEmail) {
                    newMem['memberId'] = mem.id
                }
                newMem.teamRoles =  [
                        {
                            roleName: data[`teamRole${mem.id}`]
                        }
                    ]
                
                return newMem
            })
        }
        // if (!mems.length) return;

        doCreateTeam(payload, () => {
            reset();
            setMems([]);
            setAddTeam(false);
            setNotificationMessage(`
                <p><strong>${payload.name}</strong> team has been added successfully!</p>
            `);
        });
    }

    const onHideAddTeamModal = () => {
        reset();
        onCancel();
        setOrganizations([]);
        setMems([]);
    }

    const getOrganizations = value => {
        if (isOrgTeam()) return;
        if (formState.submitCount > 0) trigger('teamOrg');
        setSearchLoading(true);

        doGetOrganizations({
            name: value,
            isSearching: true
        }, () => {
            setSearchLoading(false);
        }, false, true);
	};

    const getMembers = value => {
        setMemberLoading(true);

        doGetMembers({
            term: value,
            page: 0,
            limit: MEMBER_PAGE_SIZE,
            isSearching: true,
            sort: 'name_asc',
            listing: true
        }, () => {
            setMemberLoading(false);
        }, false);
    };

    const handleAddItem = () => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }

    return (
        <Modal
            open={open}
            className="tr__add-team"
            onCancel={onHideAddTeamModal}
            title="Add New Team"
            submitButton
            submitButtonLabel="Add"
            footerLabel="We will invite all new members on behalf of you"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(doAddTeam)}>
                    {children}
                </form>
            )}
        >
            <TeamDetails 
                title="Team Details"
                isEditable={true}
                register={register}
                errors={errors}
                onInputChange={getOrganizations}
                searchLoading={searchLoading}
                formState={formState}
            />
            <InviteMembers 
                title="Invite Members" 
                isMember={false}
                register={register}
                members={allMembers}
                errors={errors}
                selectedMembers={mems}
                onAddItem={handleAddItem}
                setSelectedMembers={setMems}
                onInputChange={getMembers}
                searchLoading={memberLoading}
                isOrgTeam={isOrgTeam()}
                isNew={true}
                formState={formState}
            />
        </Modal>
    )
};

AddTeam.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};