import React from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';

export const ContactDetails = ({ contactDetails = {} }) => {

    return <InfoBlock
        className="tr__details-box"
        title="Contact Details"
    >
        <InfoRow>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Shipper address"
                        value={contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Consignee"
                        value={contactDetails.consignee ? contactDetails.consignee : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                <InfoCell
                        className="col-sm-6"
                        label="Consignee address"
                        value={contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Notify party"
                        value={contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Notify party address"
                        value={contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Service contract number"
                        value={contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                    />
                </div>
            </div>
            <h4 className="h4 f-medium tr__info-block--label">
                <span>Contact Information</span>
            </h4>
            <div className="col-12 col-sm-3 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Shipper contact"
                        value={contactDetails && contactDetails.shipperContact ? contactDetails.shipperContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Shipper fax"
                        value={contactDetails && contactDetails.shipperFax ? contactDetails.shipperFax : ''}
                    />
                </div> 
            </div>
            <div className="col-12 col-sm-3 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Consignee contact"
                        value={contactDetails && contactDetails.consigneeContact ? contactDetails.consigneeContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Consignee fax"
                        value={contactDetails && contactDetails.consigneeFax ? contactDetails.consigneeFax : ''}
                    />
                </div> 
            </div>
            <div className="col-12 col-sm-3 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Notify contact"
                        value={contactDetails && contactDetails.notifyContact ? contactDetails.notifyContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Notify fax"
                        value={contactDetails && contactDetails.notifyFax ? contactDetails.notifyFax : ''}
                    />
                </div> 
            </div>
        </InfoRow>
    </InfoBlock>;
};

ContactDetails.propTypes = {
    contactDetails: PropTypes.object
};
