import React, { useContext, useMemo, useState, } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import {
  Modal,
} from 'components';
import { ModalContext, TemplateContext } from 'contexts';
import { TemplateTaskHeader } from 'components/pages/TemplateDetails';
import { get, find, isEmpty } from 'lodash';
import { roles } from 'utils';
import { Collaborators } from 'components/pages/ActiveShipmentsDetails';
import lodash from "lodash";
import { MEMBER } from 'actions';

export const PreviewTemplates = ({ open = false, onCancel, skip }) => {
  const { setPreviewTempalates } = useContext(ModalContext);
  const { templatesDetails } = useContext(TemplateContext);
  const [limit] = useState(4);

  const handleCloseModal = () => {
    setPreviewTempalates(false);
  }

  const allCollaborators = useMemo(()=>{
    if(!templatesDetails) return [];
    const details = templatesDetails;
    const { collaborators } = details;
    let tempCollaborators = [];
    lodash.forEach(collaborators,(item) =>{
        tempCollaborators=lodash.concat(tempCollaborators,item.collaborators)
    });

    return tempCollaborators.map(collaborator=> {
        return {
            _id: collaborator.collaboratorId,
            avatar: collaborator.avatar,
            type: collaborator.type,
            roles: collaborator.roles,
            name: collaborator.type === MEMBER.toUpperCase() ? collaborator.username : collaborator.teamName,
            orgName: collaborator.organization ? collaborator.organization.name : '',
            email: collaborator.type === MEMBER.toUpperCase() ? collaborator.email : null,
        }
    });

}, [templatesDetails])

  return (
    <Modal
      open={open}
      className="tr__edit-extracted-info no-padding"
      onCancel={handleCloseModal}
      title='Preview Templates'
      isBackDropClickable={false}
      footerLabel=""
    >
        <div className="tr--preview-header page-box">
            <div className="title-header mbx2">
                <h2 class="h2 f-medium mbx1" title={templatesDetails && templatesDetails.name}>{templatesDetails && templatesDetails.name}</h2>
                <h6 class="text-grey f-medium" title={templatesDetails && templatesDetails.desc}>{templatesDetails && templatesDetails.desc}</h6>
            </div>
            <div className="mbx3">
                    <Collaborators
                        members={allCollaborators}
                        skip={5}
                        noneButton={true}
                    />
            </div>
            <TemplateTaskHeader className="mbx1" />
            {templatesDetails && templatesDetails.sections.map((section, sectionIndex) => {
                return (
                    <div className="tr__template-details page-box height mbx3" key={sectionIndex}>
                        <div className="tr__template-details--top-header f-medium" title={section.name}>
                            {section.name}
                        </div>
                        <div className="tr__template-details--box">
                            {section.tasks.events && section.tasks.events.length > 0 && (
                                <div className="tr__template-task-item--table">
                                    <div className="table--header">
                                        <h4 class="h4 f-medium">Events</h4>
                                    </div>
                                    {section.tasks.events.map((event, eventIndex) => {
                                        return (
                                            <div className="table--body d-flex table-body justify-content-between" key={eventIndex}>
                                                <div className="column name">
                                                    <h4 className="h4 f-medium">
                                                        <i className="icon-check" />
                                                        <span title={event.name}>{event.name}</span>
                                                    </h4>
                                                </div>
                                                <div className="column partner">
                                                    <div className="partners">
                                                            {event.roles && event.roles.length > 0 && (
                                                            <>
                                                            {event.roles.slice(0, limit).map((role, roleIndex) => (
                                                                < i className={get(find(roles,['id',role]),'icon')} />
                                                            ))}
                                                            </>
                                                            )}
                                                            
                                                        {limit < event.roles.length && (
                                                            <span className="f-medium more">+{event.roles.length - limit}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="column assignee">
                                                    <Collaborators
                                                        members={event.partners && event.partners.length > 0 ? event.partners : []}
                                                        skip={5}
                                                        noneButton={true}
                                                    />
                                                </div>
                                                <div class="column preview-date d-flex align-items-center justify-content-between">
                                                    {(event && event.dueDate.eventBasedDate.typeOfEvent !== null && !isEmpty(event.dueDate.eventBasedDate)) ? (
                                                        <p className="tr__badge mlx2">{`${event.dueDate.eventBasedDate.typeOfEvent} ${(event.dueDate.eventBasedDate.adjustDays > 0) ? "+" + event.dueDate.eventBasedDate.adjustDays : event.dueDate.eventBasedDate.adjustDays} `}</p>
                                                    ) : (<p></p>)}
                                                </div>
                                            </div>
                                        )
                                    })}
                             </div>
                            )}
                           
                           {section.tasks.documents && section.tasks.documents.length > 0 && (
                            <div className='tr__template-task-item--table mtx3'>
                                <div className='table--header'>
                                    <h4 className="h4 f-medium">Documents</h4>
                                </div>
                                {section.tasks.documents.map((doc, docIndex) => {
                                return (
                                    <div className="table--body d-flex table-body justify-content-between" key={docIndex}>
                                        <div className="column name">
                                            <h4 className="h4 f-medium">
                                                <i className="icon-check" />
                                                <span title={doc.name}>{doc.name}</span>
                                            </h4>
                                        </div>
                                        <div className="column partner">
                                            <div className="partners">
                                                    {doc.roles && doc.roles.length > 0 && (
                                                    <>
                                                    {doc.roles.slice(0, limit).map((role, roleIndex) => (
                                                        < i className={get(find(roles,['id',role]),'icon')} />
                                                    ))}
                                                    </>
                                                    )}
                                                    
                                                {limit < doc.roles.length && (
                                                    <span className="f-medium more">+{doc.roles.length - limit}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div class="column assignee">
                                            <Collaborators
                                                members={doc.partners && doc.partners.length > 0 ? doc.partners : []}
                                                skip={5}
                                                noneButton={true}
                                            />
                                        </div>
                                        <div class="column preview-date text-center">
                                            {(doc.dueDate.eventBasedDate && doc.dueDate.eventBasedDate.typeOfEvent !== null && !isEmpty(doc.dueDate.eventBasedDate))  ? (
                                                <p className="tr__badge mlx2">{`${doc.dueDate.eventBasedDate.typeOfEvent} ${(doc.dueDate.eventBasedDate.adjustDays > 0) ? "+" + doc.dueDate.eventBasedDate.adjustDays : doc.dueDate.eventBasedDate.adjustDays} `}</p>
                                            ) : (<p></p>)}
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                           )}  
                        </div>
                    </div>
                )
            })}
        </div>
    </Modal>
  )
};

PreviewTemplates.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
}