import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import avatar from 'assets/images/avatar.png';
import {
    // Button,
    Comment,
    // Textarea,
    FilePreview
} from 'components';
import { AuthContext, BookingRequestContext, UploadFileContext, NotificationContext } from 'contexts';
import { BR_COMMENTS_PAGE_SIZE, FILE_TYPE_BR_CMT_DOCS, GET_FILE } from 'actions';
// import { getErrorMessage } from 'utils';
import { NOTIFICATION_AUTH } from 'actions/constants';
import Pusher from 'pusher-js';

export const CommentTab = ({
    errors = [],
    register = () => { }
}) => {
    const { userInfo, loggedInUser } = useContext(AuthContext);
    const {
        bookingRequest,
        doGetBookingRequestComments,
        comments,
        totalComments,
        currentPageComments,
        doAddBookingRequestComment,
        doGetLatestComment
    } = useContext(BookingRequestContext);
    const { doUploadFile } = useContext(UploadFileContext);

    const [files, setFiles] = useState([]);

    const { config } = useContext(NotificationContext);

    /*eslint-disable */
    useEffect(() => {
        if (bookingRequest) {
            doGetBookingRequestComments(bookingRequest._id, {
                page: 0,
                limit: BR_COMMENTS_PAGE_SIZE
            })
        }
    }, [bookingRequest])

    useEffect(() => {
        if (bookingRequest && config && config.key && config.cluster) {
            const pusher = new Pusher(config.key, {
                cluster: config.cluster,
                authEndpoint: NOTIFICATION_AUTH,
                auth: {
                    headers: {
                        'authorization': loggedInUser
                    }
                }
            });

            const bookingRequestChannel = pusher.subscribe(`private-${bookingRequest._id}`);
            bookingRequestChannel.bind('COMMENT_BOOKING', data => {
                if (userInfo._id != data.userId) {
                    doGetLatestComment(bookingRequest._id);
                }
            });
        }
    }, [config, bookingRequest])
    /*eslint-enable */

    const loadMoreComments = () => {
        doGetBookingRequestComments(bookingRequest._id, {
            page: currentPageComments + 1,
            limit: BR_COMMENTS_PAGE_SIZE,
            isLoadMore: true
        });
    }

    const handleFilesUpload = files => {
        setFiles(oldFiles => [...oldFiles, ...files]);
    }

    const sendComments = async (data, callback) => {
        let tmpAttachments = [];
        let promises = [];
        for (let file of files) {
            promises.push(doUploadFile({
                fileName: file.name.split('.').slice(0, -1).join('.'),
                type: FILE_TYPE_BR_CMT_DOCS,
                subType: FILE_TYPE_BR_CMT_DOCS,
                fileExtension: file.name.split('.').pop(),
            }, file.data, loggedInUser));
        }

        tmpAttachments = await Promise.all(promises);
        tmpAttachments = tmpAttachments.map(item => {
            return {
                awsId: item.id,
                fileName: item.name,
            };
        });

        setFiles([]);

        doAddBookingRequestComment(bookingRequest._id, {
            content: data.comments,
            files: tmpAttachments,
        });

        callback();
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    return (
        <div className="comments">
            {totalComments > 0 ? (
                <>
                    <p>{totalComments} comment{totalComments > 1 ? 's' : ''}</p>
                    {comments.map((comment, commentIndex) => (
                        <div className="comments-item d-flex" key={commentIndex}>
                            <div className="comments-item--avatar mrx2">
                                <img src={comment.creator.avatar ? GET_FILE + comment.creator.avatar : avatar} alt={comment.user} />
                            </div>
                            <div className="comments-item--content mbx3">
                                {comment.creator && (
                                    <div className="user">
                                        <h6 className="h6 f-medium mbx1">{comment.creator.base?.delete ? "deleted_" + (comment.creator._id || comment.creator.id) : comment.creator.name}</h6>
                                        <p className="f-medium">{moment(comment.base.createdDate).format('DD MMM YYYY | hh:mm A')}</p>
                                    </div>
                                )}
                                <div className="content mtx2">
                                    <pre>{comment.content}</pre>
                                </div>
                                {comment.files && comment.files.length > 0 && (
                                    <div className="gallery mtx3">
                                        {comment.files.map((item, itemIndex) => (
                                            // <img src={item} key={itemIndex} alt={itemIndex} />
                                            <FilePreview
                                                file={item.url}
                                                fileExtension={item.fileName.split('.').pop()}
                                                fileName={item.fileName}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {totalComments > ((comments && comments.length) || 0) && (
                        <div className="load-more-link mtx2 text-center">
                            <p className="tr__link" onClick={loadMoreComments}>Load more comments...</p>
                        </div>
                    )}
                </>
            ) : (
                <p className="mbx3">No comments</p>
            )}

            {userInfo && (
                <>
                    <Comment
                        className='comment-tab-br'
                        user={userInfo}
                        onSendComment={sendComments}
                        isHaveAttachment={true}
                        attachments={files}
                        handleAddAttachments={handleFilesUpload}
                        handleRemoveAttachment={handleFileRemove}
                    />
                    {/*<div className="comments-item form mtx3">*/}
                    {/*    <div className="comments-item--field d-flex">*/}
                    {/*        <div className="comments-item--avatar mrx2">*/}
                    {/*            <img src={userInfo.avatar || avatar} alt={userInfo.name} />*/}
                    {/*        </div>*/}
                    {/*        <div className="comments-item--content d-flex align-items-end">*/}
                    {/*            <div className="mrx2">*/}
                    {/*                <div className="form-btns">*/}
                    {/*                    <h6 className="h6 f-medium mbx1">You</h6>*/}
                    {/*                    <Textarea*/}
                    {/*                        name="commentMessage"*/}
                    {/*                        placeholder="Add your comment"*/}
                    {/*                        icon="icon-paperclip"*/}
                    {/*                        iconPosition="right"*/}
                    {/*                        refs={register({ required: true })}*/}
                    {/*                        error={!!errors.commentMessage}*/}
                    {/*                        errorMessage={getErrorMessage(errors.commentMessage, 'Comment')}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <Button*/}
                    {/*                isSubmitBtn={true}*/}
                    {/*            >*/}
                    {/*                Send*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>
            )}
        </div>
    )
}

CommentTab.propTypes = {
    errors: PropTypes.any,
    register: PropTypes.func,
    reset: PropTypes.func
}


