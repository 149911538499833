import React, {useState, useContext, useEffect} from 'react';
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    CheckboxGroup,
    Dropdown,
    Datepicker,
    Button,
    MultiSelect,
    Input
} from 'components';
import {
    taskRoles,
    shipmentTypes,
} from 'utils';
import { ActiveShipmentsContext, HomeContext, ModalContext, ShipmentInfoContext, TrackShipmentContext } from 'contexts';
import { ACTIVE, ACTIVE_SHIPMENT_PAGE_SIZE, CARRIER_PAGE_SIZE, DESTINATION, EMPTY, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';

export const ShipmentFilter = ({ open = false, onCancel, currentTab }) => {
    const { handleSubmit, register } = useForm();

    const { 
        doGetActiveShipments, 
        doGetBookingDetails,
        bookingDetailsNumber
    } = useContext(ActiveShipmentsContext);
    const { setActiveShipmentFilter } = useContext(ModalContext);
    const { shipmentFields, doGetShipmentFiels } = useContext(ShipmentInfoContext);
    const { carriers, doGetCarriers, origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { setFilterParamsShipment } = useContext(HomeContext);

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [selectedBkNum, setSelectedBkNum] = useState([]);
    const [selectedBlNum, setSelectedBlNum] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [VDLDates, setVDLDates] = useState(null);
    const [VADDates, setVADDates] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [shipmentValue, setShipmentValue] = useState(null);
    const [selectShipmentKey, setSelectShipmentKey] = useState(null);
    const [shipmentKeys, setShipmentKeys] = useState([]);

    /*eslint-disable*/
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        doGetBookingDetails();
        doGetShipmentFiels();
    }, [])  
    useEffect(() => {
        setShipmentKeys(shipmentFields);
    }, [shipmentFields])
    /*eslint-enable*/

    const handleSubmitFilter = data => {
        const { 
            blNumber, 
            bookingNo, 
            carrier, 
            origin, 
            destination,
            shipmentRole, 
            shipmentType,
            shipmentKey
        } = data;

        let payload = {
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: currentTab,
            status: ACTIVE,
        }
        if (shipmentRole) payload.role = shipmentRole;
        if (shipmentType) payload.type = shipmentType;
        if (carrier)      payload.carrier = selectedCarriers.map(carrier => carrier.id);
        if (origin)       payload.origin = origin;
        if (destination)  payload.destination = destination;
        if (blNumber)     payload.blNumb = selectedBlNum.map(blNum => blNum.id);
        if (bookingNo)    payload.bookingNo = selectedBkNum.map(bkNum => bkNum.id);
        if (VDLDates){
            payload.vdlStart = VDLDates.fromTime;
            payload.vdlEnd   = VDLDates.toTime;
        }
        if (VADDates) {
            payload.vadStart = VADDates.fromTime;
            payload.vadEnd   = VADDates.toTime;
        }
        if (shipmentKey && shipmentValue){
            payload.key = shipmentKey;
            payload.value = shipmentValue;
        } 

        setFilterParamsShipment(payload);

        doGetActiveShipments(payload, () => {
            setActiveShipmentFilter(false);
        });
    }

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value.trim(),
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        });
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    const handleClearFilterShipment = () => {
        setSelectedRoles([]);
        setSelectedOrigin(null);
        setSelectedDestination(null);
        setSelectedType(null);
        setVDLDates(null);
        setVADDates(null);
        setSelectedCarriers([]);
        setSelectedBkNum([]);
        setSelectedBlNum([]);
        setSelectShipmentKey("");
        setShipmentValue("");
    }

    const handleSearchBkNumber = (value) =>{

    }

    const handleSearchBlNumber = (value) => {

    }

    const handleVDLEvent = (dates) => {
        if (!dates) {
            const newVDLDates = {...VDLDates};
            delete newVDLDates.fromTime;
            delete newVDLDates.toTime;

            setVDLDates(newVDLDates);
            return;
        }
        const [from, to] = dates;

        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);
            setVDLDates({fromTime: fromTime, toTime: toTime});
            // console.log(fromTime, toTime)
        }
    
    }

    const handleVADEvent = (dates) => {
        if (!dates) {
            const newVADDates = {...VADDates};
            delete newVADDates.fromTime;
            delete newVADDates.toTime;

            setVADDates(newVADDates);
            return;
        }
        const [from, to] = dates;

        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);
            // console.log(from, to)
            setVADDates({fromTime: fromTime, toTime: toTime});
        }
    
    }

    const handleSearchShipmentKey = value => {
        if (value) {
            setShipmentKeys(shipmentFields.filter(item => item.label.toLowerCase() === value.toLowerCase()));
        } else {
            setShipmentKeys(shipmentFields);
        }
    }

    return (
        <Modal
            open={open}
            className="tr__shipment-filter"
            onCancel={onCancel}
            title='Filters'
            type="panel"
        >
            <form
                onSubmit={handleSubmit(handleSubmitFilter)}
            >
                <div className="tr__shipment-filter--group mbx2 pbx1">
                    <CheckboxGroup
                        label="Your Role"
                        name="shipmentRole"
                        className="big-label"
                        vertical
                        items={taskRoles}
                        labelClear={true}
                        values={selectedRoles}
                        onChange={roles => setSelectedRoles(roles)}
                        buttonMode={true}
                        refs={register}
                    >
                        <Button
                            className="icon outline mbx2"
                            icon="icon-reload"
                            type="secondary"
                            onClick={() => setSelectedRoles([])}
                        />
                    </CheckboxGroup>
                </div>
                <div className="tr__shipment-filter--group route mbx3 pbx3">
                    <span className="route-icon">
                        <i className="icon-location" />
                        <i className="icon-location" />
                    </span>
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="origin"
                        label="Port"
                        horizontalLabel="Origin:"
                        value={selectedOrigin}
                         options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedOrigin(value)}
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        horizontalLabel="Destination:"
                        value={selectedDestination}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedDestination(value)}
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
                <Datepicker
                    name="VDL"
                    label="Vessel Departure Date"
                    className="mbx4"
                    placeholder="Filter by date range"
                    range={true}
                    refs={register}
                    minDate={null}
                    start={VDLDates ? VDLDates?.fromTime : null}
                    end={VDLDates ? VDLDates?.toTime : null}
                    onDatesChange={handleVDLEvent}
                    defaultValue={`${VDLDates ? VDLDates?.fromTime + '-' + VDLDates?.toTime : EMPTY}`}
                />
                <Datepicker
                    name="VAD"
                    label="Vessel Arrival Date"
                    className="mbx4"
                    placeholder="Filter by date range"
                    range={true}
                    refs={register}
                    minDate={null}
                    start={VADDates ? VADDates?.fromTime : null}
                    end={VADDates ? VADDates?.toTime : null}
                    onDatesChange={handleVADEvent}
                    defaultValue={`${VADDates ? VADDates?.fromTime + '-' + VADDates?.toTime : EMPTY}`}
                />
                <div className="tr__shipment-filter--group mbx3 pbx3">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="shipmentType"
                        label="Type"
                        options={shipmentTypes.map(item => {
                            return {
                                key: item.id,
                                value: item.name,
                            };
                        })}
                        placeholder="Select Shipment Type"
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedType(value)}
                        value={selectedType}
                    />
                </div>
                <div className="tr__shipment-filter--group  mbx4">
                    <MultiSelect
                        className="mbx4"
                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {
                            return {
                                id: carrier.scacCode,
                                description: `${carrier.carrierName} (${carrier.scacCode})`
                            }
                        }) : []}
                        name="carrier"
                        label="Carrier"
                        value={selectedCarriers}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Search Carrier'
                        onChange={items => setSelectedCarriers(items)}
                        register={register}
                        onInputChange={onSearchCarrier}
                        searchLoading={searchLoading}
                        multiple={true}
                    />
                    <MultiSelect
                        className="mbx4"
                        options={bookingDetailsNumber?.bookingNumbers.map(item => {
                            return {
                                id: item,
                                description: item,
                            };
                        })}
                        name="bookingNo"
                        label="Booking No."
                        value={selectedBkNum}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Ex: 8083720028'
                        onChange={items => setSelectedBkNum(items)}
                        register={register}
                        onInputChange={handleSearchBkNumber}
                        multiple={true}
                    />
                    <MultiSelect
                        className="mbx4"
                        options={bookingDetailsNumber?.blNumbers.map(item => {
                            return {
                                id: item,
                                description: item,
                            };
                        })}
                        name="blNumber"
                        label="BL Number"
                        value={selectedBlNum ? selectedBlNum : []}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Ex: 8083720028'
                        onChange={items => setSelectedBlNum(items)}
                        register={register}
                        onInputChange={handleSearchBlNumber}
                        multiple={true}
                    />
                </div>
                <div className="tr__shipment-filter--group last mbx7">
                    <Dropdown
                        className="select mbx4"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="shipmentKey"
                        label="Shipment Information"
                        options={shipmentKeys && shipmentKeys.length > 0 ? shipmentKeys.map(item => {
                            return {
                                key: item.fieldName,
                                value: item.label,
                            };
                        }) : []}
                        placeholder="Select Shipment information"
                        dropdownPosition="center"
                        refs={register}
                        onInputChange={handleSearchShipmentKey}
                        onChange={value => setSelectShipmentKey(value)}
                        value={selectShipmentKey}
                    />
                    <Input
                        className="select mbx4"
                        name="shipmentValue"
                        label="Select value"
                        onChange={e => setShipmentValue(e.target.value)}
                        value={shipmentValue}
                    />
                </div>
                <div className="tr__shipment-filter--btns d-flex align-items-center">
                    <Button
                        className="icon outline"
                        icon="icon-reload"
                        type="secondary"
                        onClick={() => {
                            
                            handleClearFilterShipment();
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        type="primary"
                        isSubmitBtn={true}
                    >
                        Apply
                    </Button>
                </div>
            </form>
        </Modal>
    )
};

ShipmentFilter.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};
