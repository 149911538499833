import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Tab,
    TabPane,
    ContainerForm,
    BLNumberForm,
    BookingNoForm,
    SearchResults,
    NoResultSearch
} from 'components';
import { searchParams } from 'utils';
import {
    TAB_BL_NUMBER,
    TAB_CONTAINER,
    TAB_BOOKING_NO,
    SHIPMENT_DETAILS,
    MARK_NEW,
    TRACKER_BOOKING_BK,
    TRACKER_BOOKING_BL,
    TRACKER_BOOKING_BC
} from 'actions';
import { HomeContext, ModalContext, TrackShipmentContext } from 'contexts';
import { useHistory } from 'react-router-dom';

export const SearchForms = ({ searched, setSearched = () => {}, searchType }) => {
    const { setMarkAsShipmentType, setIsApplyDataSearch } = useContext(HomeContext);
    const {
        trackers,
        firstCarriers,
        firstLocode,
        setCarriers,
        setOrigins,
        setDestinations
    } = useContext(TrackShipmentContext);
    const { noResultSearch, setNoResultSearch } = useContext(ModalContext);
    const [resultShipment, setResultShipment] = useState('');
    const [isTrackerSubmitted, setIsTrackerSubmitted] = useState(false);
    const [bkParams, setBkParams] = useState({});
    const [blParams, setBlParams] = useState({});
    const [containerParams, setContainerParams] = useState({});
    const [currentTab, setCurrentTab] = useState(0);

    const history = useHistory();

    /* eslint-disable */
    useEffect(() => {
        if(noResultSearch) setNoResultSearch(false);
    },[])

    useEffect(() => {
        if(trackers && trackers.length > 0) { 
            setSearched(true);
        }
        if (isTrackerSubmitted && trackers.length === 0) {
            setNoResultSearch(true);
        }
    }, [isTrackerSubmitted])

    useEffect(() => {
        switch (searchType?.requestType) {
            case TRACKER_BOOKING_BK:
                setCurrentTab(0);
                setBkParams(searchType);
                break;
            case TRACKER_BOOKING_BL:
                setCurrentTab(1);
                setBlParams(searchType);
                break;
            case TRACKER_BOOKING_BC:
                setCurrentTab(2);
                setContainerParams(searchType);
                break;
            default:
                setCurrentTab(0);
                break;
        }
    })
    /* eslint-enable */

    const handleShipment = (flag) => {
        setIsTrackerSubmitted(true);
        setIsApplyDataSearch(true);
        switch (flag) {
            case TAB_CONTAINER:
                setResultShipment("Container no");
                break;
            case TAB_BL_NUMBER:
                setResultShipment("MBL no");
                break;
            case TAB_BOOKING_NO:
                setResultShipment("Booking no");
                break;
            default:    
        }
    }

    const handleSwitchTab = (tab) => {
        setCarriers(firstCarriers);
        if (tab === TAB_CONTAINER) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }

    return (
        <div className="tr__search-forms">
            <h4 className="h4 box-header">Track your shipment</h4>
            {searched ? (
                <SearchResults
                    searchParams={searchParams}
                    setSearched={setSearched}
                    result={resultShipment}
                    setIsTrackerSubmitted={setIsTrackerSubmitted}
                    className='result-track-shipment'
                />
            ) : (
                    <Tab initialTab={currentTab} onTabClick={handleSwitchTab}>
                        <TabPane tabName={TAB_BOOKING_NO} tabSlug={TAB_BOOKING_NO}>
                            <BookingNoForm onSubmit={handleShipment} trackerParams={bkParams} />
                        </TabPane>
                        <TabPane tabName={TAB_BL_NUMBER} tabSlug={TAB_BL_NUMBER}>
                            <BLNumberForm onSubmit={handleShipment} trackerParams={blParams} />
                        </TabPane>
                        <TabPane tabName={TAB_CONTAINER} tabSlug={TAB_CONTAINER}>
                            <ContainerForm onSubmit={handleShipment} trackerParams={containerParams} />
                        </TabPane>
                    </Tab>
                )}
            <NoResultSearch
                open={noResultSearch}
                onCancel={() => {
                    setNoResultSearch(false)
                    setIsTrackerSubmitted(false)
                }}
                title="Track Your Shipment"
                submitButton={true}
                submitButtonLabel="Create New Shipment"
                submitButtonType="primary"
                onSubmitClick={() => {
                    setNoResultSearch(false);
                    setMarkAsShipmentType(MARK_NEW);
                    history.push(SHIPMENT_DETAILS);
                }}
            />
        </div>
    )
};

SearchForms.propTypes = {
    searched: PropTypes.bool,
    setMarkers: PropTypes.array
};