import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { CREATED } from 'actions';

export const BookingTaskHeader = ({ className = '', typeRequest, onStatusSorting = () => {} }) => {
    return (
        <div className={`tr__booking-task-header f-medium d-flex align-items-center table-header justify-content-between ${className}`}>
            <div className="column number">
                <p>Reference no.</p>
            </div>
            <div className="column booking-no">
                <p>Booking no.</p>
            </div>
            <div className="column port-loading">
                <p>Port of Loading</p>
            </div>
            <div className="column port-discharge">
                <p>Port of Discharge</p>
            </div>
            <div className="column date-receipt">
                <p>Date of Receipt</p>
            </div>
            <div className="column requester">
                <p>{typeRequest === CREATED ? 'Freight Partner' : 'Requester'}</p>
            </div>
            <div className="column created-date">
                <p>Created Date</p>
            </div>
            <div className="column status">
                <p>Status</p>
                <i className="icon-filter" onClick={onStatusSorting} />
            </div>
        </div>
    )
};

BookingTaskHeader.propTypes = {
    className: PropTypes.string,
    typeRequest: PropTypes.string,
    onStatusSorting: PropTypes.func
};