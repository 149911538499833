import React, { createContext, useContext, useState } from 'react';
import { 
    HomeContext,
    AuthContext,
} from 'contexts';
import {
    OPERATION_FAILED_MESSAGE,
    getTemplates,
    ERROR,
    getTemplate,
    addNewSection,
    editTemplate,
    deleteTemplate,
    editSection,
    deleteSection,
    addNewTask,
    updateTask,
    deleteTask,
    removeCollaboratorTemplate,
    getCollaboratorsTemplate,
    assignPartnerTemplate,
    removePartnerTemplate,
    addCollabratorsTemplate,
    cloneTemplate
} from 'actions';

export const TemplateContext = createContext();

export const TemplateContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const { setLoading, setNotificationType, setNotificationMessage } = useContext(HomeContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const [templates, setTemplates] = useState([]);
    const [templatesDetails, setTemplatesDetails] = useState(null);
    const [collaboratorsTemp, setCollaboratorsTemp] = useState([]);
    const [partnersTaskTemplate, setPartnersTaskTemplate] = useState([]);

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType(ERROR);
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetTemplates = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getTemplates(params, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                const { items } = data;
                setTemplates(items || []);
                setTotalPages(data.totalPage);
                setCurrentPage(data.currentPage);
                if (callback) callback();
            }
            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doGetTemplatesDetails = async (id,callback) => {
        try {
            setLoading(true);
            const response = await getTemplate(id, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                setTemplatesDetails(data);
                
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doEditTemplate = async (templateId, payload, callback) => {
        try {
            setLoading(true);
            const response = await editTemplate(templateId, payload, loggedInUser);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doDeleteTemplate = async (templateId, callback) => {
        try {
            setLoading(true);
            const response = await deleteTemplate(templateId, loggedInUser);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doCloneTemplate = async (templateId, callback) => {
        try {
            setLoading(true);
            const response = await cloneTemplate(templateId, loggedInUser);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doAddNewSection = async (templateId, payload, callback) => {
        try {
            setLoading(true);
            const response = await addNewSection(templateId, payload, loggedInUser);
            const { status } = response;
            if (status === 200) {                
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }
    const doEditSection = async (sectionId, payload, callback) => {
        try {
            setLoading(true);
            const response = await editSection(sectionId, payload, loggedInUser);
            const { status } = response;
            if (status === 200) {                
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }
    const doDeleteSection = async (sectionId, callback) => {
        try {
            setLoading(true);
            const response = await deleteSection(sectionId, loggedInUser);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doAddNewTask = async (sectionId, payload, callback) => {
        if (sectionId) {
            try {
                setLoading(true);
                const response = await addNewTask(sectionId, payload, loggedInUser);
                const { status } = response;
                if(status === 200) {
                    if(callback) callback()
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doUpdateTask = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await updateTask(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doDeleteTaskTemplate = async (taskId, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await deleteTask(taskId, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doRemoveCollaboratorTemplate = async (templateId, payload, callback) => {
        if(templateId) {
            try {
                setLoading(true);
                const response = await removeCollaboratorTemplate(templateId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetCollaboratorsTemplate = async (templateId, params, callback, hasLoading = true) => {
        if (templateId) {
            try {
                if(hasLoading) setLoading(true);
                const response = await getCollaboratorsTemplate(templateId, params, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    // for (let member of data.items) {
                        // if (member.avatar) {

                            // member.avatar = GET_FILE + member.avatar;
                        // }
                    // }
                    setCollaboratorsTemp(data.items);
                    if (callback) callback();
                }
                if(hasLoading) setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doAddCollabratorsTemplate = async (templateId, payload, callback, hasLoading = true) => {
        if (templateId) {
            try {
                if(hasLoading) setLoading(true);
                const response = await addCollabratorsTemplate(templateId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                if(hasLoading) setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doAssignPartnerTemplate = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await assignPartnerTemplate(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doRemovePartnerTemplate = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await removePartnerTemplate(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    return (
        <TemplateContext.Provider
            value={{
                templates,
                currentPage,
                totalPages,
                templatesDetails,
                collaboratorsTemp,
                partnersTaskTemplate,
                setPartnersTaskTemplate,
                setCurrentPage,
                doGetTemplates,
                doGetTemplatesDetails,
                doAddNewSection,
                doEditTemplate,
                doDeleteTemplate,
                doEditSection,
                doDeleteSection,
                doAddNewTask,
                doUpdateTask,
                doDeleteTaskTemplate,
                setCollaboratorsTemp,
                doRemoveCollaboratorTemplate,
                doGetCollaboratorsTemplate,
                doAssignPartnerTemplate,
                doRemovePartnerTemplate,
                doAddCollabratorsTemplate,
                doCloneTemplate,
                setTemplates
            }}
        >
            { children }
        </TemplateContext.Provider>
    );
}