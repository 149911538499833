import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
    Input
} from 'components';
import { HomeContext, TemplateContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { last, split } from "lodash"
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';

export const AddNewSection = ({ open = false, onCancel }) => {
    const {doAddNewSection } = useContext(TemplateContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const {doGetTemplatesDetails}  = useContext(TemplateContext);
    const { handleSubmit, register, reset, errors } = useForm();
    const templateId = last(split(useLocation().pathname,'/'));

    const handleAddSection = (data) => {
        const { sectionName } = data;
        doAddNewSection(templateId, { name :sectionName.trim(), order : 0 },() => {
            setNotificationMessage(`
                <p><strong></strong> Section has been created successfully!</p>
            `);
            reset();
            doGetTemplatesDetails(templateId);
            onCancel()
            }
        )
    }

    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={() => {
                reset();
                onCancel();
            }}
            title="Add New Section"
            btnClasses="justify-content-between"
            submitButton
            // onSubmitClick={onSubmit}
            submitButtonLabel="Add new"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleAddSection)}>
                    {children}
                </form>
            )}
        >
            <div className="tr__section-name">
                <Input
                    label="Section name"
                    className="mbx3"
                    name="sectionName"
                    refs={register({ required: true, validate: val => !val.trim() ? 'This field cannot be blank' : undefined })}
                    error={!!errors.sectionName}
                    errorMessage={getErrorMessage(errors.sectionName, "section name", "This field cannot be blank")}
                />
            </div>
        </Modal>
    )
};

AddNewSection.propTypes ={
    open : PropTypes.bool,
    onCancel : PropTypes.func,
}