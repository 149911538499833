import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
    Modal,
} from 'components';
import { HomeContext } from 'contexts';
export const CouponOrganization = ({
    open = false,
    onCancel,
    onSubmit = () => {},
}) => {
    const { couponMessage } = useContext(HomeContext);
    return (
        <Modal
            open={open}
            className="tr__issue-confirmation"
            onCancel={onCancel}
            title="Congratulations"
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            isBackDropClickable={false}
            onSubmitClick={onCancel}
        >
            <div className="text-center change-status-issue">
                <h6 className="h6 mtbx2 f-medium">{couponMessage}</h6>
            </div>
        </Modal>
    )
}
CouponOrganization.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};
