import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import {
    Tab,
    TabPane,
    Collaborators,
    ShipmentTasks,
    TaskManagementButtons,
    AddCollaborator
} from 'components';
import {
    shipmentStatuses
} from 'utils';
import { ManagementContext, ModalContext, CollaboratorsContext, HomeContext } from 'contexts';
import { ALL, TASK_PAGE_SIZE } from 'actions';
import { useParams } from 'react-router-dom';

export const TaskManagement = ({ type, isArchive = false }) => {
    const { currentTabManagement, setCurrentTabManagement, setIsTemplate } = useContext(HomeContext);
    const { setAllCollaborators } = useContext(ModalContext);
    const { taskManagements, doGetTaskManagements } = useContext(ManagementContext);
    const { collaborators, doGetCollaborators } = useContext(CollaboratorsContext);
    const [tabs] = useState(['All', 'My Task']);
    const [totalTasks, setTotalTasks] = useState(0);
    const [addCollaborator, setAddCollaborator] = useState(false);
    const { shipmentId } = useParams();


    /*eslint-disable */
    useEffect(() => {
        if (shipmentStatuses && shipmentStatuses.length > 0 && !totalTasks) {
            let total = 0;
            shipmentStatuses.forEach(status => total += status.num);
            setTotalTasks(total);
        }
    }, [])
    
    useEffect(() => {
        const params = {
            page: 0,
            limit: TASK_PAGE_SIZE,
            tab: currentTabManagement
        }
        doGetTaskManagements(shipmentId, params, () => {});
        doGetCollaborators(shipmentId, {
            page: 0,
        });
    }, [currentTabManagement])
    /*eslint-enable */

    return addCollaborator ? (
        <AddCollaborator
            goBack={() => setAddCollaborator(false)}
        />
    ) : (
        <div className="tr__shipment-task-management">
            <TaskManagementButtons
                className={`${isArchive ? 'display-none' : ''} mbx3`}
                currentTab={currentTabManagement}
            />
            <Collaborators
                members={collaborators && collaborators.length > 0 ? collaborators : []}
                skip={5}
                onAdd={() => setAddCollaborator(true)}
                onAvatarsClick={() => {
                    setAllCollaborators(true);
                    setIsTemplate(false);
                    doGetCollaborators(shipmentId, {
                    })
                }}
            />
            <Tab
                // animated={false}
                initialTab={currentTabManagement === ALL ? 0 : 1}
                onTabClick={tab => setCurrentTabManagement(tab.toUpperCase().replace('-', ''))}
            >
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={tab}
                        tabSlug={tab.toLowerCase().replace(' ', '-')}
                    >
                        <div className="tr__task-pane">
                            <ShipmentTasks type={type} tasks={taskManagements} collaborators={collaborators} currentTab={currentTabManagement} />
                        </div>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};
