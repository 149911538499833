import React, { useContext, useState } from 'react';

import './index.scss';
import {
    Modal,
    TaskDetails,
    AddDueDate,
    AddRole,
    AccessPermission
} from 'components';
import { FtaAdvisorContext, HomeContext, ManagementContext, ModalContext, ShipmentInfoContext, TemplateContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { dueDateOptions, dueDates } from 'utils';
import moment from 'moment';
import { ALL, MYTASK, VDL } from 'actions';
import { useLocation } from 'react-router-dom';

export const AddTask = ({ open = false, onCancel }) => {
    const { 
        isTemplate, 
        isEditTask, 
        sectionId, 
        setNotificationMessage, 
        sectionName, 
        eventTask, 
        documentTask, 
        selectTask, 
        setIsEditTask,
        setCurrentTabManagement,
        currentTabManagement,
        setIsTemplate,
        isAddTaskFta,
        setIsAddTaskFta
    } = useContext(HomeContext);
    const { doCreateTask, doUpdateShipmentTask, newEstSection, taskManagements, doGetTaskManagements, doCreateTaskFta } = useContext(ManagementContext);
    const { doAddNewTask, doGetTemplatesDetails, templatesDetails, doUpdateTask } = useContext(TemplateContext);
    const { documentFta } = useContext(FtaAdvisorContext);

    const { setAddTask, setDeleteTask } = useContext(ModalContext);
    const { handleSubmit, register, errors, reset } = useForm();
    const [dayAjust, setDayAjust] = useState(1);
    const [roles, setRoles] = useState('');
    const [sectionType, setSectionType] = useState('')
    const [dateType, setDateType] = useState(dueDates[0]);
    const [eventBasedDate, setEventBasedDate] = useState(dueDateOptions[0]);
    const [date, setDate] = useState(moment(new Date()).format('DD MMM YYYY'));
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    
    const handleAddTask = (data) => {
        const { taskName, typeOfTask, dueDate, eventBasedDate, sectionName } = data;
        let selectedRole = [];
        roles.forEach(role => {
            selectedRole = [
                ...selectedRole,
                {
                    role: role.id.toUpperCase().replace('-', '_'),
                    permission: role.permission.key
                }
            ]
        })
        let selectedDueDate= { }
        if(dateType.id === "specific-date") {
            selectedDueDate = {
                specificDate: new Date(dueDate).getTime(),
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: eventBasedDate === VDL ? shipmentInfo.addresses.pod.date : shipmentInfo.addresses.pol.date,
                    adjustDays:  dayAjust
                }
            }
        }

        let payload = {
            taskName: taskName.trim(),
            type: typeOfTask.toUpperCase(),
            dueDate : selectedDueDate,
            roles: selectedRole,
        };

        const payloadTemplate = {
            name: taskName.trim(),
            type: typeOfTask.toUpperCase(),
            dueDate : {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: null,
                    adjustDays:  dayAjust
                }
            },
            roles: selectedRole,
        };

        if(isAddTaskFta) {
            const id =  pathnameArr[2];
            const documents = [
                {
                    fileName: documentFta.docName,
                    documentLink: documentFta.docLink
                }
            ];
            payload = {...payload,
                sectionName: sectionName ? sectionName : sectionType.value,
                documents
            };
            delete payload.type;
            
            doCreateTaskFta(id, payload, () => {
                reset();
                setAddTask(false);
                setRoles([]);
                setCurrentTabManagement(ALL);
                setIsAddTaskFta(false);
                setNotificationMessage(`
                <p> Task has been added successfully!</p>
                `);
            });
        }else{
            if (isTemplate === true) {
                doAddNewTask(sectionId, payloadTemplate, () => {
                    reset();
                    setIsTemplate(false);
                    setRoles([]);
                    onCancel();
                    doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                    setNotificationMessage(`
                    <p> Task has been added successfully!</p>
                    `);
                })
            } else {
                doCreateTask(shipmentId, sectionType.key, payload, () => {
                    reset();
                    setAddTask(false);
                    setRoles([]);
                    setCurrentTabManagement(ALL);
                    setNotificationMessage(`
                    <p> Task has been added successfully!</p>
                    `);
                });
            }
        }
        
    }

    const handleEditTask = (data) => {
        const { taskName, typeOfTask, dueDate, eventBasedDate} = data;
        let selectedRole = [];
        roles.forEach(role => {
            selectedRole = [
                ...selectedRole,
                {
                    role: role.id.toUpperCase().replace('-', '_'),
                    permission: role.permission.key
                }
            ]
        })
        let selectedDueDate= { }
        if(dateType.id === "specific-date") {
            selectedDueDate = {
                specificDate: new Date(dueDate).getTime(),
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: !isTemplate ? (eventBasedDate === VDL ? shipmentInfo.addresses.pod.date : shipmentInfo.addresses.pol.date) : null,
                    adjustDays:  dayAjust
                }
            }
        }
        const payload = {
            sectionId: sectionType.key,
            taskName: taskName.trim(),
            type: typeOfTask.toUpperCase(),
                dueDate : selectedDueDate
            ,
            roles: selectedRole,
        };

        const payloadTemplate = {
            sectionId: sectionType.key,
            name: taskName.trim(),
            type: typeOfTask.toUpperCase(),
            dueDate : {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: null,
                    adjustDays:  dayAjust ? dayAjust : 1
                }
            },
            roles: selectedRole,
        };

        if (isTemplate === true) {
            doUpdateTask(eventTask.id, payloadTemplate, () => {
                setIsTemplate(false);
                setAddTask(false);
                setIsEditTask(false);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(`
                <p> Task has been updated successfully!</p>
            `);
            })
        } else {
            doUpdateShipmentTask(shipmentId, eventTask.id, payload, () => {
                setAddTask(false);
                setAddTask(false);
                setIsEditTask(false);
                if (currentTabManagement === ALL) { 
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    })
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }
                // setCurrentTabManagement(ALL);
                setNotificationMessage(`
                    <p> Task has been updated successfully!</p>
                `);
            })
        }
        
    }

    const hanndleDeleteTask = () => {
        setAddTask(false);
        setDeleteTask(true);
    }
    
    const handleCloseModal = () => {
        reset();
        onCancel();
        setIsEditTask(false);
        setIsAddTaskFta(false);
        setDateType(dueDates[0]);
        setRoles([]);
    }

    
    return (
        <Modal
            open={open}
            className="tr__add-task no-padding"
            onCancel={handleCloseModal}
            title={isTemplate ? 'Task Settings' : (isEditTask ? 'Edit task' : 'Add New Task')}
            cancelButton={isEditTask}
            onCancelClick={hanndleDeleteTask}
            cancelButtonLabel="Delete Task"
            cancelButtonClassNames="outline"
            cancelButtonType="primary"
            btnClasses={isTemplate || isEditTask ? 'justify-content-between' : 'justify-content-end'}
            submitButton
            submitButtonLabel={isTemplate || isEditTask ? 'Save Changes' : "Save Task"}
            onSubmitClick={isEditTask ? handleSubmit(handleEditTask) : handleSubmit(handleAddTask)}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <TaskDetails 
                title="Task Details"
                register={register}
                errors={errors}
                sectionTask={isTemplate ? templatesDetails.sections : taskManagements}
                sectionName={sectionName}
                eventTask={eventTask}
                documentTask={documentTask ? documentTask : ''}
                isEditTask={isEditTask}
                setSectionType={setSectionType}
                sectionType={sectionType}
                newEstSection={newEstSection}
                sectionId={sectionId}
                selectTask={selectTask}
                isAddTaskFta={isAddTaskFta}
             />
            <AddDueDate 
                title="Add Due Date"
                register={register}
                errors={errors}
                setDayAjust={setDayAjust}
                dayAjust={dayAjust ? dayAjust : 1}
                eventTask={eventTask}
                setDateType={setDateType}
                dateType={dateType}
                date={date}
                setDate={setDate}
                isEditTask={isEditTask}
                eventBasedDate={eventBasedDate}
                setEventBasedDate={setEventBasedDate}
                optionBaseDate={shipmentInfo}
                isAddTaskFta={isAddTaskFta}
             />
            <AddRole
                title="Add Role"
                register={register}
                errors={errors}
                setRoles={setRoles}
                roles={roles}
                eventTask={eventTask}
                isEditTask={isEditTask}
            />
            <AccessPermission 
                title="Access Permission"
                register={register}
                errors={errors}
                setRoles={setRoles}
                roles={roles}
                eventTask={eventTask}
                isEditTask={isEditTask}
            />
        </Modal>
    )
};