import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Button,
    ShippingDetails,
    AddressDetails,
    EditBookingDetails,
    EditAISTracking,
    EditInvoice,
    EditCutOff,
    EditContactDetails,
    EditContainers,
    EditTranshipmentInfo,
    EditSectionInfo
} from 'components';
import { ShipmentInfoContext, HomeContext, ModalContext, DocumentsContext } from 'contexts';
import { trimArrayItems } from 'utils';
import _ from 'lodash';


export const EditShipmentInfo = ({ goBack, shipmentInfo }) => {
    const { setFileId, isArchive } = useContext(HomeContext);
    const { setViewExtractedInfo } = useContext(ModalContext);
    const { doUpdateShipmentInfo } = useContext(ShipmentInfoContext);
    const { setDocumentPermission } = useContext(DocumentsContext);
    const { handleSubmit, register, errors, getValues, watch, setError, clearErrors } = useForm();
    const [aisTracking, setAisTracking] = useState([0]);
    const {shipmentId} = useParams();
    const [containersTracking, setContainersTracking] = useState([0]);
    const [transhipments, setTranshipments] = useState([0]);
    const [prePolLocation, setPrePolLocation] = useState();
    const [postPodLocation, setPostPodLocation] = useState();
    const [departureLocation, setDepartureLocation] = useState({});
    const [arrivalLocation, setArrivalLocation] = useState({});

    /*eslint-disable */
    useEffect(() => {
        if (shipmentInfo) {
            if(shipmentInfo.containerTracking &&  shipmentInfo.containerTracking.containers && shipmentInfo.containerTracking.containers.length > 0){
                setContainersTracking([...Array(shipmentInfo.containerTracking.containers.length).keys()]);
            }
            if(shipmentInfo.transhipmentInfo  && shipmentInfo.transhipmentInfo.length > 0){
                setTranshipments([...Array(shipmentInfo.transhipmentInfo.length).keys()]);
            }
            if(shipmentInfo.aisTracking  && shipmentInfo.aisTracking.length > 0){
                setAisTracking([...Array(shipmentInfo.aisTracking.length).keys()]);
            }
        }
    }, [shipmentInfo])
    /*eslint-enable */
    
    const markAsActiveShipment = (data) => {
        if(!shipmentInfo) return;
        const params = {};
        const { 
            bookingConfirmation,
            masterBillOfLading,
            houseBill,
            invoiceNumber,
            shipperAddress,
            consigneeDeliveryAdress,
            carrierName,
            scacCode,
            prePol,
            postPod,
            bookingDate,
            shipper,
            eta,
            etd,
            measurement,
            containerYard,
            commodityDescription,
            grossWeightUnit,
            grossWeightValue,
            serviceType,
            hsCode,
            numberOfContainers,
            contactShipperAdd,
            consignee,
            consigneeAddress,
            notifyParty,
            notifyPartyAddress,
            serviceContractNumber,
            shipperContact,
            shipperFax,
            consigneeContact,
            consigneeFax,
            notifyContact,
            notifyFax,
            vgmSubmissionTimeline,
            siSubmission,
            cutOffDate,
            cutOffTime,
            sections
        } = data;
        let prepol = null;
        if (shipmentInfo.addresses && prePolLocation) {
            prepol = {
                location: prePolLocation,
                date: shipmentInfo.addresses.prepol && shipmentInfo.addresses.prepol.date  ? shipmentInfo.addresses.prepol.date : null,
                actual: shipmentInfo.addresses.prepol ? shipmentInfo.addresses.prepol.actual : false
            }
        }

        if(shipmentInfo.addresses && shipmentInfo.addresses.prepol && prePol === shipmentInfo.addresses.prepol.location.locode){
            prepol = {
              ...shipmentInfo.addresses.prepol
            }
            delete prepol._id;
            delete prepol.location._id;
        }

        let postpod = null;
        if (shipmentInfo.addresses && postPodLocation) {
            postpod = {
                location: postPodLocation,
                date: shipmentInfo.addresses.postpod && shipmentInfo.addresses.postpod.date  ? shipmentInfo.addresses.postpod.date : null,
                actual: shipmentInfo.addresses.postpod ? shipmentInfo.addresses.postpod.actual : false
            }
        }

        if(shipmentInfo.addresses && shipmentInfo.addresses.postpod && postPod === shipmentInfo.addresses.postpod.location.locode){
            postpod = {
              ...shipmentInfo.addresses.postpod
            }
            delete postpod._id;
            delete postpod.location._id;
        }

        const confirmationNo = bookingConfirmation.trim() ? trimArrayItems(bookingConfirmation.split(',')) : null;
        const masterBoL = masterBillOfLading.trim() ? trimArrayItems(masterBillOfLading.split(',')) : null;
        const houseBoL = houseBill.trim() ? trimArrayItems(houseBill.split(',')) : null;
        const invoices = invoiceNumber.trim() ? trimArrayItems(invoiceNumber.split(',')) : null;
        const aisTrackings = aisTracking.map(v => {
            const name = data[`vesselName${v}`];
            const imo = data[`vesselImo${v}`];
            const voyage = data[`vesselVoyage${v}`];
            let departure = departureLocation[`vesselDeparture${v}`];
            let arrival = arrivalLocation[`vesselArrival${v}`];
            if(!departure && shipmentInfo.aisTracking && shipmentInfo.aisTracking[v] && shipmentInfo.aisTracking[v].departure){
                departure = shipmentInfo.aisTracking[v].departure;
                delete departure._id;
            }
            if(!arrival && shipmentInfo.aisTracking && shipmentInfo.aisTracking[v] && shipmentInfo.aisTracking[v].arrival){
                arrival = shipmentInfo.aisTracking[v].arrival;
                delete arrival._id;
            }
            const newParams = {};

            if (name) newParams.name = name;
            if (imo) newParams.imo = imo;
            if (voyage) newParams.voyage = voyage;
            if (departure) newParams.departure = departure;
            if (arrival) newParams.arrival = arrival;
            return Object.keys(newParams).length === 5 ? newParams : null;
        }).filter(v => v);

        if (aisTrackings && aisTrackings.length > 0) params.aisTracking = aisTrackings;

        params.addresses = {};

        if (shipperAddress) params.addresses = {
            ...params.addresses,
            shipperPickupAdd: shipperAddress
        }

        if (consigneeDeliveryAdress) params.addresses = {
            ...params.addresses,
            consigneeDeliveryAdd: consigneeDeliveryAdress
        }


        if (prepol) params.addresses = {
            ...params.addresses,
            prepol
        };

        if (postpod) params.addresses =  {
            ...params.addresses,
            postpod
        };;

        if (carrierName) {
            params.bookingDetails = {
                ...params.bookingDetails,
                carrierName
            };
        }

        if (scacCode) {
            params.bookingDetails = {
                ...params.bookingDetails,
                scacCode
            };
        }

        if (confirmationNo) params.bookingDetails = {
            ...params.bookingDetails,
            confirmationNo
        };

        if (masterBoL && masterBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            masterBoL
        };

        if (houseBoL && houseBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            houseBoL
        };

        if (masterBoL && masterBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            masterBoL
        };

        if (invoices && invoices.length > 0) params.invoices = invoices;

        if (bookingDate)  params.shippingDetails = {
            ...params.shippingDetails,
            bookingDate: new Date(bookingDate).getTime()
        };

        if (shipper)  params.shippingDetails = {
            ...params.shippingDetails,
                shipper
        };

        if (eta)  params.shippingDetails = {
            ...params.shippingDetails,
            eta: new Date(eta).getTime().toString()
        };

        if (etd)  params.shippingDetails = {
            ...params.shippingDetails,
            etd: new Date(etd).getTime().toString()
        };

        const containers = containersTracking.map(v => {
            const containerNumber = data[`containerNumber${v}`];
            const carrierSealNumber = data[`carrierSealNumber${v}`];
            const containerType = data[`containerType${v}`];
            const weightUnit = data[`weightUnit${v}`];
            const weightValue = data[`weightValue${v}`];
            const volumeUnit = data[`volumeUnit${v}`];
            const volumeValue = data[`volumeValue${v}`];
            const newParams = {};

            if (containerNumber) newParams.containerNumber = containerNumber;
            if (carrierSealNumber) newParams.carrierSealNumber = carrierSealNumber;
            if (containerType) newParams.containerType = containerType;
            if (weightValue && weightUnit) newParams.weight = {
                weightUnit: weightUnit,
                value: weightValue
            };
            if (volumeValue && volumeUnit) newParams.volume = {
                unit: volumeUnit,
                value: volumeValue
            };
            return Object.keys(newParams).length > 0 ? newParams : null;
        }).filter(v => v);

        params.containerTracking = {};

        if (measurement)  params.containerTracking = {
            ...params.containerTracking,
            measurement
        };

        if (containerYard)  params.containerTracking = {
            ...params.containerTracking,
            containerYard
        };
        
        if (commodityDescription)  params.containerTracking = {
            ...params.containerTracking,
            commodityDescription
        };

        if (serviceType)  params.containerTracking = {
            ...params.containerTracking,
            serviceType
        };

        if (hsCode)  params.containerTracking = {
            ...params.containerTracking,
            hsCode
        };

        if (numberOfContainers)  params.containerTracking = {
            ...params.containerTracking,
            numberOfContainers
        };

        if (grossWeightUnit && grossWeightValue)  params.containerTracking.grossWeight = {
            ...params.containerTracking.grossWeight,
            weightUnit: grossWeightUnit,
            value: grossWeightValue 
        };

        if (containers && containers.length > 0) params.containerTracking.containers = containers;

        params.contactDetails = {};

        if (contactShipperAdd)  params.contactDetails = {
            ...params.contactDetails,
            shipperAddress: contactShipperAdd
        };

        if (consignee)  params.contactDetails = {
            ...params.contactDetails,
            consignee
        };

        if (consigneeAddress)  params.contactDetails = {
            ...params.contactDetails,
            consigneeAddress
        };

        if (notifyParty)  params.contactDetails = {
            ...params.contactDetails,
            notifyParty
        };

        if (notifyPartyAddress)  params.contactDetails = {
            ...params.contactDetails,
            notifyPartyAddress
        };

        if (serviceContractNumber)  params.contactDetails = {
            ...params.contactDetails,
            serviceContractNumber
        };

        if (shipperContact)  params.contactDetails = {
            ...params.contactDetails, 
            shipperContact 
        };

        if (shipperFax)  params.contactDetails = {
            ...params.contactDetails, 
            shipperFax 
        };
        
        if (consigneeContact)  params.contactDetails = {
            ...params.contactDetails, 
            consigneeContact 
        };

        if (consigneeFax)  params.contactDetails = {
            ...params.contactDetails, 
            consigneeFax 
        };

        if (notifyContact)  params.contactDetails = {
            ...params.contactDetails, 
            notifyContact 
        };

        if (notifyFax)  params.contactDetails = {
            ...params.contactDetails, 
            notifyFax 
        };

        if (siSubmission)  params.cutOff = {
            ...params.cutOff, 
            siSubmission 
        };

        if (vgmSubmissionTimeline)  params.cutOff = {
            ...params.cutOff, 
            vgmSubmissionTimeline 
        };

        if (cutOffDate)  params.cutOff = {
            ...params.cutOff, 
            date: new Date(cutOffDate).getTime().toString()
        };

        if (cutOffTime)  params.cutOff = {
            ...params.cutOff, 
            time: cutOffTime 
        };
        
        const transhipmentInfo = transhipments.map(v => {
            const tsPort = data[`tsPort${v}`];
            const tsNextPort = data[`tsNextPort${v}`];
            const tsVessel = data[`tsVessel${v}`];
            const tsVesselIMO = data[`tsVesselIMO${v}`];
            const tsETD = data[`tsETD${v}`];
            const tsETA = data[`tsETA${v}`];
            const newParams = {};

            if (tsPort) newParams.tsPort = tsPort;
            if (tsNextPort) newParams.tsNextPort = tsNextPort;
            if (tsVessel) newParams.tsVessel = tsVessel;
            if (tsVesselIMO) newParams.tsVesselIMO = tsVesselIMO;
            if (tsETD) newParams.tsETD = tsETD;
            if (tsETA) newParams.tsETA = tsETA;
            return Object.keys(newParams).length > 0 ? newParams : null;
        }).filter(v => v);

        if(transhipmentInfo && transhipmentInfo.length > 0) {
            params.transhipmentInfos = transhipmentInfo;
        }
        if ((shipmentInfo.document && shipmentInfo.document._id) || _.isEmpty(shipmentInfo.document)) {
            delete params.document;
        } else {
            params.document = shipmentInfo.document;
        }
        if(sections){
            params.addedInfoSections = sections;
        }
        doUpdateShipmentInfo(shipmentId,params);
        goBack();
    }
    
    const handleViewExtracted = () => {
        if (shipmentInfo && !_.isEmpty(shipmentInfo.document)) {
            setFileId(shipmentInfo.document.awsId);
            setViewExtractedInfo(true);
            setDocumentPermission(true);
        }
    }

    return (
        <div
            className="tr__edit-shipment-info"
        >
            <div className="tr__edit-shipment-info--title d-flex align-items-center col-12">
                <p className="tr__link tag mrx2" onClick={goBack}>
                    <i className="icon-chevron-left" />
                    <span>Back</span>
                </p>
                <h2 className="h2 f-medium">Edit Shipment Information</h2>
            </div>
            <form onSubmit={handleSubmit(markAsActiveShipment)}>
                <div className="tr__edit-shipment-info--content">
                    <ShippingDetails 
                        shippingDetails={shipmentInfo.shippingDetails}
                        register={register} 
                        watch={watch}
                        editShipmentInfo
                        isArchive={isArchive}/>
                    <AddressDetails 
                        routes={shipmentInfo.routes || shipmentInfo.addresses} 
                        errors={errors}
                        getValues={getValues}
                        register={register} 
                        editShipmentInfo
                        setPrePolLocation={setPrePolLocation}
                        setPostPodLocation={setPostPodLocation}
                        isArchive={isArchive}
                    />
                    <EditBookingDetails
                        bookingDetails={shipmentInfo.bookingDetails}
                        register={register}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        editShipmentInfo
                        isArchive={isArchive}
                    />
                    <EditContainers
                        containerTracking={shipmentInfo.containerTracking}
                        containers={containersTracking}
                        setContainers={setContainersTracking}
                        register={register}
                        watch={watch}
                        errors={errors}
                        editShipmentInfo
                        isArchive={isArchive}
                    />
                    <EditAISTracking 
                        register={register}
                        vessels={aisTracking}
                        setVessels={setAisTracking}
                        errors={errors}
                        getValues={getValues}
                        trackings={shipmentInfo.aisTracking}
                        watch={watch}
                        editShipmentInfo
                        setError={setError}
                        clearErrors={clearErrors}
                        setDepartureLocation={setDepartureLocation}
                        departureLocation={departureLocation}
                        setArrivalLocation={setArrivalLocation}
                        arrivalLocation={arrivalLocation}
                        isArchive={isArchive}
                    />
                    <EditInvoice shipmentDetails={shipmentInfo} isArchive={isArchive} register={register} watch={watch} editShipmentInfo/>
                    <EditContactDetails  
                        register={register}
                        errors={errors}
                        contactDetails={shipmentInfo.contactDetail}
                        watch={watch}
                        isArchive={isArchive}
                        editShipmentInfo
                    />
                    <EditTranshipmentInfo 
                        register={register} 
                        transhipmentInfo={shipmentInfo.transhipmentInfo} 
                        watch={watch}
                        editShipmentInfo 
                        isArchive={isArchive}
                        transhipments={transhipments} 
                        setTranshipments={setTranshipments}
                    />
                    <EditCutOff cutOff={shipmentInfo.cutOff} register={register} watch={watch}  isArchive={isArchive} editShipmentInfo/>    
                    <EditSectionInfo register={register} sections={shipmentInfo.addedInfoSections} errors={errors} />
                    <div className="tr__verify-details-form--note d-flex flex-column align-items-center justify-content-center">
                        <Button
                            className="outline f-medium mtx2"
                            type="secondary"
                            onClick={handleViewExtracted}
                            // disabled={isArchive}
                        >
                            View All Extracted Information
                        </Button>
                    </div>
                </div>
                <div className="tr__edit-shipment-info--footer d-flex align-items-center justify-content-between">
                <p className="f-medium">All this information will be reflected user “Shipment Information” tab</p>
                <div className="d-flex align-items-center">
                    <p
                        className="tr__link secondary mrx2"
                        onClick={goBack}
                    >Cancel</p>
                    <Button
                         isSubmitBtn={true}
                         disabled={isArchive}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
            </form>
        </div>
    )
};

EditShipmentInfo.propTypes = {
    goBack: PropTypes.func
};
