import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import moment from 'moment';
import {
    Collaborators,
    Button,
    Table,
    DocumentHeader,
    UploadDocuments,
    Tooltip
} from 'components';
import { AuthContext, CollaboratorsContext, DocumentsContext, HomeContext, ManagementContext, ModalContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { CONSIGNEE, DEFAULT, DOCUMENT_PAGE_SIZE, EXPORT_CUSTOMS, EXPORT_LOGISTICS, FREIGHT, GET_FILE, IMPORT_CUSTOMS, IMPORT_LOGISTICS, SHIPMENT_ADMIN } from 'actions';
import { useForm } from 'react-hook-form';

export const Documents = () => {
    const { register, errors } = useForm();
    const { userInfo } = useContext(AuthContext);
    const {
        isArchive,
        setTaskName,
        setTaskId,
        setTaskRoles,
        setIsTemplate,
        setIsDocument
    } = useContext(HomeContext);
    const {
        documents,
        doGetDocuments,
        setDocumentsOfTask,
        setDocuments,
        doUploadDocumentsTask,
        setDocumentPermission,
        doGetTradeTrust
    } = useContext(DocumentsContext);
    const { setViewAllDocuments, setAssignCollaborators } = useContext(ModalContext);
    const { doGetTaskPartners } = useContext(ManagementContext);
    const { doGetCollaborators } = useContext(CollaboratorsContext);
    const [limit] = useState(3);
    const { shipmentId } = useParams();

    /*eslint-disable */
    useEffect(() => {
        setDocuments([]);
        doGetDocuments(shipmentId, {
            page: 0,
            limit: DOCUMENT_PAGE_SIZE
        }, () => {
        })
    }, [])
    /*eslint-enable */
    // const loadMoreDocuments = () => {
    //     doGetDocuments(shipmentId, {
    //         page: currentPage + 1,
    //         limit: DOCUMENT_PAGE_SIZE
    //     })
    // }


    const navigateViewDocuments = (documents, taskId, type, partners) => {
        if (partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) {
            setDocumentPermission(true)
        } else {
            setDocumentPermission(false)
        }

        // get trade trust data
        doGetTradeTrust(documents.filter(doc => doc.awsId).map(doc => doc.awsId), true, data => {
            // map response data to documents
            documents.forEach(doc => {
                doc.tradeTrust = data.find(e => e.id === doc.awsId);
            });

            setDocumentsOfTask(documents);
            setViewAllDocuments(true);
            setTaskId(taskId);
        });
    }

    const handleAssignTask = (taskId, taskName, parnters, roles) => {
        setIsDocument(true);
        setIsTemplate(false);
        doGetTaskPartners(taskId, () => {
            setAssignCollaborators(true);
        });
        // setAssignCollaborators(true);
        setTaskId(taskId);
        setTaskName(taskName);
        setTaskRoles(roles);

        doGetCollaborators(shipmentId, {
            page: 0,
            limit: 9999,
            role: roles
        });

    }

    const handleDownloadDocs = (docs) => {
        const newDocs = [...docs];
        newDocs.forEach(doc => {
            setTimeout(() => {
                window.open(GET_FILE + doc.awsId);
            }, 1000)
        })

    }
    const columns = [
        {
            dataIndex: '_id,taskName,documents,partners,type',
            render: (_id, taskName, documents, partners, type) => (
                <div className="tr__doc-title d-flex">
                    {type === DEFAULT && <i className="icon-star mrx2" />}
                    <div className="w-100">
                        <div className="mbx1 d-flex align-items-center justify-content-between">
                            <h4 className="has-tooltip" title={taskName}>{taskName}</h4>
                            <span>{documents.length} doc{documents.length > 1 ? 's' : ''}</span>
                        </div>
                        {/* <p className={`tr__link ${(partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) ? '' : 'disabled'}`} onClick={() => navigateViewDocuments(documents, _id)}>View all documents</p> */}
                        <p className={`tr__link ${documents.length > 0 ? '' : 'disabled'}`} onClick={() => navigateViewDocuments(documents, _id, type, partners)}>View all documents</p>
                    </div>
                </div>
            )
        },
        {
            dataIndex: 'roles',
            render: (roles) => (
                <div className="column partner w-100 px2">
                    <div className="partners d-flex justify-content-between text-capitalize">
                        {roles.length > 0 && (
                            <>
                                {roles && roles.length > 0 && (
                                    <>
                                        {roles.slice(0, limit).map((role, roleIndex) => (
                                            <Tooltip
                                                // content={role.replace('_', ' ').toLowerCase()}
                                                content={role && role.role ? role.role.replace('_',' ').toLowerCase() : role}
                                                key={roleIndex}
                                            >
                                                {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                                {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                                {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                                {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                                {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                                {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                                {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                                <i className={`icon-${role && role.role ? role.role.toLowerCase()  : ''}`} />
                                            </Tooltip>
                                        ))}

                                        {limit < roles.length && (
                                            <Tooltip
                                                renderContent={() => (
                                                    <div className="tags d-flex flex-column">
                                                        {roles.slice(limit, roles.length).map((partner, partnerIndex) => (
                                                            <span key={partnerIndex}>{partner.replace('_', ' ').toLowerCase()}</span>
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <span className="f-medium">+{roles.length - limit}</span>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )
        },
        {
            dataIndex: 'documents',
            render: (documents) => (
                <div className="tr__doc-date">
                    {documents.length > 0 && (
                        <>
                            <p className="mbx1">{documents[documents.length - 1]?.uploadedBy?.name}</p>
                            <p className="date">{moment(documents[documents.length - 1]?.uploadedTime).format('DD MMM YYYY | hh:mm A')}</p>
                        </>
                    )
                    }
                </div>
            )
        },
        {
            dataIndex: '_id,partners,roles,taskName,type',
            render: (_id, partners, roles, taskName, type) => (
                <>
                    {type !== DEFAULT ? (
                        <Collaborators
                            members={partners}
                            skip={2}
                            label="Assign"
                            onAdd={() => handleAssignTask(_id, taskName, partners, roles)}
                            isDocument={true}
                        />
                    ) : (<div></div>)
                    }
                </>
            )

        },
        {
            dataIndex: 'documents,partners,type',
            render: (documents, partners, type) => <Button
                type="secondary"
                className="icon outline"
                icon="icon-download"
                onClick={() => handleDownloadDocs(documents)}
                // disabled={(documents.length > 0 && !isArchive ? ((partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) ? false : true) : true)}

            >Download</Button>
        }
    ];

    const handleUploadDocuments = (files) => {
        console.log(files);
        doUploadDocumentsTask()
    }

    return (
        <div className="tr__shipment-documents d-flex">
            <div className="tr__shipment-documents--table">
                <div className="tr__shipment-documents--list">
                    <DocumentHeader />
                    <Table
                        className="documents"
                        dataSource={documents}
                        columns={columns}
                    >
                    </Table>
                    
                </div>
                {/* {totalPages > currentPage + 1 && (
                        <div className="load-more-link mtx2 text-center">
                            <p className="tr__link" onClick={loadMoreDocuments}>
                                Load more documents...</p>
                        </div>
                    )} */}
            </div>
            
            {!isArchive && (
                <div className="tr__shipment-documents--uploader">
                    <UploadDocuments
                        title="Upload documents"
                        name="shipmentDocs"
                        mode="doc"
                        documents={documents}
                        onFilesUpload={handleUploadDocuments}
                        errors={!!errors}
                        register={register}
                    />
                </div>
            )}
        </div>
    )
};
