import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext, ModalContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { EDIT_REQUEST_URL } from 'actions';

export const SelectLinkedVessel = ({ open = false, onCancel }) => {
    const { bookingId, setSelectLinkVessel } = useContext(HomeContext);
    const { setSelectLinkedVessel } = useContext(ModalContext);
    const history = useHistory();
    
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Vessel Confirmation"
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={() => {
                history.push({
                            pathname: EDIT_REQUEST_URL.replace(':brId', bookingId),
                            // state: chooseLinkedVessel
                        });
                setSelectLinkedVessel(false);
                setSelectLinkVessel(true);
            }}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={() => {
                history.push({
                    pathname: EDIT_REQUEST_URL.replace(':brId', bookingId),
                    state: {
                        noSelectLinkVessel : true
                    }
                });
            setSelectLinkedVessel(false);
            setSelectLinkVessel(true);
            }}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to select this vessel schedule?</h6>
            </div>
        </Modal>
    )
};