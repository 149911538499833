import React, { createContext, useContext, useState } from 'react';
import {
    HomeContext,
    AuthContext,
    ModalContext
} from 'contexts';
import {
    DESTINATION,
    getCarriers,
    getLocodes,
    getTrackers,
    getTrackAndTraceV2,
    ORIGIN,
    getRoute,
    ERROR,
    createMarkAsActiveShipment,
    FAILED,
    OPERATION_FAILED_MESSAGE
} from 'actions';
import { VesselScheduleContextProvider } from './VesselScheduleContext';
export const TrackShipmentContext = createContext();

export const TrackShipmentContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const { setLoading, setNotificationType, setNotificationMessage, setSearchParamsShipment } = useContext(HomeContext);

    const { setNoResultSearch } = useContext(ModalContext);
    const [carriers, setCarriers] = useState([]);
    const [firstCarriers, setFirstCarriers] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const [tracker, setTracker] = useState([]);
    const [firstLocode, setFirstLocode] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [routes, setRoutes] = useState();
    const [trackerParams, setTrackerParams] = useState([]);
    const [selectedTracker, setSelectedTracker] = useState();

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType(ERROR);
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const buildItem = (icon, title, status, items, location, date) => {
        return {
            icon,
            title,
            status,
            items,
            location,
            date
        };
    }

    const doGetCarriers = async (params, callback, errorCallback) => {
        if (carriers.length === 0 || params.nameOrCode || params.nameOrCode === '') {
            try {
                const newParams = { ...params };
                delete newParams.limit;

                const response = await getCarriers(newParams, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (params.hasOwnProperty('nameOrCode')) {
                        setCarriers(data || []);
                        if (callback) callback();
                    } else {
                        setFirstCarriers(data || []);
                        setCarriers(data || []);
                    }

                    setCarriers(data || []);
                    if (callback) callback();
                }
            } catch (error) {
                // handleException(error);
                if (errorCallback) errorCallback();
            }
        }
    }

    const doGetLocodes = async (params, callback, errorCallback, type = ORIGIN, hasLoading = false) => {
        if ((type === ORIGIN && origins.length === 0) || (type === DESTINATION && destinations.length === 0) || params.isSearching) {
            try {
                if (hasLoading) setLoading(true);
                const newParams = { ...params };
                delete newParams.isSearching;

                const response = await getLocodes(newParams, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (params.hasOwnProperty('cop')) {
                        if (type === ORIGIN) {
                            if (callback) callback(data || []);
                            setOrigins(data || []);
                        } else {
                            if (callback) callback(data || []);
                            setDestinations(data || []);
                        }
                    } else {
                        setFirstLocode(data || []);
                        setOrigins(data || []);
                        setDestinations(data || []);
                    }

                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                // handleException(error)
                if (errorCallback) errorCallback();
            }
        }
    }

    const doGetRoute = async (params, callback, errorCallback) => {
        try {
            const response = await getRoute(params, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                setRoutes((data.status !== FAILED) ? (data || null) : null);
                // if (data.status === FAILED || (!data.success && !data.routeArray)) setNoResultSearch(true);
                if (callback) callback();
            }
        } catch (error) {
            if (errorCallback) errorCallback();
        }
    }

    const doGetTrackers = async (params, callback, errorCallback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getTrackers(params, loggedInUser);
            const { data, status } = response;
            let resultTracker = [];
            if (status === 200) {
                setTracker(data.trackerResponse || []);
                setTrackerParams(Object.values(params));
                if (data.trackerResponse.length === 0) {
                    // setNoResultSearch(true);
                    setTrackers([]);
                    setSelectedTracker(null);
                    setRoutes(null);
                    if (errorCallback) errorCallback();
                } else {
                    const newData = { ...data };
                    const carrierName = newData.scac;
                    const { locations, containers, container, route, vessels } = newData.trackerResponse;
                    const locationPrePol = locations.find(e => e.id === route.pol.location);
                    const locationPostPod = locations.find(e => e.id === route.pod.location);
                    const locationPol = locations.find(e => e.id === route.pol.location);
                    const locationPod = locations.find(e => e.id === route.pod.location);

                    const stepShipment = {
                        // titlePrePol: locationPrePol.id === locationPol.id ? 'PREPOL' :`${locationPrePol.name} ${locationPrePol.locode ? `${locationPrePol.locode}` : ''}`,
                        // titlePol: `${locationPol.name} ${locationPol.locode ? `${locationPol.locode}` : ''}`,
                        // originLocode: locationPrePol.locode,
                        // prePolEvents: locationPrePol.id === route.prepol.location ? [{
                        //     items: [
                        //         {
                        //             actual: route.prepol.actual,
                        //             date: route.prepol.date,
                        //             description: "Empty container pickup"
                        //         }
                        //     ]
                        // }] : [],
                        // statusPrePol: route.prepol.actual ? 'done' : '',
                        // statusPol: route.pol.actual ? 'done' : '',
                    };
                    // debugger
                    if (locationPrePol && locationPol) {
                        stepShipment.titlePrePol = locationPrePol.id === locationPol.id ? 'PREPOL' : `${locationPrePol.name} ${locationPrePol.locode ? `${locationPrePol.locode}` : ''}`;
                        stepShipment.titlePol = `${locationPol.name} ${locationPol.locode ? `${locationPol.locode}` : ''}`;
                        stepShipment.originLocode = locationPrePol.locode;
                        stepShipment.prePolEvents = locationPrePol.id === route.prepol.location ? [{
                            items: [
                                {
                                    actual: route.prepol.actual,
                                    date: route.prepol.date,
                                    description: "Empty container pickup"
                                }
                            ]
                        }] : [];
                        stepShipment.statusPrePol = route.prepol.actual ? 'done' : '';
                        stepShipment.statusPol = route.pol.actual ? 'done' : '';
                    }
                    if (locationPostPod && locationPod) {
                        stepShipment.titlePostPod = locationPostPod.id === locationPod.id ? 'POSTPOD' : `${locationPostPod.name} ${locationPostPod.locode ? `${locationPostPod.locode}` : ''}`;
                        stepShipment.titlePod = `${locationPod.name} ${locationPod.locode ? `${locationPod.locode}` : ''}`;
                        stepShipment.destinationLocode = locationPostPod.locode;
                        stepShipment.postPodEvents = locationPostPod.id === route.postpod.location ? [{
                            items: [
                                {
                                    actual: route.postpod.actual,
                                    date: route.postpod.date,
                                    description: "Delivery"
                                }
                            ]

                        }] : [];
                        stepShipment.statusPod = route.pod.actual ? 'done' : '';
                        stepShipment.statusPostPod = route.postpod.actual ? 'done' : ''
                    }
                    const prePol = buildItem("icon-container", stepShipment.titlePrePol, stepShipment.statusPrePol, stepShipment.prePolEvents, locationPrePol, route.prepol.date);
                    const postPod = buildItem("icon-container", stepShipment.titlePostPod, stepShipment.statusPostPod, stepShipment.postPodEvents, locationPostPod, route.postpod.date);

                    (containers ? containers : [container]).forEach(container => {
                        const polEvents = container.events.filter(e => e.location === route.pol.location);
                        const podEvents = container.events.filter(e => e.location === route.pod.location);
                        const containerId = container.number;
                        const vesselIdsPol = [...new Set(polEvents.map(item => item.vessel))];
                        const vesselIdsPod = [...new Set(podEvents.map(item => item.vessel))];

                        const polVesselEvents = vesselIdsPol.map(vessel => {
                            const vesselInfo = vessels.find(e => e.id === vessel);
                            const itemEvents = polEvents.filter(e => e.vessel === vessel);
                            return { items: itemEvents, vesselName: vesselInfo };
                        })
                        const podVesselEvents = vesselIdsPod.map(vessel => {
                            const vesselInfo = vessels.find(e => e.id === vessel);
                            const itemEvents = podEvents.filter(e => e.vessel === vessel);
                            return { items: itemEvents, vesselName: vesselInfo };
                        })

                        const pol = buildItem("icon-anchor", stepShipment.titlePol, stepShipment.statusPol, polVesselEvents, locationPol, route.pol.date);
                        const pod = buildItem("icon-anchor", stepShipment.titlePod, stepShipment.statusPod, podVesselEvents, locationPod, route.pod.date);

                        resultTracker = [
                            ...resultTracker,
                            {
                                carrierName,
                                containerId,
                                vessels,
                                pol,
                                pod,
                                prePol,
                                postPod
                            }
                        ];
                    });

                    setTrackers(resultTracker);
                    setSelectedTracker(resultTracker[0]);
                    console.log(params)
                    setSearchParamsShipment(params);
                    if (callback) callback();

                    if (vessels.length > 0) {
                        doGetRoute({
                            imo: vessels[0].imo,
                            originPort: prePol.location.locode,
                            destinationPort: postPod.location.locode
                        }, null, () => {
                            if (errorCallback) errorCallback();
                        })
                    }

                }
            }
            setLoading(false);
        } catch (error) {
            setNoResultSearch(true);
            if (errorCallback) errorCallback();
        }
    }

    const doGetTrackAndTraceV2 = async (params, callback, errorCallback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getTrackAndTraceV2(params, loggedInUser);
            const { data, status } = response;
            let resultTracker = [];
            if (status === 200) {
                setTracker(data.trackerResponse || []);
                setTrackerParams(Object.values(params));
                if (data.trackerResponse.length === 0) {
                    // setNoResultSearch(true);
                    setTrackers([]);
                    setSelectedTracker(null);
                    setRoutes(null);
                    if (errorCallback) errorCallback();
                } else {
                    const newData = { ...data };
                    const carrierName = newData.scac;
                    const { locations, containers, container, route, vessels } = newData.trackerResponse;
                    const locationPrePol = locations.find(e => e.id === route.pol.location);
                    const locationPostPod = locations.find(e => e.id === route.pod.location);
                    const locationPol = locations.find(e => e.id === route.pol.location);
                    const locationPod = locations.find(e => e.id === route.pod.location);

                    const stepShipment = {};
                    // debugger
                    if (locationPrePol && locationPol) {
                        stepShipment.titlePrePol = locationPrePol.id === locationPol.id ? 'PREPOL' : `${locationPrePol.name} ${locationPrePol.locode ? `${locationPrePol.locode}` : ''}`;
                        stepShipment.titlePol = `${locationPol.name} ${locationPol.locode ? `${locationPol.locode}` : ''}`;
                        stepShipment.originLocode = locationPrePol.locode;
                        stepShipment.prePolEvents = locationPrePol.id === route.prepol.location ? [{
                            items: [
                                {
                                    actual: route.prepol.actual,
                                    date: route.prepol.date,
                                    description: "Empty container pickup"
                                }
                            ]
                        }] : [];
                        stepShipment.statusPrePol = route.prepol.actual ? 'done' : '';
                        stepShipment.statusPol = route.pol.actual ? 'done' : '';
                    }
                    if (locationPostPod && locationPod) {
                        stepShipment.titlePostPod = locationPostPod.id === locationPod.id ? 'POSTPOD' : `${locationPostPod.name} ${locationPostPod.locode ? `${locationPostPod.locode}` : ''}`;
                        stepShipment.titlePod = `${locationPod.name} ${locationPod.locode ? `${locationPod.locode}` : ''}`;
                        stepShipment.destinationLocode = locationPostPod.locode;
                        stepShipment.postPodEvents = locationPostPod.id === route.postpod.location ? [{
                            items: [
                                {
                                    actual: route.postpod.actual,
                                    date: route.postpod.date,
                                    description: "Delivery"
                                }
                            ]

                        }] : [];
                        stepShipment.statusPod = route.pod.actual ? 'done' : '';
                        stepShipment.statusPostPod = route.postpod.actual ? 'done' : ''
                    }
                    const prePol = buildItem("icon-container", stepShipment.titlePrePol, stepShipment.statusPrePol, stepShipment.prePolEvents, locationPrePol, route.prepol.date);
                    const postPod = buildItem("icon-container", stepShipment.titlePostPod, stepShipment.statusPostPod, stepShipment.postPodEvents, locationPostPod, route.postpod.date);

                    (containers ? containers : [container]).forEach(container => {
                        const polEvents = container.events.filter(e => e.location === route.pol.location);
                        const podEvents = container.events.filter(e => e.location === route.pod.location);
                        const containerId = container.number;
                        const vesselIdsPol = [...new Set(polEvents.map(item => item.vessel))];
                        const vesselIdsPod = [...new Set(podEvents.map(item => item.vessel))];

                        const polVesselEvents = vesselIdsPol.map(vessel => {
                            const vesselInfo = vessels.find(e => e.id === vessel);
                            const itemEvents = polEvents.filter(e => e.vessel === vessel);
                            return { items: itemEvents, vesselName: vesselInfo };
                        })
                        const podVesselEvents = vesselIdsPod.map(vessel => {
                            const vesselInfo = vessels.find(e => e.id === vessel);
                            const itemEvents = podEvents.filter(e => e.vessel === vessel);
                            return { items: itemEvents, vesselName: vesselInfo };
                        })

                        const pol = buildItem("icon-anchor", stepShipment.titlePol, stepShipment.statusPol, polVesselEvents, locationPol, route.pol.date);
                        const pod = buildItem("icon-anchor", stepShipment.titlePod, stepShipment.statusPod, podVesselEvents, locationPod, route.pod.date);

                        resultTracker = [
                            ...resultTracker,
                            {
                                carrierName,
                                containerId,
                                vessels,
                                pol,
                                pod,
                                prePol,
                                postPod
                            }
                        ];
                    });

                    setTrackers(resultTracker);
                    setSelectedTracker(resultTracker[0]);
                    setSearchParamsShipment(params);
                    if (callback) callback();

                    setTimeout(() => {
                        setRoutes(data.route);
                    }, 1000);

                    // setRoutes(data.route);
                }
            }
            setLoading(false);
        } catch (error) {
            setNoResultSearch(true);
            if (errorCallback) errorCallback();
        }
    }

    const doCreateMarkAsActiveShipment = async (payload, callback) => {
        try {
            setLoading(true);
            const response = await createMarkAsActiveShipment(payload, loggedInUser);
            const { status } = response;

            if (status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }
    return (
        <TrackShipmentContext.Provider
            value={{
                selectedTracker,
                carriers,
                trackers,
                firstCarriers,
                firstLocode,
                origins,
                destinations,
                routes,
                tracker,
                trackerParams,
                doGetRoute,
                setCarriers,
                setOrigins,
                setDestinations,
                doGetCarriers,
                doGetTrackers,
                doGetTrackAndTraceV2,
                doGetLocodes,
                doCreateMarkAsActiveShipment,
                setSelectedTracker,
                setTrackers,
                setRoutes
            }}
        >
            <VesselScheduleContextProvider>
                {children}
            </VesselScheduleContextProvider>
        </TrackShipmentContext.Provider>
    );
}
