import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    EditShipmentInfoInput,
    Collapse,
    EditShipmentInfoDatepicker
} from 'components';
import moment from 'moment';

export const EditCutOff = ({ 
    register = () => {}, 
    cutOff,
    watch = () => {}, 
    editShipmentInfo,
    isArchive = false 
}) => {
    const [cutOffDate,setCutOffDate] = useState('');

    useEffect(()=>{
        const date = cutOff && cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : null
        setCutOffDate(date);
    },[cutOff])

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Cut Off" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="vgmSubmissionTimeline"
                                        refs={register()}
                                        viewOnly={isArchive}
                                        label="VGM submission timeline"
                                        defaultValue={cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                                        originalvalue={cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('vgmSubmissionTimeline',cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="siSubmission"
                                        refs={register()}
                                        viewOnly={isArchive}
                                        label="SI submission"
                                        defaultValue={cutOff && cutOff.siSubmission ? cutOff.siSubmission : ''}
                                        originalvalue={cutOff && cutOff.siSubmission ? cutOff.siSubmission : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('siSubmission',cutOff && cutOff.siSubmission ? cutOff.siSubmission : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                     <EditShipmentInfoDatepicker
                                        className="mtx2"
                                        name="cutOffDate"
                                        refs={register()}
                                        viewOnly={isArchive}
                                        label="Date"
                                        value={cutOffDate}
                                        onDatesChange={(date) => {setCutOffDate(date)}}
                                        originalvalue={cutOff && cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={moment(cutOffDate).format('DD MMM YYYY')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="cutOffTime"
                                        refs={register()}
                                        viewOnly={isArchive}
                                        label="Time"
                                        defaultValue={cutOff && cutOff.time ? cutOff.time : ''}
                                        originalvalue={cutOff && cutOff.time ? cutOff.time : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('cutOffTime',cutOff && cutOff.time ? cutOff.time : '')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditCutOff.propTypes = {
    register: PropTypes.func,
    cutOff: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
