import React from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';
import moment from 'moment';

export const CutOff = ({ cutOff = {} }) => {

    return <InfoBlock
        className="tr__details-box"
        title="CutOff"
    >
        <InfoRow >
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="VGM submission timeline"
                        value={cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="SI submission"
                        value={cutOff.siSubmission ? cutOff.siSubmission : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Date"
                        value={cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Time"
                        value={cutOff.time ? cutOff.time : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

CutOff.propTypes = {
    cutOff: PropTypes.object
};
