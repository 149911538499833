import React from 'react';
import PropTypes from 'prop-types';

import {
    Collapse
} from 'components';
import { InputTags } from 'components/common';

export const EditInvoice = ({ register, shipmentDetails, editShipmentInfo, isArchive = false }) => {

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Invoice" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <InputTags 
                                        className="mtx2"
                                        name="invoiceNumber"
                                        refs={register()}
                                        maxLength={50}
                                        label="Invoice number"
                                        placeholder="Eg: BN94500331575, BN94500331575"
                                        defaultValue={shipmentDetails && shipmentDetails.invoices ? shipmentDetails.invoices : []}
                                        isTooltip={editShipmentInfo}
                                        viewOnly={isArchive}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditInvoice.propTypes = {
    register: PropTypes.func,
    shipmentDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
