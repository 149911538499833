import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { TemplateTaskHeader, TemplateTaskItem } from 'components';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


export const TemplateTasks = ({ isMyTemplate = true, sections }) => {
    return (
        <div className="tr__template-task">
            <TemplateTaskHeader className="mbx1" />
                <DndProvider backend={HTML5Backend}>
                    <div className="tr__template-task-list">
                        <TemplateTaskItem
                            sections={sections}
                            isMyTemplate={isMyTemplate}
                        />
                    </div>
                </DndProvider>
        </div>
    )
};

TemplateTasks.propTypes = {
    isMyTemplate: PropTypes.bool,
    sections:PropTypes.array
};