import React, { useContext, useState, useEffect } from 'react';

import {
    Tab,
    TabPane,
    ShipmentList
} from 'components';
import { useHistory } from 'react-router-dom';
import { HomeContext, ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import {
    // ACTIVE_SHIPMENT_PAGE_SIZE,
    ACTIVE_SHIPMENT_TABS,
    ACTIVE,
    ACTIVE_SHIPMENT_PAGE_SIZE
} from 'actions';

export const ShipmentTabs = ({ setCurrentTab = () => {}, currentTab }) => {
    const history = useHistory();
    const { isArchive, setShipment, seachParamActive,filterParamsShipment } = useContext(HomeContext);
    const { setTrackers, setSelectedTracker, setRoutes } = useContext(TrackShipmentContext);
    const { 
        doGetActiveShipments, 
        activeShipments,
        needActiveShipments,
        starActiveShipments,
        setNeedAttentionActiveShipments,
        setActiveShipments,
        totalPages,
        currentPage,
        doAddStarShipment,
        doRemoveStarShipment
    } = useContext(ActiveShipmentsContext);
    const [tabs,setTabs] = useState(isArchive
        ? [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.COMPLETED, ACTIVE_SHIPMENT_TABS.CANCELLED]
        : [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.MY_TASK]);
        
    /*eslint-disable */
    useEffect(() => {
        setNeedAttentionActiveShipments([]);
        setActiveShipments([]);
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE,
            name: seachParamActive
        });
    }, [seachParamActive])
    
    useEffect(() => {
        setTabs(isArchive
            ? [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.COMPLETED, ACTIVE_SHIPMENT_TABS.CANCELLED]
            : [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.MY_TASK]);
    }, [isArchive])
    /*eslint-enable */

    const handleShowDetails = shipmentId => {
        if (shipmentId) {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
            history.push(`/${isArchive ? 'archive' : 'active-shipments'}/${shipmentId._id}`);
            setShipment(shipmentId);
        }
    }

    const handleTabChange = tab => {
        let tabOption = ACTIVE_SHIPMENT_TABS.ALL;
        switch(tab) {
            case ACTIVE_SHIPMENT_TABS.ALL:
                tabOption = ACTIVE_SHIPMENT_TABS.ALL;
                break;
            case ACTIVE_SHIPMENT_TABS.MY_TASK:
                tabOption = ACTIVE_SHIPMENT_TABS.MY_TASK.replace('_', '');
                break;
            default:
        }
        setCurrentTab(tabOption);

        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: tabOption,
            status: ACTIVE,
            name: seachParamActive
        });
    }

    const handlePageChange = (page) => {
        let params;
        if (filterParamsShipment) {
            filterParamsShipment.page = page;
            filterParamsShipment.tab = currentTab;
            params = filterParamsShipment
        } else {
            params = {
                page: page,
                limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                tab: currentTab,
                status: ACTIVE,
                name: seachParamActive
            }
        }
        doGetActiveShipments(params);
        console.log(page)
    }

    const handleStarShipment = (shipment) => {
        console.log(shipment._id)
        if (shipment && shipment._id) {
            doAddStarShipment(shipment._id, currentTab, () => {
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive
                });
            })
        }
        
    }

    const handleRemoveStarShipment = (shipment) => {
        if (shipment && shipment._id) {
            doRemoveStarShipment(shipment._id, currentTab, () => {
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive
                });
            })
        }
    }

    return (
        <div className="tr__shipment-tabs mtx2">
            <Tab initialTab={0} onTabClick={handleTabChange}>
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={tab?.toLowerCase().replace('_', ' ')}
                        tabSlug={tab}
                    >
                        <>
                            {starActiveShipments && (
                                <ShipmentList
                                    className='mbx4'
                                    title="Star Shipments"
                                    shipments={starActiveShipments}
                                    onShowDetails={handleShowDetails}
                                    isArchive={isArchive}
                                    onStarShipment={handleRemoveStarShipment}
                                    isStar={true}
                                    currentTab={currentTab}
                                />
                            )}
                            {needActiveShipments && (
                                <ShipmentList
                                    className='mbx4'
                                    title="Needs Your Attention"
                                    shipments={needActiveShipments}
                                    onShowDetails={handleShowDetails}
                                    isArchive={isArchive}
                                    onStarShipment={handleStarShipment}
                                    currentTab={currentTab}
                                />
                            )}
                            <ShipmentList
                                className='mbx4'
                                title="Other Shipments"
                                shipments={activeShipments}
                                onShowDetails={handleShowDetails}
                                isArchive={isArchive}
                                seachParamActive={seachParamActive}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                onStarShipment={handleStarShipment}
                                currentTab={currentTab}
                            />
                        </>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};
