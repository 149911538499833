import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    MultiSelect,
    ItemCard
} from 'components';
import avatar from 'assets/images/avatar.png';
import { AuthContext, MemberContext, TeamContext } from 'contexts';
import { GET_FILE, MEMBER, TEAM } from 'actions';

export const ChooseFreightPartner = ({
    register = () => {},
    errors = [],
    formState,
    selectedValues = [],
    setSelectedValues = () => {}
}) => {
    const { userInfo } = useContext(AuthContext);
    const { members, doGetMembers } = useContext(MemberContext);
    const { teams, doGetTeams } = useContext(TeamContext);

    const [partners, setPartners] = useState([0]);
    const [dropdownList, setDropdownList] = useState([]);
    const [memberLoading, setMemberLoading] = useState(false);
    const [teamLoading, setTeamLoading] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        doGetMembers({
            isSearching: true
        });
        doGetTeams({
            isSearching: true,
            teamOfCurrentUser: false
        });
    }, [])
    
    useEffect(() => {
        if (members && members.length > 0 || teams && teams.length > 0) {
            setDropdownList([...(members || []).map(mem => {
                return {
                    ...mem,
                    type: MEMBER
                }
            }), ...(teams || []).map(team => {
                return {
                    ...team,
                    type: TEAM
                }
            })].sort((a, b) => a.name.localeCompare(b.name)));
        }
    }, [members, teams]);
    /*eslint-enable */

    const handleAddPartner = () => {
        setPartners(oldPartners => [...oldPartners, oldPartners.length]);
        setSelectedValues(oldValues => [...oldValues, []])
    }

    const handleSearchPartners = value => {
        setMemberLoading(true);
        setTeamLoading(true);

        doGetMembers({
            term: value,
            isSearching: true,
            disableInviteMember: true
        }, () => {
            setMemberLoading(false);
        }, null, false);

        doGetTeams({
            term: value,
            isSearching: true,
            teamOfCurrentUser: false
        }, () => {
            setTeamLoading(false);
        }, null, false);
    };

    const getLabel = no => {
        const newNo = no + 1;
        switch(newNo) {
            case 1:
                return '1st';
            case 2:
                return '2nd';
            case 3:
                return '3rd';
            default:
                return `${newNo}th`;
        }
    }

    const handleAddPartners = (partner, items) => setSelectedValues(oldValue => [...oldValue.map((old, oldIndex) => {
        if (oldIndex === partner) return [
            ...old,
            ...items
        ];
        return old;
    })])

    const handleRemove = (partner, item) => setSelectedValues(oldValue => [...oldValue.map((old, oldIndex) => {
        if (oldIndex === partner) return old.filter(o => o.id !== item.id);
        return old;
    })])

    return (
        <div className="tr__choose-freight-partner">
            <div className="tr__choose-freight-partner--form">
                {partners.map(partner => (
                    <MultiSelect
                        key={partner}
                        className="mbx4"
                        name={`freightPartner-${partner}`}
                        options={dropdownList.filter(item => !selectedValues.some(i => i.length > 0 ? i[0].id === item._id : false) && userInfo._id !== item._id).map(item => {
                            return {
                                id: item._id,
                                // icon: item.type === MEMBER ? (item.avatar || avatar) : 'icon-users',
                                icon: item.type === MEMBER ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users',
                                title: item.name,
                                description: item.type === MEMBER ? item.email : item.organization.name,
                                type: item.type
                            }
                        })}
                        value={selectedValues[partner]}
                        label={`Send an Email to The ${getLabel(partner)} Freight Partner`}
                        addButton={true}
                        addButtonLabel="Add"
                        onInputChange={handleSearchPartners}
                        disabled={selectedValues[partner] && selectedValues[partner].length > 0}
                        placeholder={selectedValues[partner] && selectedValues[partner].length
                            ? 'You can only invite 1 individual or team for each single partner'
                            : 'You can search by name, email, team name or invite new people'}
                        onChange={items => handleAddPartners(partner, items)}
                        onRemove={item => handleRemove(partner, item)}
                        register={register}
                        errors={errors}
                        formState={formState}
                        errorMessage='Please choose at least 1 individual or team for each single partner'
                        single={true}
                        searchLoading={memberLoading || teamLoading}
                        renderList={mems => (
                            <div className="tr__partners d-flex flex-wrap mtx1">
                                {mems.length > 0 && mems.map((member, memberIndex) => (
                                    <ItemCard
                                        key={memberIndex}
                                        item={member}
                                        onRemove={item => handleRemove(partner, item)}
                                        cardOnly={true}
                                    />
                                ))}
                            </div>
                        )}
                    />
                ))}
                <p className="tr__link mbx2" onClick={handleAddPartner}>
                    <i className="icon-plus mrx1"/>
                    <span>Add more freight partner</span>
                </p>
            </div>
        </div>
    )
};

ChooseFreightPartner.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    formState: PropTypes.object
};
