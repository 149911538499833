import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  Input
} from 'components';
import { HomeContext, OrganizationContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';

export const JoinOrganization = ({ open = false, onCancel, onSubmit }) => {
  const { handleSubmit, register, errors } = useForm();
  const { setNotificationMessage } = useContext(HomeContext);
  const { organization, doJoinOrganization, doGetOrganization } = useContext(OrganizationContext);

  const handleSendRequest = (data) => {
    const { message } = data;
    const payload = {
      message: message
    }
    doJoinOrganization(organization._id, payload, () => {
      onCancel();
      setNotificationMessage(`<p> Request has been sent successfully!</p>`)
      doGetOrganization(organization._id)
    }, false)
  }


  return (
    <Modal
      open={open}
      className="tr__join-organization"
      onCancel={onCancel}
      title={`Join ${organization?.name}`}
      btnClasses="justify-content-end"
      submitButton
      submitButtonLabel="Send Request"
      onSubmitClick={onSubmit}
      cancelButton={true}
      cancelButtonLabel="Cancel"
      cancelButtonType="primary"
      cancelButtonClassNames="label"
      onCancelClick={onCancel}
      isBackDropClickable={false}
      renderForm={children => (
        <form onSubmit={handleSubmit(handleSendRequest)}>
            {children}
        </form>
    )}
    >
      <div className="text-center">
        <h6 className="h6 mtx2  mbx4 f-medium text-left">Leave a Message</h6>
        <Input
          name="message"
          className="mtx3 mbx4"
          placeholder="Enter message"
          maxLength="200"
          error={!!errors.message}
          refs={register({ required: true, validate: val => !val.trim() ? 'This field cannot be blank' : undefined })}
          errorMessage={getErrorMessage(errors.message, "Message", "This field cannot be blank")}
        />
      </div>
    </Modal>
  )
};

JoinOrganization.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};