import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
} from 'components';
import { HomeContext, TemplateContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { TEMPLATES_URL } from 'actions';

export const CloneTemplate = ({ open = false, onCancel, setCurrentTab }) => {
    const { setNotificationMessage } = useContext(HomeContext);
    const { templatesDetails, doCloneTemplate }  = useContext(TemplateContext);
    const history = useHistory();

    const handleCloneTemplate = () => {
        doCloneTemplate(templatesDetails && templatesDetails.id, () => {
            onCancel();
            setNotificationMessage(`
                <p> Template has been cloned successfully!</p>
            `);
            setCurrentTab(0);
            history.push(TEMPLATES_URL)
        })
    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Clone Template"
            btnClasses="justify-content-center"
            submitButton
            onSubmitClick={handleCloneTemplate}
            submitButtonLabel="Clone"
            cancelButton
            cancelButtonLabel="Cancel"
            cancelButtonType="primary"
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to clone this template?</h6>
            </div>
        </Modal>
    )
};