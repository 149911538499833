import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { ALL, MYTASK } from 'actions';

export const DeleteTask = ({ open = false, onCancel }) => {
    const { eventTask, setNotificationMessage, setIsEditTask, currentTabManagement, isTemplate, setIsTemplate } = useContext(HomeContext);
    const { setDeleteTask } = useContext(ModalContext);
    const { doDeleteTask, doGetTaskManagements } = useContext(ManagementContext);
    const { doDeleteTaskTemplate, doGetTemplatesDetails, templatesDetails } = useContext(TemplateContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    
    const handleDeleteTask = () => {
        if(isTemplate === true) {
            doDeleteTaskTemplate(eventTask.id, () => {
                setIsTemplate(false);
                onCancel();
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(`
                    <p> Task has been deleted successfully!</p>
                `);
            })
        } else {
            doDeleteTask(shipmentId, eventTask.id, () => {
                setDeleteTask(false);
                setIsEditTask(false);
                if(currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }
                setNotificationMessage(`
                <p> Task has been deleted successfully!</p>
            `);
            })
        }
          
    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Delete Task"
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={handleDeleteTask}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to delete this task?</h6>
            </div>
        </Modal>
    )
};