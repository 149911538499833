import axios from 'axios';
import {
    AUTH_LOGIN,
    AUTH_REGISTER,
    AUTH_CHANGE_PASSWORD,
    AUTH_GET_USER_INFO,
    AUTH_GENERATE_TOKEN,
    AUTH_FORGOT_PASSWORD,
    AUTH_CONFIRM_FORGOT_PASSWORD,
} from './constants';

export const authHeaders = token => {
    return {
        headers: {
            'Authorization': token
        }
    }
}

/** Login
* @param {
*      userName: string
*      password: string
* } payload 
*/
export const login = payload => {
    if (!payload) return;
    return axios.post(AUTH_LOGIN, payload);
};

/** Register
* @param {
*      userName: string
*      email: string
*      fullName: string
*      password: string
* } payload 
*/
export const register = payload => {
    if (!payload) return;
    return axios.post(AUTH_REGISTER, payload);
};

/** Change password
* @param {
*      oldPassword: string
*      password: string
* } payload 
*/
export const changePassword = (payload, token) => {
    if (!payload) return;
    return axios.put(AUTH_CHANGE_PASSWORD, payload, authHeaders(token));
};

/** Get user's info */
export const getUserInfo = token => {
    return axios.get(AUTH_GET_USER_INFO, authHeaders(token));
};

/** Generate token
* @param {
*      refreshToken: string
* } payload 
*/
export const generateToken = payload => {
    if (!payload) return;
    return axios.post(AUTH_GENERATE_TOKEN, payload);
};

/** Forgot password */
export const forgotPassword = userName => {
    return axios.get(AUTH_FORGOT_PASSWORD, {
        params: {
            userName
        }
    });
};

/** Confirm forgot password
* @param {
*      userName: string
*      password: string
*      confirmationCode: string
* } payload 
*/
export const confirmForgotPassword = payload => {
    if (!payload) return;
    return axios.post(AUTH_CONFIRM_FORGOT_PASSWORD, payload);
};