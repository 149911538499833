import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Dropdown } from 'components/common';
import { HomeContext, ModalContext, OrganizationContext } from 'contexts';
import { MINE } from 'actions';


export const NotificationDropdown = ({
    notifications = [],
    markAsRead = ids => { },
    deleteNotification = id => { } }) => {

    const { setNotificationId } = useContext(HomeContext);
    const { setCouponMessage } = useContext(HomeContext);
    const { setCountNotification } = useContext(ModalContext);
    const { doGetOrganizations } = useContext(OrganizationContext);
    const handleChangeUrl = (notification) => {
        markAsRead([notification.id], () => {
            window.location.href = notification.url
        });
    }

    const handleShowPopup = (notification) => {
        console.log(notification)
        const { message, id } = notification;
        doGetOrganizations({
            page: 0,
            isSearching: true,
            tab: MINE.toUpperCase(),
            unexpired: true,
        }, () => {
            setCouponMessage(message);
            setCountNotification(true);
            setNotificationId(id);
        }, true);

    }
    return (
        <div className="tr__notification-dropdown">
            <div className="tr__notification-dropdown__header d-flex align-items-center justify-content-between">
                <h4 className="h4 f-medium">Notification</h4>
                <p className="tr__link secondary d-flex align-items-center"
                    onClick={() => {
                        const ids = notifications
                            .reduce((arr, e) => arr.concat(e.items), [])
                            .filter(e => !e.seen)
                            .map(e => e.id);
                        markAsRead(ids);
                    }}>
                    <i className="icon-check mrx1" />
                    <span>Mark all as read</span>
                </p>
            </div>
            <div className="tr__notification-dropdown__body">
                {notifications.map((groupByDate, notificationIndex) => {
                    return <div className="tr__notification-dropdown__details" key={notificationIndex}>
                        {/* <div className="time f-medium">{groupByDate.daysAgo}</div> */}
                        {groupByDate.items.map((notification, index) => {
                            return <div
                                className={`tr__notification-dropdown__details--items d-flex align-items-center justify-content-between ${notification.seen ? '' : 'unread'} ${notification.enableClick ? '' : 'disabled'}`}
                                key={index}
                            >
                                <i className={`${notification.icon} mrx1 icon-notice`} />
                                <div className="content"
                                    onClick={() => {
                                        notification.url === 'POPUP' ? handleShowPopup(notification) : handleChangeUrl(notification)
                                    }}>
                                    <p className="f-medium">{notification.message}</p>
                                    <span className="f-medium">{notification.dateStr} </span>
                                </div>
                                <Dropdown
                                    mode="icon"
                                    options={notification.seen ?
                                        [
                                            {
                                                key: 2,
                                                value: 'Delete this notification'
                                            }
                                        ] :
                                        [
                                            {
                                                key: 1,
                                                value: 'Mark as read'
                                            },
                                            {
                                                key: 2,
                                                value: 'Delete this notification'
                                            }
                                        ]
                                    }
                                    dropdownPosition="left"
                                    onChange={value => {
                                        if (value.key === 1) {
                                            markAsRead([notification.id])
                                        } else if (value.key === 2) {
                                            deleteNotification(notification.id)
                                        }
                                    }}
                                >
                                    <i className="icon-dots" />
                                </Dropdown>
                            </div>
                        })}
                    </div>
                })}
            </div>

        </div>
    )
};

NotificationDropdown.propTypes = {
    notifications: PropTypes.array,
    markAsRead: PropTypes.func,
    deleteNotification: PropTypes.func
};
