import React, { createContext, useContext, useState } from 'react';

import {
    addLinkedShipmentTask,
    addStarShipment,
    getActiveShipments,
    getBookingDetails,
    getSearchParams,
    OPERATION_FAILED_MESSAGE,
    removeStarShipment,
    saveSearchParams,
} from 'actions';
import { HomeContext, AuthContext } from 'contexts';
import { CollaboratorsContextProvider } from './CollaboratorsContext';
import { ManagementContextProvider } from './ManagementContext';
import { ShipmentInfoContextProvider } from './ShipmentInfoContext';
import { IssueContextProvider } from './IssueContext';
import { TemplateContextProvider } from './TemplateContext';
import { ExtractedContextProvider } from './ExtractedContext';
import { FtaAdvisorContextProvider } from './FtaAdvisorContext';
import { DocumentsContextProvider } from './DocumentsContext';

export const ActiveShipmentsContext = createContext();

export const ActiveShipmentsContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [activeShipments, setActiveShipments] = useState([]);
    const [needActiveShipments, setNeedActiveShipments] = useState([]);
    const [needAttentionActiveShipments, setNeedAttentionActiveShipments] = useState([]);
    const [starActiveShipments, setStarActiveShipments] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [linkedShipment, setLinkedShipment] = useState();
    const [hasSearchData, setHasSearchData] = useState(false);
    const [bookingDetailsNumber, setBookingDetailsNumber] = useState();

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetActiveShipments = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);

            const response = await getActiveShipments(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const { activeShipments, attentionShipments, starShipments } = data;
                if (activeShipments && attentionShipments) {
                    setActiveShipments(data.activeShipments?.items || []);
                    setNeedActiveShipments(data.attentionShipments.items || []);
                    setStarActiveShipments(data.starShipments.items || []);
                    setTotalPages(data.activeShipments?.totalPage);
                    setCurrentPage(data.activeShipments?.currentPage);
                    setTotalItems(activeShipments.totalItems + attentionShipments.totalItems + starShipments.totalItems);
                    // setTotalItems(data.activeShipments?.totalItems);
                } else {
                    setActiveShipments(data.items || []);
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    setTotalItems(data.totalItems);
                }
                

                if (callback) callback();
            }

            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doCreateLinkedShipment = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
    
                const response = await addLinkedShipmentTask(shipmentId, payload, loggedInUser);
                const { data, status } = response;
    
                if (status === 200) {
                    if (callback) callback(data);
                    setLinkedShipment(data || {})
                }
    
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    const doSaveSearchParams = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
            setLoading(true);
    
            const response = await saveSearchParams(shipmentId, payload, loggedInUser);
            const { status } = response;
    
            if (status === 200) {
                if (callback) callback();
            }
    
            setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetSearchParams = async (shipmentId, callback) => {
        if (shipmentId) {
            try {
            setLoading(true);
    
            const response = await getSearchParams(shipmentId, loggedInUser);
            const { data, status } = response;
    
            if (status === 200) {
                if (data === "") {
                    setHasSearchData(false)
                } else {
                    setHasSearchData(true)
                }
                // (data !== "" ? setHasSearchData(true) : setHasSearchData(false));
                if (callback) callback(data);
            }
    
            setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetBookingDetails = async () => {
        try {
            setLoading(true);
    
            const response = await getBookingDetails(loggedInUser);
            const { data, status } = response;
    
            if (status === 200) {
                // const { bookingNumbers, blNumbers } = data;
                setBookingDetailsNumber(data);
            }
    
            setLoading(false);
            } catch (error) {
                handleException(error);
        }
    }

    const doAddStarShipment = async (shipmentId, currentTab, callback) => {
        try {
            setLoading(true);
    
            const response = await addStarShipment(shipmentId, currentTab, loggedInUser);
            const { status } = response;
    
            if (status === 200) {
                if (callback) callback();
            }
    
            setLoading(false);
            } catch (error) {
                handleException(error);
        }
    }

    const doRemoveStarShipment = async (shipmentId, currentTab, callback) => {
        try {
            setLoading(true);
    
            const response = await removeStarShipment(shipmentId, currentTab, loggedInUser);
            const { status } = response;
    
            if (status === 200) {
                if (callback) callback();
            }
    
            setLoading(false);
            } catch (error) {
                handleException(error);
        }
    }

    return(
        <ActiveShipmentsContext.Provider
            value={{
                activeShipments,
                needActiveShipments,
                starActiveShipments,
                hasSearchData,
                needAttentionActiveShipments,
                linkedShipment,
                currentPage,
                totalPages,
                totalItems,
                bookingDetailsNumber,
                doCreateLinkedShipment,
                doGetActiveShipments,
                setNeedAttentionActiveShipments,
                setActiveShipments,
                doSaveSearchParams,
                doGetSearchParams,
                doGetBookingDetails,
                doAddStarShipment,
                doRemoveStarShipment
            }}
        >
            <TemplateContextProvider>
                <IssueContextProvider>
                    <CollaboratorsContextProvider>
                        <ShipmentInfoContextProvider>
                            <ManagementContextProvider>
                                <ExtractedContextProvider>
                                    <FtaAdvisorContextProvider>
                                        <DocumentsContextProvider>
                                            {children}
                                        </DocumentsContextProvider>
                                    </FtaAdvisorContextProvider>
                                </ExtractedContextProvider>
                            </ManagementContextProvider>
                        </ShipmentInfoContextProvider>
                    </CollaboratorsContextProvider>
                </IssueContextProvider>
            </TemplateContextProvider>
        </ActiveShipmentsContext.Provider>
    );
};
