import axios from 'axios';
import { authHeaders } from './auth';
import {
    ACTIVE_SHIPMENT_DOCUMENTS,
    ACTIVE_SHIPMENT_DOCUMENTS_TASK,
    GENERATE_TRADE_TRUST,
    GET_TRADE_TRUST
} from './constants';

/** Get all documents
 * @param {
 * 		page: number
 * } params
 */

export const getDocuments = (shipmentId, params, token) => {
    if (!params) return;
    return axios.get(ACTIVE_SHIPMENT_DOCUMENTS.replace(':shipmentId', shipmentId), params ? {
        params,
        ...authHeaders(token)
    } : authHeaders(token));
}

/** remove documents of a task
 * @param {
 * 		ids: []
 * } payload
 */
export const removeDocumentsTask = (taskId, payload, token) => {
    if (!payload) return;
    return axios.delete(ACTIVE_SHIPMENT_DOCUMENTS_TASK.replace(':taskId', taskId), {
        data: payload,
        ...authHeaders(token)
    });
}

/** upload documents of a task
 * @param {
 * 		ids: []
 * } payload
 */
export const uploadDocumentsTask = (taskId, payload, token) => {
    if (!payload) return;
    return axios.put(ACTIVE_SHIPMENT_DOCUMENTS_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

/** upload documents of a task in task daskboard
* @param {
* 		ids: []
* } payload
*/
export const uploadTaskDashboard = (taskId, payload, token) => {
    if (!payload) return;
    return axios.post(ACTIVE_SHIPMENT_DOCUMENTS_TASK.replace(':taskId', taskId), payload, authHeaders(token));
}

/** generate tradeTrust
    */
export const generateTradeTrust = (fileName, shipmentId, token) => {
    return axios.post(`${GENERATE_TRADE_TRUST}?fileName=${fileName}&shipmentId=${shipmentId}`, null, authHeaders(token));
}


/** get tradeTrust
    */
export const getTradeTrust = (ids, token) => {
    return axios.post(`${GET_TRADE_TRUST}`, ids, authHeaders(token));
}

