import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const StepsBreadcrum = ({ steps = [], activeStep = 0 }) => {
    return steps.length > 0 ?(
        <div className="tr__steps-breadcrum d-flex align-items-center mbx4">
            {steps.map((step, stepIndex) => (
                <div className={`step ${stepIndex === activeStep ? 'active' : ''} ${stepIndex < activeStep ? 'done' : ''}`} key={stepIndex}>
                    <p className="text-center">{step.name}</p>
                    {step.date && <p className="date--port text-center">{step.date} </p> }
                </div>
            ))}
        </div>
    ) : null;
};

StepsBreadcrum.propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number
};