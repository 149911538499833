import React, { useEffect, useState } from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';

export const ContainerTracking = ({ containerTracking = {} }) => {
    const [containers,setContainers] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if(containerTracking && containerTracking.containers && containerTracking.containers.length > 0) setContainers(containerTracking.containers)
    }, [containerTracking])
    /*eslint-enable */

    return <InfoBlock
        className="tr__details-box"
        title="Container Tracking"
    >
        <InfoRow>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Measurement"
                        value={containerTracking.measurement ? containerTracking.measurement : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Container yard"
                        value={containerTracking.containerYard ? containerTracking.containerYard : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                <InfoCell
                        className="col-sm-6"
                        label="Commodity description"
                        value={containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Service type"
                        value={containerTracking.serviceType ? containerTracking.serviceType : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Gross weight unit"
                        value={containerTracking.grossWeight && containerTracking.grossWeight.weightUnit ? containerTracking.grossWeight.weightUnit : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Gross weight value"
                        value={containerTracking.grossWeight && containerTracking.grossWeight.value ? containerTracking.grossWeight.value : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3 mbx3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="HS Code"
                        value={containerTracking.hsCode ? containerTracking.hsCode : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Number of containers"
                        value={containerTracking.numberOfContainers ? containerTracking.numberOfContainers : ''}
                    />
                </div>
            </div>
            <h4 className="h4 f-medium tr__info-block--label">
                <span>Containers</span>
            </h4>
            {containers.length  > 0 
                ? containers.map((container, index) =>
                    (
                        <div className="col-12 col-sm-12 " key={index}>
                            <div className="row">
                                <div className="col-12 col-sm-3 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Container number"
                                            value={container.containerNumber ? container.containerNumber : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Carrier seal number"
                                            value={container.carrierSealNumber ? container.carrierSealNumber : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-12 col-sm-3 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Container type"
                                            value={container.containerType ? container.containerType : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Weight unit"
                                            value={container.weight && container.weight.weightUnit ? container.weight.weightUnit : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-12 col-sm-3 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Weight value"
                                            value={container.weight && container.weight.value ? container.weight.value : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Volume unit"
                                            value={container.volume && container.volume.unit ? container.volume.unit : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-12 col-sm-3 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="Volume value"
                                            value={container.volume && container.volume.value ? container.volume.value : ''}
                                        />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    )
                ) : null
            }
            
        </InfoRow>
    </InfoBlock>;
};

ContainerTracking.propTypes = {
    ContainerTracking: PropTypes.object
};
