import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
    Input
} from 'components';
import { HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';
import { useLocation } from 'react-router-dom';
import { ALL, MYTASK } from 'actions';

export const EditSection = ({ open = false, onCancel, isEdit = false }) => {
    const {
        setNotificationMessage,
        sectionName,
        sectionId,
        currentTabManagement,
        setCurrentTabManagement,
        isTemplate,
        setIsTemplate,
        sectionOrder,
    } = useContext(HomeContext);
    const { setIsEditSection, setEditSection } = useContext(ModalContext);

    const { doCreateSection, doUpdateSection, doRomoveSection, doGetTaskManagements } = useContext(ManagementContext);
    const { doEditSection, templatesDetails, doGetTemplatesDetails, doDeleteSection } = useContext(TemplateContext);

    const { handleSubmit, register, reset, errors } = useForm();
    const handleCloseModal = () => {
        onCancel();
        setIsEditSection(false);
    }
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];

    const handleEditSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionName: sectionName.trim()
        }
        const payloadTemplate = {
            name: sectionName.trim(),
            order: sectionOrder
        }
        if (isTemplate === true) {
            doEditSection(sectionId, payloadTemplate, () => {
                onCancel();
                setIsEditSection(false);
                setIsTemplate(false);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(`
                    <p> Section has been updated successfully!</p>
                `)
            })
        } else {
            doUpdateSection(sectionId, payload, () => {
                setEditSection(false);
                setIsEditSection(false);
                if (currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }

                setNotificationMessage(`
                <p> Section has been updated successfully!</p>
            `);
            });
        }

    }

    const doAddSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionType: 'USER_SECTION',
            sectionName: sectionName.trim()
        }
        doCreateSection(shipmentId, payload, () => {
            reset();
            setEditSection(false);
            setIsEditSection(false);
            setCurrentTabManagement(ALL);
            setNotificationMessage(`
            <p> Section has been added successfully!</p>
        `);
        });
    }

    const handleDeleteSection = () => {
        if (isTemplate === true) {
            doDeleteSection(sectionId, () => {
                onCancel();
                setIsEditSection(false);
                setIsTemplate(false);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(`
                    <p> Section has been deleted successfully!</p>
                `)
            })
        } else {
            doRomoveSection(shipmentId, sectionId, () => {
                setEditSection(false);
                setIsEditSection(false);
                if (currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }
                setNotificationMessage(`
                    <p> Section has been delete successfully!</p>
                `);
            });
        }
        


    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={handleCloseModal}
            title={isEdit ? 'Edit section' : "Add new section"}
            btnClasses="justify-content-between"
            submitButton={true}
            onSubmitClick={isEdit ? handleSubmit(handleEditSection) : handleSubmit(doAddSection)}
            submitButtonLabel={isEdit ? "Save Change" : "Add"}
            cancelButton={isEdit}
            onCancelClick={isEdit ? handleSubmit(handleDeleteSection) : () => { }}
            cancelButtonLabel="Delete Section"
            cancelButtonType="primary"
            cancelButtonClassNames="outline"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <h4 className="h4 f-medium mbx2">Change Section Name</h4>
            <div className="tr__section-name">
                <Input
                    label="Section name"
                    className="mbx3"
                    name="sectionName"
                    refs={register({ required: true, validate: val => !val.trim() ? 'This field cannot be blank' : undefined})}
                    defaultValue={isEdit ? sectionName : ''}
                    error={!!errors.sectionName}
                    errorMessage={getErrorMessage(errors.sectionName, "section name", 'This field cannot be blank')}
                />
            </div>
        </Modal>
    )
};