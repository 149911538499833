import React, { useContext } from 'react';

import './index.scss';

import { ModalContext } from 'contexts';
import PropTypes from 'prop-types';
import {RequestFilter } from 'components';


export const ActionTopHeader = ({
    className = '',
    title = '',
    searchParams,
    setSearchParams = () => {},
    onFilterApplied = () => {},
    onClearFilters = () => {},
    selectedTab
}) => {
    const { requestFilter, setRequestFilter } = useContext(ModalContext);
    return (
        <>
            <div className={`tr__action-top-header d-flex align-items-center justify-content-between ${className}`}>
                {title && (
                    <h1 className="h1 f-medium mtx2 mbx4">{title}</h1>
                )}
                    <div className="action d-flex align-items-center">
                        <h4 className="h4 f-medium text-right" onClick={() => setRequestFilter(true)}>
                            <i className="icon-filter" />
                            <span>Filter</span>
                        </h4>
                    </div>
            </div>
            <RequestFilter
                open={requestFilter}
                onCancel={() => setRequestFilter(false)}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                onFilterApplied={onFilterApplied}
                onClearFilters={onClearFilters}
                selectedTab={selectedTab}
            />
        </>
    )
};

ActionTopHeader.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    onFilterApplied: PropTypes.func,
    onClearFilters: PropTypes.func,
    selectedTab: PropTypes.string
};