import React, { useContext, useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { AuthContext, HomeContext, MemberContext, ModalContext, TrackShipmentContext, NotificationContext } from 'contexts';
import avatar from 'assets/images/avatar.png';
import logo from 'assets/images/logo.png';
import {
    UserDropdown,
    Dropdown,
    Sidebar,
    NotificationDropdown,
    DeleteConfirmation
} from 'components';
import {
    userOptions,
    homeLinks,
    removeAll,
    languageOptions
} from 'utils';
import { ACTIVE_SHIPMENTS_URL, LOGIN_URL, LANGUAGE } from 'actions';
import { Input } from 'components/common';

import { getToken, getDaysAgo, getTimeAgo } from 'utils';
import { NOTIFICATION_AUTH } from 'actions/constants';
import Pusher from 'pusher-js';
import moment from 'moment';
import * as _ from 'lodash';

export const Header = () => {
    const history = useHistory();
    const [t] = useTranslation();

    // const { userInfo, setUserInfo, setLoggedInUser } = useContext(AuthContext);
    const { userInfo } = useContext(AuthContext);
    const { signOut, setSignOut, setNoResultSearch, setViewAllDocuments } = useContext(ModalContext);
    const {
        setSeachParamActive,
        setIsSearchingShipment,
        setIdTemplate,
        searchTerm,
        setSearchTerm,
        setSelectLinkVessel,
        setChooseLinkedVessel,
        setUnLinkVessel
    } = useContext(HomeContext);
    const {
        rawNotifications,
        setRawNotifications
    } = useContext(NotificationContext);
    const { doGetMember } = useContext(MemberContext);
    const { setTrackers } = useContext(TrackShipmentContext);

    // const [rawNotifications, setRawNotifications] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [countNotifications, setCountNotifications] = useState(0);
    const [token, setToken] = useState(null);
    const { config, doGetConfig, doGetNotifications, doMaskAsRead, doDeleteNotification } = useContext(NotificationContext);
    const [language, setLanguage] = useState('en');
    let setupComplete = false;

    /*eslint-disable */
    useEffect(() => {
        const selectedLanguage = localStorage.getItem(LANGUAGE);
        if (selectedLanguage) setLanguage(selectedLanguage);
    }, [])

    useEffect(() => {
        if (!token) {
            setToken(getToken());
        }
    }, [token])

    useEffect(() => {
        if (!rawNotifications) {
            doGetNotifications(data => setRawNotifications(data));
        }

        if (config && config.key && config.cluster && config.channelId) {
            setupComplete = true;
            const pusher = new Pusher(config.key, {
                cluster: config.cluster,
                authEndpoint: NOTIFICATION_AUTH,
                auth: {
                    headers: {
                        'authorization': token
                    }
                }
            });

            const commonChannel = pusher.subscribe(config.channelId);
            commonChannel.bind('PUSH_NOTIFICATION', data => {
                setRawNotifications(oldData =>
                    [
                        ...oldData,
                        ...[data]
                    ]
                );
            });
        } else {
            doGetConfig();
        }
    }, [config])

    useEffect(() => {
        if (rawNotifications && rawNotifications.length) {
            const arr = rawNotifications.map(e => ({
                ...e,
                icon: e.category === 'SHIPMENT' ? 'icon-ship' : 'icon-organization danger',
                daysAgo: getDaysAgo(new Date(e.time)),
                timeStr: moment(new Date(e.time)).format('hh:mm A'),
                dateStr: moment(new Date(e.time)).format('DD MMM YYYY | hh:mm A')
            }))
            setCountNotifications(arr.filter(e => !e.seen).length);

            const groupNotification = _.chain(arr)
                .groupBy('daysAgo')
                .map((value, key) => ({ daysAgo: key, items: value.sort((a, b) => b.time - a.time) }))
                .value()
                .sort((a, b) => b.items[0].time - a.items[0].time);

            // calc hours/mins ago for notification come today
            if (groupNotification[0].daysAgo[0] === 'T') {
                groupNotification[0].items.forEach(item => {
                    item.timeStr = getTimeAgo(new Date(item.time));
                });
            }

            setNotifications(groupNotification);
        } else {
            setCountNotifications(0);
            setNotifications([]);
        }
    }, [rawNotifications])
    /*eslint-enable */

    const onInput = (searchValue) => {
        setSeachParamActive(searchValue);
        history.push(ACTIVE_SHIPMENTS_URL);
    }

    const handleChangeRoute = option => {
        if (option.key === 3) {
            const { _id } = userInfo;
            doGetMember(_id);
        }
        if (option.key === 5) {
            setSignOut(true);
        } else if (option.key === 6) {
            // setUserInfo(null);
            // setLoggedInUser(null);
            removeAll();
            if (window) window.location = '/';
            else if (option.path) {
                history.push(option.path);
            }
        } else {
            if (option.path) history.push(option.path);
        }
    }

    const changeLanguage = (e, lng) => {
        if (e) e.preventDefault();
        localStorage.setItem(LANGUAGE, lng);
        // i18n.changeLanguage(lng);
        window.location.reload();
    }
    
    return (
        <div className="tr__header--wrapper">
            <div className="tr__header">
                <div className="tr__header--logo text-center">
                    <NavLink to="/">
                        <img src={logo} alt="Trames Client" />
                    </NavLink>
                </div>
                <div className="tr__header--menu">
                    <Sidebar
                        links={homeLinks}
                        setIsSearchingShipment={setIsSearchingShipment}
                        setSearchTerm={setSearchTerm}
                        setSeachParamActive={setSeachParamActive}
                        setIdTemplate={setIdTemplate}
                        setNoResultSearch={setNoResultSearch}
                        setTrackers={setTrackers}
                        setViewAllDocuments={setViewAllDocuments}
                        setSelectLinkVessel={setSelectLinkVessel}
                        setChooseLinkedVessel={setChooseLinkedVessel}
                        setUnLinkVessel={setUnLinkVessel}
                    />
                </div>
                <div className="tr__header--search">
                    <Input
                        icon="icon-search"
                        iconPosition="right"
                        name="searchShipment"
                        placeholder="Search all your active shipments"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        onIconClick={() => onInput(searchTerm)}
                        onKeyPress={e => {
                            if (e.which === 13) onInput(searchTerm);
                        }}
                    />
                </div>
                <div className="tr__header--notice">
                    <Dropdown
                        mode="icon"
                        dropdownPosition="right"
                        render={(options, onChange) => <NotificationDropdown
                            notifications={notifications}
                            markAsRead={(ids, callback) => doMaskAsRead(ids, result => {
                                if (result) {
                                    // set seen for notifications
                                    setRawNotifications(oldData => [
                                        ...oldData.map(e => {
                                            if (ids.includes(e.id)) {
                                                e.seen = true;
                                            }
                                            return e;
                                        })
                                    ]);
                                }

                                if (callback)
                                    callback();
                            })}
                            deleteNotification={id => doDeleteNotification(id, result => {
                                if (result) {
                                    // set seen for notifications
                                    setRawNotifications(oldData => [
                                        ...oldData.filter(e => e.id !== id)
                                    ]);
                                }
                            })}
                        />
                        }
                    >
                        <div className="notification">
                            <i className="icon-bell" />
                            {
                                countNotifications > 0 &&
                                <span className="badge">{countNotifications}</span>

                            }
                        </div>
                    </Dropdown>
                </div>
                <div className="tr__header--user d-flex">
                    <Dropdown
                        mode="icon"
                        options={languageOptions.map(l => {
                            return {
                                ...l,
                                value: t(l.value)
                            }
                        })}
                        dropdownPosition="right"
                        onChange={value => changeLanguage(null, value.code)}
                    >
                        <span className="mr-2">{t(`home.languages.${language}`)}</span>
                        <i className="icon-chevron-down" />
                    </Dropdown>
                    <Dropdown
                        mode="icon"
                        options={userOptions}
                        dropdownPosition="right"
                        onChange={handleChangeRoute}
                        render={(options, onChange) => <UserDropdown
                            options={options}
                            onChange={onChange}
                            onOrgSelect={org => console.log(org)}
                        />
                        }
                    >
                        {userInfo && (
                            <>
                                <img src={userInfo.avatar || avatar} alt="Avatar" />
                                <p>{userInfo.name}</p>
                            </>
                        )}
                        <i className="icon-chevron-down" />
                    </Dropdown>
                </div>
            </div>

            <DeleteConfirmation
                open={signOut}
                onCancel={() => setSignOut(false)}
                onSubmit={() => handleChangeRoute({
                    key: 6,
                    path: LOGIN_URL
                })}
                title="Sign Out"
                message={`Are you sure you want to sign out?`}
            />
        </div>
    );
};
