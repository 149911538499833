import React, { useEffect, useState } from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';

export const TranshipmentInfo = ({ transhipmentInfo = [] }) => {
    const [transhipmentInfos,setTranshipmentInfos] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if(transhipmentInfo && transhipmentInfo.length > 0) setTranshipmentInfos(transhipmentInfo)
    }, [transhipmentInfo])
    /*eslint-enable */

    return transhipmentInfos.length  > 0 ? <InfoBlock
        className="tr__details-box"
        title="Transhipment Information"
    >
        <InfoRow>
            { transhipmentInfos.map((transhipment, index) =>
                    (
                        <div className={`col-12 col-sm-12 ${index > 0 ? 'mtx3' : ''}`} key={index}>
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS port"
                                            value={transhipment.tsPort ? transhipment.tsPort : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS next port"
                                            value={transhipment.tsNextPort ? transhipment.tsNextPort : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-12 col-sm-3">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS vessel"
                                            value={transhipment.tsVessel ? transhipment.tsVessel : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS vessel IMO"
                                            value={transhipment.tsVesselIMO ? transhipment.tsVesselIMO : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-12 col-sm-3">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS ETD"
                                            value={transhipment.tsETD ? transhipment.tsETD : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label="TS ETA"
                                            value={transhipment.tsETA ? transhipment.tsETA : ''}
                                        />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    )
                ) 
            }
        </InfoRow>
    </InfoBlock> : null;
};

TranshipmentInfo.propTypes = {
    ContainerTracking: PropTypes.array
};
