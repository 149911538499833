import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
    Input,
    Textarea
} from 'components';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';
import { HomeContext, TemplateContext } from 'contexts';

export const EditTemplate = ({ open = false, onCancel }) => {
    const { setNotificationMessage } = useContext(HomeContext);
    const { handleSubmit, register, errors } = useForm();
    const { templatesDetails, doEditTemplate, doGetTemplatesDetails }  = useContext(TemplateContext);

    const handleEditTemplate = data => {
        console.log(data)
        const payload = {
            name: data.name.trim(),
            desc: data.description.trim()
        }
        doEditTemplate(templatesDetails && templatesDetails.id, payload, () => {
            doGetTemplatesDetails(templatesDetails && templatesDetails.id);
            setNotificationMessage(`
                <p> Template has been updated successfully!</p>
            `)
            onCancel();
        })
    }
    return (
        <Modal
            open={open}
            className="tr__edit-template"
            onCancel={onCancel}
            title="Edit Template"
            btnClasses="justify-content-end"
            submitButton
            submitButtonLabel="Save Changes"
            // cancelButton={true}
            // cancelButtonLabel="No"
            // cancelButtonType=""
            // cancelButtonClassNames="outline secondary"
            // onCancelClick={onCancel}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleEditTemplate)}>
                    {children}
                </form>
            )}
        >
            <div className="tr__edit-template__modal--form">
                <Input
                    label="Title"
                    className="mbx3"
                    name="name"
                    refs={register({ required: true, validate: val => !val.trim() ? 'This field cannot be blank' : undefined })}
                    errorMessage={getErrorMessage(errors.name, "Name", "This field cannot be blank")}
                    maxLength={200}
                    defaultValue={templatesDetails ? templatesDetails.name : ''}
                    placeholder="Enter name"
                    error={!!errors.name}
                />
                <Textarea
                    label="Description"
                    className="mbx3"
                    name="description"
                    refs={register()}
                    // errorMessage={getErrorMessage(errors.name, "Name", "This field cannot be blank")}
                    maxLength={500}
                    defaultValue={templatesDetails ? templatesDetails.desc : ''}
                    placeholder="Enter description"
                    error={!!errors.description}
                    errorMessage={getErrorMessage(errors.description, "Description", "Description must not exceed 500 characters")}
                />
            </div>
        </Modal>
    )
};