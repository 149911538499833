import React, { createContext, useContext, useState } from 'react'
import { 
    DESTINATION,
    getFtaDetails,
    getHsCodes,
    LOCODE_PAGE_SIZE,
    ORIGIN,
 } from 'actions';
import { countryList } from 'utils';
import _ from 'lodash';
import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';

export const FtaAdvisorContext = createContext();

export const FtaAdvisorContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [originCountries, setOriginCountries] = useState([]);
    const [destinationCountries, setDestinationCountries] = useState([]);
    const [hsCodes, setHsCodes] = useState([]);
    const [ftaDetails, setFtaDetails] = useState([]);
    const [documentFta, setDocumentFta] = useState([]);
    const { setLoading } = useContext(HomeContext);

    const doGetCountries = (params, callback, type) => {
        const limit = params && params.limit ? params.limit : LOCODE_PAGE_SIZE;
        let value =  params && params.value;
        let result = [];
        if(value){
            value = value.toUpperCase();
            result = _.filter(countryList, function(country) { 
                return country.name.toUpperCase().indexOf(value) > -1 || country.locode.toUpperCase().indexOf(value) > -1
            }).slice(0,limit);
        }else{
            result = countryList.slice(0,limit);
        }

        switch (type) {
            case ORIGIN:
                if (callback) callback();
                setOriginCountries(result);
                break;
            case DESTINATION:
                if (callback) callback();
                setDestinationCountries(result);
                break;
            default:
                setOriginCountries(result);
                setDestinationCountries(result);
                break;
        }
    }

    const doGetHsCodes = async (shipmentId, params, callback, errorCallback) => {
        if(shipmentId){
            try {
                const response = await getHsCodes(shipmentId, params, loggedInUser);
                const { data, status } = response;

                if(status === 200) {
                    setHsCodes(data || []);

                    if(callback) callback();
                    return hsCodes;
                }
            } catch (error) {
                setHsCodes(hsCodes);
                if (errorCallback) errorCallback();
            }
            return hsCodes;
        }
    }

    const doGetFtaAdvisorResult = async (shipmentId, params, payload, callback, errorCallback) => {
        if(shipmentId){
            setLoading(true);
            try {
                const response = await getFtaDetails(shipmentId, params, payload, loggedInUser);
                const { data, status } = response;

                if(status === 200) {
                    setFtaDetails(data);
                    if(callback) callback();
                }
            } catch (error) {
                setFtaDetails([]);
                if (errorCallback) errorCallback();
            }
            setLoading(false);
        }
    }
    
    return (
        <FtaAdvisorContext.Provider
            value={{
                destinationCountries,
                originCountries,
                hsCodes,
                ftaDetails,
                documentFta, 
                setDocumentFta,
                setDestinationCountries,
                setOriginCountries,
                setFtaDetails,
                doGetCountries,
                doGetHsCodes,
                doGetFtaAdvisorResult
            }}
        >
            { children}
        </FtaAdvisorContext.Provider>
    )
}
