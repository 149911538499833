import React, { useContext } from 'react';
import { HomeContext, ModalContext } from 'contexts';
import {
    AddOrganization,
    AddTeam,
    AddMember,
    EditSection,
    AddTask,
    EditTask,
    AssignModal,
    MemberSettings,
    AllCollaborators,
    IssueModal,
    AssignMembersModal,
    DocumentModal,
    LinkTaskModal,
    Spinner,
    Notification,
    ConfirmStatusIssue,
    ConfirmStatusShipment,
    ViewExtractedInfo,
    PreviewTemplates,
    ConfirmRemoveCollaborator,
    AddBRCollaborators,
    AddNewSection,
    DeleteTemplate,
    EditExtractedInfo,
    ConfirmSaveAsTemplate,
    DeleteTask,
    EditTemplate,
    JoinOrganization,
    ConfirmCancelRequest,
    ViewJoinRequest,
    ConfirmApproveReuqest,
    LinkIssueList,
    ViewAllDocuments,
    DeleteLinkedVessel,
    SelectLinkedVessel,
    CouponOrganization,
    CouponNotication,
    CollaboratorsBk,
} from 'components';

export const Modals = () => {
    const {
            addOrganization,
            addTeam,
            addMember,
            memberSettings,
            editSection,
            addSection,
            deleteTemplate,
            deleteTask,
            deleteLinkedVessel, 
            selectLinkedVessel,
            addTask,
            editTask,
            assignMembers,
            assignCollaborators,
            allCollaborators,
            addIssue,
            editIssue,
            isEditSection,
            editExtractedInfo,
            document,
            addLink,
            notification,
            confirmStatusIssue,
            confirmStatusShipment,
            viewExtractedInfo,
            previewTempalates,
            removeCollaborator,
            addBRCollaborators,
            confirmSaveTemplate,
            editTemplate,
            joinOrg,
            confirmCancelRequest,
            viewRequestSetting,
            confirmApprove,
            linkIssueList,
            viewAllDocuments,
            couponPopup,
            couponNotification,
            collabsBk,
            setCollabsBk,
            setCountNotification,
            setCouponPopup,
            setViewAllDocuments,
            setLinkIssueList,
            setConfirmApprove,
            setViewRequestSetting,
            setConfirmCancelRequest,
            setJoinOrg,
            setAddOrganization,
            setAddTeam,
            setAddMember,
            setMemberSettings,
            setEditSection,
            setAddSection,
            setDeleteTemplate,
            setDeleteTask,
            setDeleteLinkedVessel,
            setSelectLinkedVessel,
            setAddTask,
            setEditTask,
            setAssignMembers,
            setAssignCollaborators,
            setAllCollaborators,
            setAddIssue,
            setEditExtractedInfo,
            setDocument,
            setAddLink,
            setNotification,
            setConfirmStatusIssue,
            setConfirmStatusShipment,
            setViewExtractedInfo,
            setPreviewTempalates,
            setRemoveCollaborator,
            setAddBRCollaborators,
            setConfirmSaveTemplate,
            setEditTemplate,
        } = useContext(ModalContext);
        const {
            loading,
            notificationMessage,
            notificationDuration,
            notificationType,
            taskId,
            setIsTemplate,
            setTeamMember,
            setIsEditTask,
            setNotificationMessage
        } = useContext(HomeContext);
    
    return (
        <>
            <AddOrganization
                open={addOrganization}
                onCancel={() => setAddOrganization(false)}
            />
            <AddTeam
                open={addTeam}
                onCancel={() => setAddTeam(false)}
            />
            <AddMember
                open={addMember}
                onCancel={() => {
                    setAddMember(false);
                    setTeamMember(false);
                }}
            />
            <MemberSettings
                open={memberSettings}
                onCancel={() => setMemberSettings(false)}
            />
            <AddNewSection 
                open={addSection}
                onCancel={() => setAddSection(false)}/>
            <EditSection
                open={editSection}
                onCancel={() => setEditSection(false)}
                isEdit={isEditSection}
            />
            <DeleteTemplate
                open={deleteTemplate}
                onCancel={() => setDeleteTemplate(false)}
            />
            <DeleteLinkedVessel
                open={deleteLinkedVessel}
                onCancel={() => setDeleteLinkedVessel(false)}
            />
            <SelectLinkedVessel
                open={selectLinkedVessel}
                onCancel={() => setSelectLinkedVessel(false)}
            />
            <DeleteTask
                open={deleteTask}
                onCancel={() => setDeleteTask(false)}
            />
            <AddTask
                open={addTask}
                onCancel={() => {
                    setAddTask(false);
                    setIsTemplate(false);
                    setIsEditTask(false);
                }}
            />
            <EditTask
                open={editTask}
                onCancel={() => setEditTask(false)}
            />

            <AssignModal
                open={assignCollaborators}
                onCancel={() => setAssignCollaborators(false)}
                taskId={taskId}
            />
            <AssignMembersModal
                open={assignMembers}
                onCancel={() => setAssignMembers(false)}
            />
            <AllCollaborators
                open={allCollaborators}
                onCancel={() => setAllCollaborators(false)}
            />
            <IssueModal
                open={addIssue}
                onCancel={() => setAddIssue(false)}
                isEdit={editIssue}
            />
            <EditExtractedInfo 
                open={editExtractedInfo}
                onCancel={() => setEditExtractedInfo(false)}
            />
            <DocumentModal
                open={document}
                onCancel={() => setDocument(false)}
            />
            <LinkTaskModal 
                open={addLink}
                onCancel={() => setAddLink(false)}
            />
            <Notification
                open={notification}
                type={notificationType}
                onCancel={() => {
                    setNotification(false);
                    setNotificationMessage('');
                }}
                notificationMessage={notificationMessage}
                duration={notificationDuration}
            />
            <ConfirmStatusIssue
                open={confirmStatusIssue}
                onCancel={() => setConfirmStatusIssue(false)}
				// onSubmit={}
                title="Confirmation Change Status"
				message={`Are you sure you want to change status of this issue ?`}
            />
            <ConfirmStatusShipment
                open={confirmStatusShipment}
                onCancel={() => setConfirmStatusShipment(false)}
            />
            <PreviewTemplates
                 open={previewTempalates}
                 onCancel={() => setPreviewTempalates(false)}
            />
            <ViewExtractedInfo 
                open={viewExtractedInfo}
                onCancel={() => setViewExtractedInfo(false)}
            />
            <ConfirmRemoveCollaborator
                open={removeCollaborator}
                onCancel={() => setRemoveCollaborator(false)}
                title="Confirmation Remove Collaborator"
                message={`Are you sure you want to remove this collaborator ?`}
            />
            <AddBRCollaborators
                open={addBRCollaborators}
                onCancel={() => {
                    setAddBRCollaborators(false);
                }}
            />
            <ConfirmSaveAsTemplate 
                open={confirmSaveTemplate}
                onCancel={() => {
                    setConfirmSaveTemplate(false);
                }}
            />
            <EditTemplate 
                open={editTemplate}
                onCancel={() => {
                    setEditTemplate(false);
                }}
            />
            <JoinOrganization 
                open={joinOrg}
                onCancel={() =>{
                    setJoinOrg(false);
                }}
            />
            <ConfirmCancelRequest 
                open={confirmCancelRequest}
                onCancel={() => {
                    setConfirmCancelRequest(false);
                }}
            />
            <ViewJoinRequest
                open={viewRequestSetting}
                onCancel={() => {
                    setViewRequestSetting(false);
                }} 
            />
            <ConfirmApproveReuqest
                open={confirmApprove}
                onCancel={() => {
                    setConfirmApprove(false);
                }}
            />
            <LinkIssueList 
                open={linkIssueList}
                onCancel={() => {
                    setLinkIssueList(false);
                }}
            />
            <ViewAllDocuments
                open={viewAllDocuments}
                onCancel={() => {
                    setViewAllDocuments(false);
                }}
            /> 
            <CouponOrganization 
                open={couponPopup}
                onCancel={() => {
                    setCouponPopup(false)
                }}
            />
            <CouponNotication 
                open={couponNotification}
                onCancel={() => {
                    setCountNotification(false)
                }}
            />
            <CollaboratorsBk 
                open={collabsBk}
                onCancel={() => {
                    setCollabsBk(false)
                }}
            />

            {loading && (
                <Spinner type="transparent" />
            )}
        </>
    );
};