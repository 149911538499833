import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Collapse,
    Dropdown
} from 'components';
import { getErrorMessage } from 'utils';
import { TrackShipmentContext } from 'contexts';
import { DESTINATION, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';
import { EditShipmentInfoInput } from '../EditShipmentInfoInput';

export const EditAISTracking = ({
    register = () => {},
    errors = [],
    getValues = () => {},
    vessels = [],
    setVessels = () => {},
    trackings,
    watch = () => {},
    editShipmentInfo,
    setError= () => {}, 
    clearErrors= () =>{},
    setDepartureLocation=() =>{},
    setArrivalLocation=() =>{},
    departureLocation,
    arrivalLocation,
    isArchive = false,
    bookingRequest={}
}) => {
    const [originLoading, setOriginLoading] = useState(Array(vessels.length).fill(false));
    const [destinationLoading, setDestinationLoading] = useState(Array(vessels.length).fill(false));
    const { doGetLocodes, origins, destinations } = useContext(TrackShipmentContext);

    useEffect(() => {
        console.log(trackings)
    }, [trackings])

    const isVesselDirty = vessel => {
        const name = getValues(`vesselName${vessel}`);
        const imo = getValues(`vesselImo${vessel}`);
        const voyage = getValues(`vesselVoyage${vessel}`);
        const departure = getValues(`vesselDeparture${vessel}`);
        const arrival = getValues(`vesselArrival${vessel}`);
        
        return name || imo || voyage || departure || arrival;
    }

    const onSearchOriginLocode = (index, value) => {
        handleValidVessel(index);
        setOriginLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(Array(vessels.length).fill(false));
        }, () => {
            setOriginLoading(Array(vessels.length).fill(false));
        }, ORIGIN);
    }

    const onSearchDestLocode = (index, value) => {
        handleValidVessel(index);
        setDestinationLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(Array(vessels.length).fill(false));
        }, () => {
            setDestinationLoading(Array(vessels.length).fill(false));
        }, DESTINATION);
    }
    
    const getLocationItem = code => {
        if (code) {
            const { name, country, locode } = code;
            return {
                key: locode,
                value: name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : ''),
                item: locode,
            }
        }
        return null;
    }

    const handleValidVessel = (index) =>{
        const name = getValues(`vesselName${index}`);
        const imo = getValues(`vesselImo${index}`);
        const voyage = getValues(`vesselVoyage${index}`);
        (isVesselDirty(index) && name) || !isVesselDirty(index) ? clearErrors(`vesselName${index}`) : setError(`vesselName${index}`,{type: 'required'});
        (isVesselDirty(index) && imo) || !isVesselDirty(index) ? clearErrors(`vesselImo${index}`) : setError(`vesselImo${index}`,{type: 'required'});
        (isVesselDirty(index) && voyage) || !isVesselDirty(index) ? clearErrors(`vesselVoyage${index}`) : setError(`vesselVoyage${index}`,{type: 'required'});
    }
    
    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="AIS Tracking" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            {vessels.length > 0 && vessels.map(vessel => (
                                <div className="row" key={vessel}>
                                    <div className="col-12 col-sm-12">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`vesselName${vessel}`}
                                            label="Vessel name"
                                            placeholder="MSC DOMITILLE"
                                            refs={register()}
                                            viewOnly={isArchive}
                                            maxLength={50}
                                            error={!!errors[`vesselName${vessel}`]}
                                            errorMessage={getErrorMessage(errors[`vesselName${vessel}`], 'Vessel Name')}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].name : ''}
                                            originalvalue={trackings && trackings[vessel] ? trackings[vessel].name : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`vesselName${vessel}`,trackings && trackings[vessel] ? trackings[vessel].name : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`vesselImo${vessel}`}
                                            label="IMO"
                                            placeholder="BN9720201"
                                            refs={register()}
                                            viewOnly={isArchive}
                                            maxLength={50}
                                            error={!!errors[`vesselImo${vessel}`]}
                                            errorMessage={getErrorMessage(errors[`vesselImo${vessel}`], 'Vessel IMO')}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].imo : ''}
                                            originalvalue={trackings && trackings[vessel] ? `${trackings[vessel].imo}` : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`vesselImo${vessel}`,trackings && trackings[vessel] ? `${trackings[vessel].imo}` : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`vesselVoyage${vessel}`}
                                            label="Voyage"
                                            placeholder="0230"
                                            refs={register()}
                                            viewOnly={isArchive}
                                            maxLength={50}
                                            error={!!errors[`vesselVoyage${vessel}`]}
                                            errorMessage={getErrorMessage(errors[`vesselVoyage${vessel}`], 'Vessel Voyage')}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].voyage : ''}
                                            originalvalue={trackings && trackings[vessel] ? trackings[vessel].voyage : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`vesselVoyage${vessel}`,trackings && trackings[vessel] ? trackings[vessel].voyage : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <Dropdown
                                            className="select mtx2"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="input"
                                            name={`vesselDeparture${vessel}`}
                                            label="Departure"
                                            error={!!errors[`vesselDeparture${vessel}`]}
                                            comparable={true}
                                            errorMessage={getErrorMessage(errors[`vesselDeparture${vessel}`], "Departure", "Departure port and Arrival port can't be the same")}
                                            refs={register({ required: isVesselDirty(vessel), validate: value => !value || value !== getValues(`vesselArrival${vessel}`) })}
                                            disabled={isArchive}
                                            options={origins && origins.length > 0 ? origins.map(locode => {
                                                return {
                                                    key: locode.locode,
                                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                    item: locode
                                                }
                                            }) : []}
                                            placeholder="Search by city, country or port"
                                            dropdownPosition="center"
                                            onInputChange={value => onSearchOriginLocode(vessel, value)}
                                            searchLoading={originLoading[vessel]}
                                            defaultValue={trackings && trackings[vessel] ? getLocationItem(trackings[vessel].departure) : null}
                                            onChange={data => {
                                                let departure = {...departureLocation};
                                                departure[`vesselDeparture${vessel}`] = {
                                                    name: data.item.city,
                                                    country: data.item.country,
                                                    locode: data.item.locode
                                                };
                                                setDepartureLocation(departure);
                                                handleValidVessel(vessel);
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <Dropdown
                                            className="select mtx2"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="input"
                                            name={`vesselArrival${vessel}`}
                                            label="Arrival"
                                            error={!!errors[`vesselArrival${vessel}`]}
                                            comparable={true}
                                            errorMessage={getErrorMessage(errors[`vesselArrival${vessel}`], "Arrival", "Departure port and Arrival port can't be the same")}
                                            refs={register({ required: isVesselDirty(vessel), validate: value => !value || value !== getValues(`vesselDeparture${vessel}`) })}
                                            disabled={isArchive}
                                            options={destinations && destinations.length > 0 ? destinations.map(locode => {
                                                return {
                                                    key: locode.locode,
                                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                    item: locode
                                                }
                                            }) : []}
                                            placeholder="Search by city, country or port"
                                            dropdownPosition="center"
                                            onInputChange={value => onSearchDestLocode(vessel, value)}
                                            searchLoading={destinationLoading[vessel]}
                                            defaultValue={trackings && trackings[vessel] ? getLocationItem(trackings[vessel].arrival) : null}
                                            onChange={data => {
                                                let arrival = {...arrivalLocation};
                                                arrival[`vesselArrival${vessel}`] = {
                                                    name: data.item.city,
                                                    country: data.item.country,
                                                    locode: data.item.locode
                                                };
                                                setArrivalLocation(arrival);
                                                handleValidVessel(vessel);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Collapse>
            {!isArchive && <div className="h4 mbx4 f-medium add-vessel mtbx4 align-items-center" onClick={() => setVessels(old => [...old, old.length])}>
                <i className="icon-plus mrx1"/>
                <span>Add New Vessel</span>
            </div>}
        </div>
    )
};

EditAISTracking.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    vessels: PropTypes.array,
    setVessels: PropTypes.func,
    trackings: PropTypes.any,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
};
