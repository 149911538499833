import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse,
    EditShipmentInfoDropdown
} from 'components';
import { TrackShipmentContext } from 'contexts';
import { CARRIER_PAGE_SIZE } from 'actions';
import { getErrorMessage } from 'utils';
import { find } from 'lodash';
import { InputTags } from 'components/common';
import { isEmpty } from 'lodash';

export const EditBookingDetails = ({ 
    register= () => {},  
    bookingDetails, 
    watch= () => {},  
    editShipmentInfo,
    getValues = () => {},  
    errors = [],
    isArchive = false,
    bookingRequest = {}
}) => {
    const { carriers, doGetCarriers } = useContext(TrackShipmentContext);
    const [scacCode, setScacCode] = useState();
    const [searchLoading, setSearchLoading] = useState(false);
    const [carrierName, setCarrierName] = useState(bookingDetails && bookingDetails.carrierName ? bookingDetails.carrierName : '');
    
    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    }, [])

    useEffect(() => {
        if (bookingDetails && bookingDetails.scacCode) setScacCode(bookingDetails.scacCode);
        if (bookingDetails && bookingDetails.carrierName && !bookingDetails.scacCode){
            const carrier = find(carriers, {'carrierName' : bookingDetails.carrierName});
            setScacCode(carrier ? carrier.scacCode : 'N/A');
        }
    }, [bookingDetails])

    useEffect(() => {
       if(!isEmpty(bookingRequest)) setScacCode((bookingRequest ? bookingRequest?.linkedVesselSchedule?.carrier?.scac : ''))
    }, [bookingRequest])
    /*eslint-enable */

    const isBookingDirty = () => {
        const bookingConfirmation = getValues('bookingConfirmation');
        const masterBillOfLading = getValues('masterBillOfLading');
        const houseBill = getValues('houseBill');
        return bookingConfirmation || masterBillOfLading || houseBill;
    }

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        setScacCode();
        
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value.trim(),
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        }, true);
    }

    const handleChooseCarrierName = (carrier) => {
        if(setScacCode.key === null) {
            setScacCode("N/A");
            setCarrierName(carrier.value)
        } else {
            setScacCode(carrier.scacCode);
            setCarrierName(carrier.value)
        }
    }

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Booking Details" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoDropdown
                                        className="mtx2"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        mode="input"
                                        name="carrierName"
                                        label="Carrier name"
                                        disabled={isArchive}
                                        refs={register({ required: isBookingDirty() })}
                                        error={!!errors['carrierName']}
                                        errorMessage={getErrorMessage(errors['carrierName'], 'Carrier Name')}
                                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {  
                                            return {
                                                key: carrier.carrierName,
                                                value: carrier.carrierName,
                                                scacCode: carrier.scacCode
                                            }
                                        }) : []}
                                        placeholder="You can select any of the carrier..."
                                        dropdownPosition="center"
                                        onInputChange={onSearchCarrier}
                                        searchLoading={searchLoading}
                                        onChange={handleChooseCarrierName}
                                        defaultValue={bookingDetails ? bookingDetails.carrierName : (bookingRequest ? bookingRequest?.linkedVesselSchedule?.carrier?.name : '')}
                                        originalvalue={bookingDetails ? bookingDetails.carrierName : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={carrierName}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        label="SCAC Code"
                                        value={scacCode}
                                        viewOnly={true}
                                        originalvalue={bookingDetails ? bookingDetails.scacCode : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={scacCode}
                                    />
                                    <input
                                        name="scacCode"
                                        type="hidden"
                                        ref={register()}
                                        value={scacCode}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="bookingConfirmation"
                                        refs={register()}
                                        label="Booking confirmation no."
                                        placeholder="BN823592"
                                        viewOnly={isArchive}
                                        maxLength={50}
                                        defaultValue={bookingDetails && bookingDetails.confirmationNo ? bookingDetails.confirmationNo.toString() : (bookingRequest ? bookingRequest.referenceNumber : "")}
                                        originalvalue={bookingDetails && bookingDetails.confirmationNo ? bookingDetails.confirmationNo.toString() : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch("confirmationNo",bookingDetails && bookingDetails.confirmationNo ? bookingDetails.confirmationNo.toString() : '')}
                                   />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="masterBillOfLading"
                                        refs={register()}
                                        label="Master bill of lading"
                                        placeholder="BN823592"
                                        maxLength={50}
                                        viewOnly={isArchive}
                                        defaultValue={bookingDetails && bookingDetails.masterBoL ? bookingDetails.masterBoL.toString() : ''}
                                        originalvalue={bookingDetails && bookingDetails.masterBoL ? bookingDetails.masterBoL.toString() : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch("masterBillOfLading",bookingDetails && bookingDetails.masterBoL ? bookingDetails.masterBoL.toString() : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <InputTags
                                        className="mtx2"
                                        name="houseBill"
                                        refs={register()}
                                        label="House bill of lading"
                                        placeholder="BN823592, BN823592, BN823592"
                                        maxLength={50}
                                        viewOnly={isArchive}
                                        defaultValue={bookingDetails && bookingDetails.houseBoL ? bookingDetails.houseBoL : []}
                                        isTooltip={editShipmentInfo}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditBookingDetails.propTypes = {
    register: PropTypes.func,
    bookingDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
    getValues: PropTypes.func,
    errors: PropTypes.any,
    bookingRequest: PropTypes.object
};
