import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import moment from 'moment';
import {
    Datepicker,
    Input,
    Collaborators,
    Dropdown,
    Button,
    Pie,
    Statuses,
    Tooltip,
    Collapse,
    UploadDocuments
} from 'components';
import { AuthContext, CollaboratorsContext, DocumentsContext, HomeContext, ManagementContext, ModalContext, ShipmentInfoContext } from 'contexts';
import { statusOptions } from 'utils';
import { ALL, COLLABORATORS_PAGE_SIZE, DONE, MYTASK, SHIPMENT_STATUS, VDL } from 'actions';
import { CONSIGNEE, EXPORT_CUSTOMS, EXPORT_LOGISTICS, FREIGHT, GET_FILE, IMPORT_CUSTOMS, IMPORT_LOGISTICS, OTHER, SHIPMENT_ADMIN } from 'actions/constants';
import avatar from 'assets/images/avatar.png';
import { useParams } from 'react-router-dom';

export const ShipmentTaskItem = ({ initialTasks = [], partnerLimit, tasks = [], collaborators = [], currentTab }) => {
    const { userInfo } = useContext(AuthContext);
    const { 
        setAddTask, 
        setAssignCollaborators, 
        setEditSection, 
        setIsEditSection, 
        setLinkIssueList 
    } = useContext(ModalContext);
    const { 
        setIsEditTask, 
        isArchive, 
        setTaskId, 
        setSectionId, 
        setSectionName, 
        setEventTask, 
        setSelectTask, 
        setTaskName, 
        setTaskRoles, 
        setCurrentTabManagement,
        setIsTemplate,
    } = useContext(HomeContext);
    const { doGetCollaborators } = useContext(CollaboratorsContext);
    const {
        // setDocuments,
        doUploadTaskDashboard,
    } = useContext(DocumentsContext);
    const { doGetTaskPartners, doChangeStatusTask, doGetTaskManagements, doUpdateShipmentTask, setIsDone, setLinkIssues } = useContext(ManagementContext);
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const [limit] = useState(4);
    const { shipmentId } = useParams();
    const handleAssignTask = (taskId, roles, name, status) => {
        doGetTaskPartners(taskId, () => {
            setAssignCollaborators(true);
        });
        setTaskId(taskId);
        setTaskName(name);
        setTaskRoles(roles);
        
        if (status && status.toLowerCase() === DONE) {
            setIsDone(true);
        } else {
            setIsDone(false);
        }

        doGetCollaborators(shipmentId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE,
            role: roles.map(role=> role.role)
        });

    }
    const handleChangeStatus = (option, taskId) => {
        const payload = {
            status: option.value.toUpperCase()
        }
        if (currentTab === ALL) {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: ALL
                })
            });
        } else {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: MYTASK
                })
            });
        }
        
    }
    const handleAddEvent = (date, taskId, event, sectionId) => {
        
        let partnersUpdate = [];
        event.partners.forEach(partner => {
            partnersUpdate = [
                ...partnersUpdate,
                {
                    id: partner.collaboratorId,
                    type: partner.type
                }
            ];
        })

        let selectedDueDate = {}
        if (event.dueDate && event.dueDate.specificDate) {
            selectedDueDate = {
                specificDate: event.dueDate.specificDate,
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: (event.dueDate.eventBasedDate && event.dueDate.eventBasedDate.typeOfEvent !== null) ? event.dueDate.eventBasedDate.typeOfEvent : null,
                    dateOfEvent: (event.dueDate.eventBasedDate && event.dueDate.eventBasedDate.dateOfEvent !== null) ? event.dueDate.eventBasedDate.dateOfEvent : 0,
                    adjustDays: (event.dueDate.eventBasedDate && event.dueDate.eventBasedDate.adjustDays !== null) ? event.dueDate.eventBasedDate.adjustDays : null
                }
            }
        }
        // let currentDuedate = event.dueDate;
        // currentDuedate.specificDate = date.getTime();

        const payload = {
            sectionId: sectionId,
            taskName: event.name,
            dueDate: selectedDueDate,
            roles: event.roles.map(role => {
                return {
                    permission: role.permission,
                    role: role.role,
                }
            }),
            partners: partnersUpdate,
            type: event.type,
            action: date.getTime()
        }

        doUpdateShipmentTask(shipmentId, taskId, payload, () => {
            doChangeStatusTask(taskId, {
                status: DONE.toUpperCase()
            }, () => {
                if (currentTab === ALL) { 
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    })
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }
                
            });
        });

    }

    const handleUploadFile = (document,files) => {
        let payload = [];
        console.log(document)
        files.forEach(file => {
            payload = [
                ...payload,
                {
                    awsId: file.id,
                    fileName: file.name,
                    documentLink: file.url,
                    typeOfDocument: OTHER
                }
            ]
        });
        doUploadTaskDashboard(document.id, payload, () => {
            doChangeStatusTask(document.id, {
                status: DONE.toUpperCase()
            }, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: (currentTab === ALL) ? ALL : MYTASK
                })
            });
            // if (currentTab === ALL) { 
                // doGetTaskManagements(shipmentId, {
                //     page: 0,
                //     tab: (currentTab === ALL) ? ALL : MYTASK
                // })
            // } else {
                // doGetTaskManagements(shipmentId, {
                    // page: 0,
                    // tab: MYTASK
                // })
            // }
        })
    }

    const getStatuses = selectedTask => {
        const statuses = [
            {
                id: SHIPMENT_STATUS.INPROGRESS.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.PENDING.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.STUCK.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.DONE.toLowerCase(),
                num: 0
            }
        ];
        
        selectedTask.tasks.documents.forEach(d => {
            switch (d.status.taskStatus) {
                case SHIPMENT_STATUS.INPROGRESS:
                    statuses[0].num += 1;
                    break;
                case SHIPMENT_STATUS.PENDING:
                    statuses[1].num += 1;
                    break;
                case SHIPMENT_STATUS.STUCK:
                    statuses[2].num += 1;
                    break;
                case SHIPMENT_STATUS.DONE:
                    statuses[3].num += 1;
                    break;
                default:
            }
        })

        selectedTask.tasks.events.forEach(d => {
            switch (d.status.taskStatus) {
                case SHIPMENT_STATUS.INPROGRESS:
                    statuses[0].num += 1;
                    break;
                case SHIPMENT_STATUS.PENDING:
                    statuses[1].num += 1;
                    break;
                case SHIPMENT_STATUS.STUCK:
                    statuses[2].num += 1;
                    break;
                case SHIPMENT_STATUS.DONE:
                    statuses[3].num += 1;
                    break;
                default:
            }
        });
        return statuses;
    }

    return (tasks.length > 0 && userInfo && userInfo._id) ? tasks.map((task, taskIndex) => (
        <div key={taskIndex}>
            <Collapse
                name={`${task.name}-${taskIndex}`}
                className="tr__shipment-task-item mtx3"
                title={task.name}
                initialOpen={true}
                renderHeader={() => (
                    <>
                        <span>{task.name}</span>
                        <div className="toggle-btn d-flex align-items-center">
                            {
                                task.tasks.events.concat(task.tasks.documents).length > 0 && (
                                    <Statuses
                                        className="bar mlx2"
                                        statuses={getStatuses(task)}
                                        showLabel={true}
                                        unit="number"
                                    />
                                )
                            }
                            <i className="icon-chevron-down" />
                        </div>
                    </>
                )}
            >
                <div className="tr__shipment-task-item--body">
                    {task.tasks.events && task.tasks.events.length > 0 && (
                        <div className='tr__shipment-task-item--table'>
                            <div className='table--header'>
                                <h4 className="h4 f-medium">Events</h4>
                            </div>
                            {task.tasks.events.map((event, eventIndex) => (
                                <div className={`table--body d-flex table-body justify-content-between ${event.status.taskStatus.toLowerCase() === DONE ? 'done' : ''}`} key={`${taskIndex}-event-${eventIndex}`}>
                                    <div className="column name d-flex align-items-center justify-content-between">
                                        <h4 className="h4 f-medium">
                                            <i className="icon-check" />
                                            {/* <span>{event.name}</span> */}
                                            <span className="short-name" title={event.name}>{event.name}</span>
                                        </h4>
                                        {event.issuesLink.length > 0 && (
                                            <i className="tr__link-task-pin icon-chain" onClick={() => {
                                                setLinkIssueList(true);
                                                setLinkIssues(event.issuesLink);
                                            }
                                            } />
                                        )}
                                        
                                    </div>
                                    <div className="column partner">
                                        <div className="partners">
                                            {event.roles && event.roles.length > 0 && (
                                                <>
                                                    {event.roles && event.roles.length > 0 && (
                                                        <>
                                                            {event.roles.slice(0, limit).map((role, roleIndex) => (
                                                                <Tooltip
                                                                    content={role && role.role ? role.role.replace('_',' ').toLowerCase() : role}
                                                                    key={roleIndex}
                                                                >
                                                                    {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                                                    {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                                                    {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                                                    {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                                                    {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                                                    {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                                                    {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                                                    <i className={`icon-${role && role.role ? role.role.toLowerCase()  : ''}`} />
                                                                </Tooltip>
                                                            ))}

                                                            {limit < event.roles.length && (
                                                                <Tooltip
                                                                    renderContent={() => (
                                                                        <div className="tags d-flex flex-column">
                                                                            {event.roles.slice(limit, event.roles.length).map((partner, partnerIndex) => (
                                                                                <span key={partnerIndex}>{partner && partner.role ? partner.role.replace('_', ' ').toLowerCase() : ''}</span>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                >
                                                                    <span className="f-medium">+{event.roles.length - limit}</span>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="column assignee">
                                        <Collaborators
                                            members={event.partners}
                                            onAdd={() => handleAssignTask(event.id, event.roles, event.name)}
                                            onAvatarsClick={() => handleAssignTask(event.id, event.roles, event.name, event.status.taskStatus)}
                                            done={event.status.taskStatus.toLowerCase() === DONE ? true : false}
                                            skip={5}
                                        />
                                    </div>
                                    <div className="column status">
                                        <Dropdown
                                            className="select"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="select"
                                            placeholder="Choose status"
                                            // disabled={!event.partners.some(partner => partner.collaboratorId === userInfo._id)}
                                            dropdownPosition="center"
                                            options={statusOptions}
                                            value={statusOptions[Math.floor(Math.random() * statusOptions.length)]}
                                            renderLabel={selectedValue => (
                                                <div className="status-option">
                                                    <p className={`task-status text-capitalize ${event.status.taskStatus.toLowerCase()}`}>
                                                        {event.status.taskStatus.toLowerCase()}
                                                    </p>
                                                    <Tooltip
                                                        renderContent={() => (
                                                            <div className="tags d-flex flex-column">
                                                                <span>{event.status.userUpdateName}</span>
                                                                <small>{event.status.userRole ? event.status.userRole.replace('_', ' ').toLowerCase() : ''}</small>
                                                            </div>
                                                        )}
                                                    >
                                                        <img src={event.status.userUpdateAvatar === '' ? avatar : GET_FILE + event.status.userUpdateAvatar} alt={event.status.userUpdateName} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            render={(options, handleChange, selectedValue) => (
                                                <>
                                                    {(event.status.taskStatus.toLowerCase() !== DONE && !isArchive) ? (
                                                        <div className={`tr__status-dropdown  ${event.updatable === true && !isArchive ? '' : 'disabled'}`}>
                                                            {options.map((option, optionIndex) => (
                                                                <div
                                                                    className={`status-option ${event.status.taskStatus === option.key ? 'active' : ''}`}
                                                                    key={optionIndex}
                                                                    onClick={() => {
                                                                        handleChangeStatus(option, event.id)
                                                                        handleChange(option)
                                                                    }}
                                                                >
                                                                    <p className={`task-status text-capitalize ${option.key.toLowerCase()}`}>{option.value}</p>
                                                                    <i className="icon-check" />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : ''}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="column date d-flex align-items-center justify-content-between">
                                        {event.status.taskStatus.toLowerCase() !== DONE && (
                                            <>
                                                {event.dueDate.specificDate ? (
                                                    <>
                                                        {new Date().getTime() - event.dueDate.specificDate > 0 ? (
                                                            <Tooltip
                                                                content={`${Math.abs(Math.floor((new Date().getTime() - event.dueDate.specificDate) / 86400000))} days overdue`}
                                                            >
                                                                <i className="icon-warning" />
                                                            </Tooltip>
                                                        ) : (
                                                                <Tooltip
                                                                    content={`${Math.abs(Math.floor((new Date().getTime() - event.dueDate.specificDate) / 86400000))} days left until the deadline`}
                                                                >
                                                                    <Pie
                                                                        width={18}
                                                                        height={18}
                                                                        percentage={80}
                                                                    />
                                                                </Tooltip>
                                                            )}

                                                    </>
                                                ) : (event.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                                        <>
                                                            {event.dueDate.eventBasedDate && new Date().getTime() - ((event.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + event.dueDate.eventBasedDate.adjustDays) > 0 ? (
                                                                <Tooltip
                                                                    content={`${Math.abs(Math.floor((new Date().getTime() - ((event.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + event.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000))} days overdue`}
                                                                >
                                                                    <i className="icon-warning" />
                                                                </Tooltip>
                                                            ) : (
                                                                    <Tooltip
                                                                        content={`${Math.abs(Math.floor((new Date().getTime() - ((event.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + event.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000))} days left until the deadline`}
                                                                    >
                                                                        <Pie
                                                                            width={18}
                                                                            height={18}
                                                                            percentage={80}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                        </>
                                                    ) : (''))}


                                            </>
                                        )}
                                        {event.status.taskStatus.toLowerCase() === DONE && (
                                            <>
                                                {event.dueDate.specificDate ? (
                                                    <Tooltip
                                                        content={`${(event.action - event.dueDate.specificDate) < 0
                                                            ? 'Done on time'
                                                            : Math.abs(Math.floor((event.action - event.dueDate.specificDate) / 86400000)) + ' days completed after the deadline'}`}
                                                    >
                                                        <i className="icon-check"></i>
                                                    </Tooltip>
                                                ) : (
                                                        <Tooltip
                                                            content={`${(event.action - ((event.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + event.dueDate.eventBasedDate.adjustDays * 86400000)) < 0
                                                                ? 'Done on time'
                                                                : Math.abs(Math.floor((event.action - ((event.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + event.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + ' days completed after the deadline'}`}
                                                        >
                                                            <i className="icon-check"></i>
                                                        </Tooltip>
                                                    )}
                                            </>
                                        )}
                                        <Datepicker
                                            start={new Date()}
                                            render={(value, onClick) => (
                                                <div className="tr__booking-date">
                                                    <Input
                                                        iconPosition="right"
                                                        placeholder="Booking Date"
                                                        value={event.dueDate.specificDate
                                                            ? moment(event.dueDate.specificDate).format('DD MMM YYYY')
                                                            // : moment(event.dueDate.eventBasedDate.dateOfEvent + event.dueDate.eventBasedDate.adjustDays * 86400000).format('DD MMM YYYY')}
                                                            : (event.dueDate.eventBasedDate.typeOfEvent !== null
                                                                ? (event.dueDate.eventBasedDate.typeOfEvent === VDL ? moment(shipmentInfo.departureDate + (event.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY') : moment(shipmentInfo.arrivalDate + (event.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY'))
                                                                : ''
                                                            )}
                                                        disabled={true}
                                                    />
                                                    {event.dueDate.eventBasedDate && event.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                                        <p className="tr__badge">{`${event.dueDate.eventBasedDate.typeOfEvent}  ${event.dueDate.eventBasedDate.adjustDays > 0 ? '+' : ''} ${event.dueDate.eventBasedDate.adjustDays}`}</p>
                                                    ) : ''}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="column actions d-flex align-items-center justify-content-between">
                                        {isArchive ? (
                                            <p className="mlx2"></p>
                                        ) : (
                                                <>
                                                    <Datepicker
                                                        // disabled={!event.updatable || event.status.taskStatus.toLowerCase() === DONE ? true : false}
                                                        onDatesChange={date => handleAddEvent(date, event.id, event, task.id)}
                                                        placeholder="+ Add event"
                                                        value={event.status.taskStatus.toLowerCase() === DONE &&
                                                            (event.action !== null ? moment(event.action).format('DD MMM YYYY'): '')
                                                        }
                                                        minDate={null}
                                                    />
                                                    <p 
                                                        className={`tr__link info ${event.status.taskStatus.toLowerCase() === DONE 
                                                            // || !event.updatable ? 'disabled' : ''
                                                        }`}
                                                        onClick={() => {
                                                            setAddTask(true);
                                                            setEventTask(event);
                                                            setIsEditTask(true);
                                                            setSectionId(task.id);
                                                            setSelectTask(task);
                                                            setIsTemplate(false);
                                                            setCurrentTabManagement(currentTab)
                                                        }}
                                                        // disabled={event.partners.some(partner => partner.collaboratorId === userInfo._id)}
                                                    >
                                                        <i className="icon-pencil"  />
                                                    </p>
                                                </>
                                            )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {task.tasks.documents && task.tasks.documents.length > 0 && (
                        <div className='tr__shipment-task-item--table mtx3'>
                            <div className='table--header'>
                                <h4 className="h4 f-medium">Documents</h4>
                            </div>
                            {task.tasks.documents.map((doc, docIndex) => (
                                <div className={`table--body d-flex table-body justify-content-between ${doc.status.taskStatus.toLowerCase() === DONE ? 'done' : ''}`} key={docIndex}>
                                    <div className="column name d-flex justify-content-between align-items-center">
                                        <h4 className="h4 f-medium">
                                            <i className="icon-check" />
                                            <span className="short-name" title={doc.name}>{doc.name}</span>
                                        </h4>
                                        {doc.issuesLink.length > 0 && (
                                            <i className="tr__link-task-pin icon-chain" onClick={() => {
                                                setLinkIssueList(true);
                                                setLinkIssues(doc.issuesLink);
                                            }
                                            } />
                                        )}
                                    </div>
                                    <div className="column partner">
                                        <div className="partners">
                                            {doc.roles && doc.roles.length > 0 && (
                                                <>
                                                    {doc.roles.slice(0, limit).map((role, partnerIndex) => (
                                                        <Tooltip
                                                        content={role && role.role ? role.role.replace('_',' ').toLowerCase() : role}
                                                        key={partnerIndex}
                                                        >
                                                            {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                                            {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                                            {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                                            {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                                            {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                                            {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                                            {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                                            <i className={`icon-${role && role.role ? role.role.toLowerCase()  : ''}`} />
                                                        </Tooltip>
                                                    ))}

                                                    {limit < doc.roles.length && (
                                                        <Tooltip
                                                            renderContent={() => (
                                                                <div className="tags d-flex flex-column">
                                                                    {doc.roles.slice(limit, doc.roles.length).map((partner, partnerIndex) => (
                                                                        <span key={partnerIndex}>{partner && partner.role ? partner.role.replace('_', ' ').toLowerCase() : ''}</span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            <span className="f-medium">+{doc.roles.length - limit}</span>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="column assignee">
                                        <Collaborators
                                            members={doc.partners}
                                            skip={5}
                                            onAdd={() => handleAssignTask(doc.id, doc.roles, doc.name)}
                                            onAvatarsClick={() => handleAssignTask(doc.id, doc.roles, doc.name, doc.status.taskStatus)}
                                            done={doc.status.taskStatus.toLowerCase() === DONE ? true : false}
                                        />
                                    </div>
                                    <div className="column status">
                                        <Dropdown
                                            className="select"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="select"
                                            dropdownPosition="center"
                                            placeholder="Choose status"
                                            // disabled={!doc.partners.some(partner => partner.collaboratorId === userInfo._id)}
                                            options={statusOptions}
                                            defaultValue="asdasd"
                                            value={statusOptions[Math.floor(Math.random() * statusOptions.length)]}
                                            renderLabel={selectedValue => (
                                                <div className="status-option">
                                                    <p className={`task-status text-capitalize ${doc.status.taskStatus.toLowerCase()}`}>
                                                        {doc.status.taskStatus.toLowerCase()}
                                                    </p>
                                                    <Tooltip
                                                        renderContent={() => (
                                                            <div className="tags d-flex flex-column">
                                                                <span>{doc.status.userUpdateName}</span>
                                                                <small>{doc.status.userRole ? doc.status.userRole.replace('_',' ').toLowerCase() : ''}</small>
                                                            </div>
                                                        )}
                                                    >
                                                        <img src={doc.status.userUpdateAvatar === '' ? avatar : GET_FILE + doc.status.userUpdateAvatar} alt={doc.status.userUpdateName} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            render={(options, handleChange, selectedValue) => (
                                                 <>
                                                    {(doc.status.taskStatus.toLowerCase() !== DONE) && !isArchive ? (
                                                        <div className={`tr__status-dropdown ${doc.updatable ? '' : 'disabled'}`}>
                                                            {options.map((option, optionIndex) => (
                                                                <div
                                                                    className={`status-option ${doc.status.taskStatus === option.key ? 'active' : ''}`}
                                                                    key={optionIndex}
                                                                    onClick={() => {
                                                                        handleChangeStatus(option, doc.id)
                                                                        handleChange(option)
                                                                    }}
                                                                >
                                                                    <p className={`task-status text-capitalize ${option.key.toLowerCase()}`}>{option.value}</p>
                                                                    <i className="icon-check" />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : ''}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="column date">
                                        {doc.status.taskStatus.toLowerCase() !== DONE && (
                                            <>
                                                {doc.dueDate.specificDate ? (
                                                    <>
                                                        {new Date().getTime() - doc.dueDate.specificDate > 0 ? (
                                                            <Tooltip
                                                                content={`${Math.abs(Math.floor((new Date().getTime() - doc.dueDate.specificDate) / 86400000))} days overdue`}
                                                            >
                                                                <i className="icon-warning" />
                                                            </Tooltip>
                                                        ) : (
                                                                <Tooltip
                                                                    content={`${Math.abs(Math.floor((new Date().getTime() - doc.dueDate.specificDate) / 86400000))} days left until the deadline`}
                                                                >
                                                                    <Pie
                                                                        width={18}
                                                                        height={18}
                                                                        percentage={80}
                                                                    />
                                                                </Tooltip>
                                                            )}

                                                    </>
                                                ) : (doc.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                                        <>
                                                            {doc.dueDate.eventBasedDate && new Date().getTime() - ((doc.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + doc.dueDate.eventBasedDate.adjustDays * 86400000) > 0 ? (
                                                                <Tooltip
                                                                    content={`${Math.abs(Math.floor((new Date().getTime() - ((doc.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + doc.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000))} days overdue`}
                                                                >
                                                                    <i className="icon-warning" />
                                                                </Tooltip>
                                                            ) : (
                                                                    <Tooltip
                                                                        content={`${Math.abs(Math.floor((new Date().getTime() - ((doc.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + doc.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000))} days left until the deadline`}
                                                                    >
                                                                        <Pie
                                                                            width={18}
                                                                            height={18}
                                                                            percentage={80}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                        </>
                                                    ) : (''))
                                                }


                                            </>
                                        )}
                                        {doc.status.taskStatus.toLowerCase() === DONE && (
                                            <>
                                                {doc.dueDate.specificDate ? (
                                                    <Tooltip
                                                        content={`${(new Date().getTime() - doc.dueDate.specificDate) > 0 
                                                            ? 'Done on time' 
                                                            : Math.abs(Math.floor((new Date().getTime() - doc.dueDate.specificDate) / 86400000)) + ' days completed after the deadline'}`}
                                                    >
                                                        <i className="icon-check"></i>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                            content={`${(new Date().getTime() - (doc.dueDate.eventBasedDate.dateOfEvent + doc.dueDate.eventBasedDate.adjustDays * 86400000)) > 0 
                                                            ? 'Done on time' 
                                                                : Math.abs(Math.floor((new Date().getTime() - (doc.dueDate.eventBasedDate.dateOfEvent + doc.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + ' days completed after the deadline'}`}
                                                    >
                                                        <i className="icon-check"></i>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        <Datepicker
                                            // start={new Date()}
                                            render={(value, onClick) => (
                                                <div className="tr__booking-date">
                                                    <Input
                                                        iconPosition="right"
                                                        placeholder="Booking Date"
                                                        value={doc.dueDate.specificDate
                                                            ? moment(doc.dueDate.specificDate).format('DD MMM YYYY')
                                                            // : moment(doc.dueDate.eventBasedDate.dateOfEvent + doc.dueDate.eventBasedDate.adjustDays * 86400000).format('DD MM YYYY')}
                                                            : (doc.dueDate.eventBasedDate.typeOfEvent !== null 
                                                                ? (doc.dueDate.eventBasedDate.typeOfEvent === VDL ? moment(shipmentInfo.departureDate + (doc.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY') : moment(shipmentInfo.arrivalDate + (doc.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY'))
                                                                : ''
                                                            )}
                                                        disabled={true}
                                                    />
                                                    {doc.dueDate.eventBasedDate && doc.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                                        <p className="tr__badge">{`${doc.dueDate.eventBasedDate.typeOfEvent} ${doc.dueDate.eventBasedDate.adjustDays > 0 ? "+" : ''} ${doc.dueDate.eventBasedDate.adjustDays}`}</p>
                                                    ) : ''}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="column actions">
                                            <>
                                                <Dropdown
                                                    disabled={!doc.updatable || doc.status.taskStatus.toLowerCase() === DONE || isArchive ? true : false}
                                                    className="select upload-doc"
                                                    mode="select"
                                                    icon="icon-plus"
                                                    iconPosition="left"
                                                    dropdownPosition="right"
                                                    defaultValue={(doc.documents && doc.documents.length > 0) ? doc.documents.length + ` document${doc.documents.length > 1 ? 's' : ''}` : (isArchive ? '' : "Upload Documents")}
                                                    placeholder=""
                                                    render={(options, handleChange, selectedValue, handleClose) => (
                                                        <UploadDocuments
                                                        className="modal"
                                                        mode="list"
                                                        label="Upload documents"
                                                        onCancel={handleClose}
                                                        onFilesUpload={files => {handleUploadFile(doc,files)}}
                                                    />
                                                    )}
                                                />
                                                {!isArchive && <p className={`tr__link info ${doc.status.taskStatus.toLowerCase() === DONE || !doc.updatable ? 'disabled' : ''}`} onClick={() => {
                                                    setAddTask(true);
                                                    setIsEditTask(true);
                                                    setEventTask(doc);
                                                    setSelectTask(task);
                                                    setIsTemplate(false);
                                                    setCurrentTabManagement(currentTab)
                                                }}>
                                                    <i className="icon-pencil" />
                                                </p> }
                                            </>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {!isArchive && (
                    <div className="tr__shipment-task-item-btns d-flex align-items-center mtx2">
                        {
                            (
                                <>
                                    <Button
                                        className="outline icon mrx2"
                                        icon="icon-plus"
                                        type="primary"
                                        onClick={() => {
                                            setAddTask(true);
                                            setSectionId(task.id);
                                            setSectionName(task.name);
                                            setSelectTask(task);
                                            setCurrentTabManagement(currentTab);
                                            setIsTemplate(false);
                                        }}
                                        disabled={!collaborators.some(collaborator => (collaborator._id && collaborator._id === userInfo._id))}
                                    >
                                        Add New Task
                                    </Button>
                                    <Button
                                        className="outline icon"
                                        icon="icon-pencil"
                                        type="secondary"
                                        onClick={() => {
                                            setEditSection(true);
                                            setIsEditSection(true);
                                            setSectionId(task.id);
                                            setSectionName(task.name);
                                            setCurrentTabManagement(currentTab);
                                            setIsTemplate(false);
                                        }}
                                        disabled={!collaborators.some(collaborator => (collaborator._id && collaborator._id === userInfo._id))}
                                    >
                                        Edit Section
                                    </Button>
                                    </>
                            )
                        }
                    
                    </div>
                )}
            </Collapse>

        </div>
    )) : '';
};

ShipmentTaskItem.propTypes = {
    initialTasks: PropTypes.array,
    tasks: PropTypes.array,
    collaborators: PropTypes.array
};
