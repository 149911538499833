import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import {
    Modal,
} from 'components';

export const CancelTrackShipment = ({ open = false, onCancel, onSubmit }) => {
    return (
        <Modal
            open={open}
            className="tr__cancel-trackshipment"
            onCancel={onCancel}
            title="Cancel Confirmation"
            btnClasses="justify-content-between"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={onSubmit}
            cancelButton
            cancelButtonLabel="No"
            cancelButtonType="secondary"
            cancelButtonClassNames="outline"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center mtbx4">
                <h6 className="h6 f-medium">Are you sure you want to cancel this form?.</h6>
                <h6 className="h6 f-medium">Data you have entered may not be saved.</h6>
            </div>
        </Modal>
    )
};

CancelTrackShipment.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};