import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Button
} from 'components'
import {
    VesselScheduleContext
} from 'contexts';

export const ResultHeader = ({ className = '', searchParams }) => {
    const { totalItems, vesselSchedule, setClearFilters } = useContext(VesselScheduleContext);

    return searchParams && searchParams.origin && searchParams.destination ? (
        <div className={`tr__result-header row ${className}`}>
            <div className="col-12 col-sm-2">
                <h1 className="h1 f-medium mbx1">Search schedule</h1>
            </div>
        
            <div className="col-12 col-sm-8 middle d-flex align-items-center">
                <span className="h4">Search result for: </span> 
                <h4 className="h4 f-medium port">
                    <span>{searchParams.origin.city !== searchParams.origin.country && `${searchParams.origin.city}, `}{searchParams.origin.country}</span>
                    <i className="icon-arrow-left" />
                    <span>{searchParams.destination.city !== searchParams.destination.country && `${searchParams.destination.city}, `}{searchParams.destination.country}</span>
                </h4>
                {vesselSchedule.length < totalItems && (
                    <Button
                        className='mlx2'
                        icon='icon-times'
                        onClick={() => setClearFilters(true)}
                    >Clear filters</Button>
                )}
            </div>
            {vesselSchedule.length > 0 && (
                <div className="col-12 col-sm-2 d-flex align-items-center justify-content-end">
                    <span className="no-of-schedule f-medium">{vesselSchedule.length} Vessel Schedule{vesselSchedule.length > 1 ? 's' : ''} </span> 
                </div>
            )}
        </div>
    ) : ''
};

ResultHeader.propTypes = {
    className: PropTypes.string,
    searchParams: PropTypes.object
};
