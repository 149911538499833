/**
 * FIXME: Sometime FE drop user info section if stay at this screen so long (maybe cause by user section expire)
 */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.scss';
import {
    Button,
    Member,
    AssigneeList,
    MultiSelect,
    // Textarea,
    // Modal,
    // FileUploader,
    FilePreview,
    Comment,
} from 'components';
// import {
//     getErrorMessage,
// } from 'utils';
import avatar from 'assets/images/avatar.png';
import {
    CLOSED,
    COLLABORATORS_PAGE_SIZE,
    COMMENT_PAGE_SIZE,
    DONE,
    ISSUES_URL,
    OPEN,
    TEAM,
    // MAX_FILE_CMT_DOCS,
    // MAX_SIZE_CMT_DOC,
} from 'actions';
import {
    AuthContext,
    CollaboratorsContext,
    HomeContext,
    IssueContext,
    ModalContext
} from 'contexts';
import { GET_FILE, ISSUE_URL_ARCHIVE } from 'actions/constants';
import { useHistory, useParams } from 'react-router-dom';
// import { useForm } from 'react-hook-form';

export const IssueDetails = ({setStatus = () => {}}) => {
    const history = useHistory();
    const { shipmentId, issueId } = useParams();
    // const { handleSubmit, register, errors, formState } = useForm();
    const { isArchive } = useContext(HomeContext);
    const { userInfo } = useContext(AuthContext);
    const { setAddIssue, setEditIssue, setConfirmStatusIssue } = useContext(ModalContext);
    const {
        doGetIssue,
        comments,
        doGetComments,
        doCreateComment,
        doAddPartnerIssue,
        currentCommentPage,
        totalCommentPages,
        issue,
        setStatusIssue,
        doGetTasksIssue,
        doRemovePartnerIssue,
        setIssues
    } = useContext(IssueContext);
    const { collaborators, doGetCollaborators } = useContext(CollaboratorsContext);

    const [assignee, setAssignee] = useState([]);
    const [searchLoadingPartner, setSearchLoadingPartner] = useState(false);
    // const [isOpenCommendDocumentsUploader, setIsOpenCommentDocumentsUploader] = useState(false);
    const [files, setFiles] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        doGetIssue(shipmentId, issueId);
        doGetCollaborators(shipmentId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE
        });
        doGetComments(shipmentId, issueId, {
            page: 0,
            limit: COMMENT_PAGE_SIZE
        });

    },[])

    /*eslint-enable */

    const changeStatus = (status) => {
        setConfirmStatusIssue(true);
        setStatusIssue({
            id: issue._id,
            status: status,
        });
    }

    const onEditIssue = () => {
        setEditIssue(true);
        setAddIssue(true);
        doGetTasksIssue(shipmentId);
    }
    const sendComments = (data, callback) => {
        const {comments } = data;
        const payload = {
            content: comments.trim(),
            attachments: files,
        }
        doCreateComment(shipmentId, issue._id, payload, () => {
            callback();
        })

        setFiles([]);
    }
    const loadMoreComments = () => {
        doGetComments(shipmentId, issue._id, {
            page: currentCommentPage + 1,
            limit: COMMENT_PAGE_SIZE,
        });
    }
    const handleAddAssignee = (item) => {
        const payload = {
            partner : {
                id: item[0].id,
                type: item[0].type,
            }
        }
        doAddPartnerIssue(shipmentId, issue._id, payload);
        setAssignee([]);
    }
    const handleRemoveMember = member => {
        const payload = {
            id: member.id,
            type: member.type
        }
        doRemovePartnerIssue(shipmentId, issue._id, payload, () => { })
    }

    const handleSearchPartners = (value) => {
        const params = {
            query: value.trim(),
            limit: COLLABORATORS_PAGE_SIZE,
            page: 0,
        };
        setSearchLoadingPartner(true);
        doGetCollaborators(shipmentId, params, () => {
            setSearchLoadingPartner(false);
        });
    }

    const handleFilesUpload = async uploadFiles => {
        setFiles(oldFiles => [...oldFiles, ...uploadFiles]);
    }

    const handleCancelFilesUpload = () => {
        setFiles([]);
        // setIsOpenCommentDocumentsUploader(false);
    }

    // const handleSubmitFilesUpload = () => {
    //     setIsOpenCommentDocumentsUploader(false);
    // }

    const handleGoBack = () => {
        setIssues([]);
        isArchive ? history.push(ISSUE_URL_ARCHIVE.replace(':shipmentId',shipmentId)) : history.push(ISSUES_URL.replace(':shipmentId', shipmentId));
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    return issue ? (
        <div className="tr__shipment-issue">
            <div className="tr__shipment-issue--header d-flex align-items-center justify-content-between">
                <div className="main-title d-flex align-items-center justify-content-between">
                    <p className="tr__link tag mrx2" onClick={handleGoBack}>
                        <i className="icon-chevron-left" />
                        <span>Back</span>
                    </p>
                    <div className="issue-info">
                        <h2 className="h2 f-medium mbx1">{issue.title}</h2>
                        <p className="f-medium has-tooltip" title={issue.description}>{issue.description}</p>
                    </div>
                </div>
                {issue && issue.statusUpdatedBy !== null && issue.statusUpdatedDate !== null && (
                    <div className={`${issue.status === CLOSED ? 'closed-issue' : 'open-issue'} d-flex align-items-center`}>
                        <i className={`${issue.status === CLOSED ? 'icon-check' : 'icon-warning'} mrx1`} />
                        <div className="closed-issue--info d-flex align-items-center">
                            {/* {issue && issue.base && (
                                    <> */}
                                    <img className="mrx1"
                                        // src={issue.statusUpdatedBy.avatar || avatar}
                                        src={issue.statusUpdatedBy.avatar ? GET_FILE + issue.statusUpdatedBy.avatar : avatar}
                                        alt={issue.statusUpdatedBy.name} />
                                    <h6 className="h6 f-medium">{userInfo?._id === issue.statusUpdatedBy.id ? 'You': issue.statusUpdatedBy.name}</h6>
                                    <p className="label">{issue.status === CLOSED ? 'closed this issue on' : 're-opened this issue on'}</p>
                                    <p>{moment(issue.statusUpdatedDate).format('DD MMM YYYY | hh:mm A')}</p>
                                    {/* </>
                                )
                            } */}
                        </div>
                    </div>
                )}

                </div>

                {/*** Render comments ***/}
                <div className="tr__shipment-issue--content d-flex">
                    <div className="comments">
                        {comments && comments.length > 0 ? comments.map((comment, commentIndex) => (
                            <div className="comments-item d-flex" key={commentIndex}>
                                {comment && comment.base && (
                                    <>
                                        <div className="comments-item--avatar mrx2">
                                            <img src={comment.base.createdBy.avatar === "" ? avatar : comment.base.createdBy.avatar} alt={comment.base.createdBy.name} />
                                        </div>
                                        <div className="comments-item--content mbx3">
                                            <div className="user">
                                                <h6 className="h6 f-medium mbx1">{comment.base.createdBy.base?.delete ? "deleted_" + comment.base.createdBy.id : comment.base.createdBy.name}</h6>
                                                <p className="f-medium">{moment(comment.base.createdDate).format('DD MMM YYYY | hh:mm A')}</p>
                                            </div>
                                            {/* <div className="content mtx2" dangerouslySetInnerHTML={{ __html: comment.content }}></div> */}
                                            <div className="content mtx2">
                                                <pre>{comment.content}</pre>
                                            </div>
                                            {comment.gallery && comment.gallery.length > 0 && (
                                                <div className="gallery mtx3">
                                                    {comment.gallery.map((item, itemIndex) => (
                                                        <img src={item} key={itemIndex} alt={itemIndex} />
                                                    ))}
                                                </div>
                                            )}

                                            {comment.attachments && comment.attachments.length > 0 && (
                                                <div className="d-flex">
                                                    {comment.attachments.map(item => (
                                                        <FilePreview
                                                            file={item.url}
                                                            fileName={item.fileName}
                                                            fileExtension={item.fileName.split('.').pop()}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )) : (
                            <p className="mbx3">No comments</p>
                        )}
                        {/*** !!! Render comments ***/}

                        {totalCommentPages > currentCommentPage + 1 && (
                            <div className="load-more-link mtx2 mbx2 text-center">
                                <p className="tr__link" onClick={loadMoreComments}>Load more comments...</p>
                            </div>
                        )}

                    {/*** Show add comment block if issue not archived ***/}
                    {!isArchive && userInfo && (
                        <>
                            <Comment
                                user={userInfo}
                                onSendComment={sendComments}
                                isHaveAttachment={true}
                                attachments={files}
                                handleAddAttachments={handleFilesUpload}
                                handleCancelAddAttachments={handleCancelFilesUpload}
                                handleRemoveAttachment={handleFileRemove}
                            />
                            {/*<div className="comments-item form d-flex mtx3">*/}
                            {/*    <div className="comments-item--avatar mrx2">*/}
                            {/*        <img src={userInfo.avatar ? userInfo.avatar : avatar} alt={userInfo.name} />*/}
                            {/*    </div>*/}
                            {/*    <div className="comments-item--content d-flex align-items-end">*/}
                            {/*        <form onSubmit={handleSubmit(sendComments)}>*/}
                            {/*            <div className="d-flex align-items-end">*/}
                            {/*                <div className="mrx2">*/}
                            {/*                    <div className="form-btns">*/}
                            {/*                        <h6 className="h6 f-medium mbx1">You</h6>*/}
                            {/*                        <Textarea*/}
                            {/*                            refs={register({required: true, maxLength: 1000})}*/}
                            {/*                            name="comments"*/}
                            {/*                            placeholder="Add your comment..."*/}
                            {/*                            icon={{*/}
                            {/*                                className: 'icon-paperclip',*/}
                            {/*                                onClick: () => { setIsOpenCommentDocumentsUploader(true) },*/}
                            {/*                                cursor: 'pointer',*/}
                            {/*                            }}*/}
                            {/*                            iconPosition="right"*/}
                            {/*                            error={!!errors.comments}*/}
                            {/*                            errorMessage={getErrorMessage(errors.comments, 'Comments', 'Comments must be less 1000 characters')}*/}
                            {/*                        />*/}
                            {/*                        /!*** Upload multiple files popup ***!/*/}
                            {/*                        <Modal*/}
                            {/*                            className='tr__add-issue-comment__upload-documents'*/}
                            {/*                            title='Upload documents'*/}
                            {/*                            open={isOpenCommendDocumentsUploader}*/}
                            {/*                            onCancel={handleCancelFilesUpload}*/}
                            {/*                            submitButton={true}*/}
                            {/*                            submitButtonLabel='Upload'*/}
                            {/*                            onSubmitClick={handleSubmitFilesUpload}*/}
                            {/*                        >*/}
                            {/*                            <FileUploader*/}
                            {/*                                className='documents-uploader'*/}
                            {/*                                mode='file'*/}
                            {/*                                name='issueCommentDocuments'*/}
                            {/*                                handleDrop={handleFilesUpload}*/}
                            {/*                                uploadedFiles={files}*/}
                            {/*                                refs={register}*/}
                            {/*                                limitSizePerFile={MAX_SIZE_CMT_DOC}*/}
                            {/*                                limitNumberUploadFile={MAX_FILE_CMT_DOCS}*/}
                            {/*                                outputFormat='base64'*/}
                            {/*                                fileTypeApproved={['image', 'word', 'excel', 'pdf']}*/}
                            {/*                            />*/}
                            {/*                        </Modal>*/}
                            {/*                        /!*** !!! Upload multiple files popup ***!/*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <Button*/}
                            {/*                    isSubmitBtn={true}*/}
                            {/*                    disabled={!formState.dirtyFields.comments}*/}
                            {/*                >*/}
                            {/*                    Send*/}
                            {/*                </Button>*/}
                            {/*            </div>*/}
                            {/*            /!*** Preview uploaded files ***!/*/}
                            {/*            <div className="d-flex mtx2">*/}
                            {/*                {files && files.map(file => (*/}
                            {/*                    <FilePreview*/}
                            {/*                        fileName={file.name}*/}
                            {/*                        fileExtension={file.name.split('.').pop()}*/}
                            {/*                        file={file}*/}
                            {/*                    />*/}
                            {/*                ))}*/}
                            {/*            </div>*/}
                            {/*            */}
                            {/*            /!*** !!! Preview uploaded files ***!/*/}
                            {/*        </form>*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                        </>
                    )}
                    {/*** !!! Show add comment block if issue not archived ***/}

                    </div>
                    <div className="members">
                        <div className="tr__task--link__list">
                            {issue.linkedTasks && issue.linkedTasks.length > 0 && issue.linkedTasks.map((linkTask, linkTaskIndex) => (
                                    <div
                                    className={`tr__task--link d-flex justify-content-start align-items-center mbx1 ${linkTask.status.taskStatus.toLowerCase() === DONE ? 'done' : ''}`}
                                    key={linkTaskIndex}
                                    >
                                        <i className="icon-chain"></i>
                                        <span>Linked Task: </span>
                                        <span>{linkTask.taskName}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="members--created">
                            <h3 className="h3 f-medium mbx2">Issue created</h3>
                            {issue && issue.base && (
                                 <Member
                                    member={issue.base.createdBy}
                                    minimal
                                    createdDate={moment(issue.base.createdDate).format('DD MMM YYYY | hh:mm A')}
                                />
                            )}
                        </div>
                        <div className="members--assignees">
                            <AssigneeList
                                title="Assignees"
                                assignees={issue.partners.map(partner => {
                                    if (partner.base?.delete) {
                                        partner.name = "deleted_" + partner._id
                                    }
                                    return partner
                                })}
                                isClosed={issue && issue.status === CLOSED ? true : false}
                                shipmentId={shipmentId}
                                onRemove={handleRemoveMember}
                                characters={20}
                            />
                            {!isArchive && (
                                <div className="members--add-assignees mtx4">
                                    <MultiSelect
                                        className="mbx4"
                                        options={collaborators && collaborators.length > 0 ? collaborators.filter(mem => !issue.partners.some(m => m.id === mem._id) && !mem.b && !mem.base?.delete).map(item => {
                                            return {
                                                id: item._id,
                                                icon: item.avatar ? GET_FILE + item.avatar : (item.type === TEAM ? 'icon-users' : avatar),
                                                title: item.name,
                                                description: item.email,
                                                type: item.type
                                            }
                                        }) : []}
                                        value={assignee}
                                        addButton={true}
                                        addButtonLabel="Add"
                                        placeholder='Search by name'
                                        onChange={handleAddAssignee}
                                        onInputChange={handleSearchPartners}
                                        single={true}
                                        searchLoading={searchLoadingPartner}
                                        disabled={issue.status === CLOSED ? true : false}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {!isArchive && (
                    <div className="tr__shipment-issue--footer d-flex align-items-center justify-content-between">
                        {issue.status === OPEN ? (
                            <>
                                <Button
                                    className="icon outline"
                                    type="secondary"
                                    icon="icon-pencil"
                                    onClick={onEditIssue}
                                    // disabled={(!issue.partners.some(p => p.id === userInfo?._id || issue.permissionChangeStatus))}
                                >
                                    Edit
                                </Button>
                                <Button
                                    className="outline"
                                    type="secondary"
                                    onClick={() => changeStatus(CLOSED)}
                                    // disabled={
                                    //     userInfo && issue && issue.partners &&
                                    //     (!issue.partners.some(p => p.id === userInfo._id || issue.permissionChangeStatus))
                                    // }
                                >
                                    Close Issue
                                </Button>
                            </>
                        ) : (
                            <>
                                <div></div>
                                <Button
                                    onClick={() => changeStatus(OPEN)}
                                    // disabled={
                                    //     userInfo && issue && issue.partners &&
                                    //     (!issue.partners.some(p => p.id === userInfo._id || issue.permissionChangeStatus))
                                    // }
                                >
                                    {issue.status === CLOSED ? 'Re-Open' : 'Open Issue'}
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
    ) : [];
};

IssueDetails.propTypes = {
    issue: PropTypes.object,
    onCloseIssue: PropTypes.func,
    onEditIssue: PropTypes.func
};
