import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext } from 'contexts';

export const DeleteLinkedVessel = ({ open = false, onCancel }) => {
    const { setUnLinkVessel } = useContext(HomeContext);
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Unlink Confirmation"
            btnClasses="justify-content-center"
            submitButton={true}
            submitButtonLabel="Yes"
            onSubmitClick={() => {
                setUnLinkVessel(true);
                onCancel();
            }}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to unlink this vessel schedule?</h6>
            </div>
        </Modal>
    )
};