import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import plane from 'assets/images/plane.svg';
import cruiseShip from 'assets/images/cruise-ship.svg';
import truck from 'assets/images/truck.svg';
import { Button } from 'components';

export const ShipmentTypeModal = ({ chooseType })  => {
    const selectedType = () => {
        if(chooseType) {
            chooseType("checked");
        }
    }

    return (
        <div className="tr__shipment-type" >
            <div className="tr__shipment-type--header">
                <p>Track your Shipment</p>
            </div>
            <div className="tr__shipment-type--content">
                <h3 className="f-medium mbx4">You can choose one of the following shipment type</h3>
                <div className="d-flex align-items-center justify-content-between">
                    <Button onClick={selectedType}>
                        <img src={cruiseShip} alt="shipment-type-cruise-ship"/>
                        <p>Sea</p>
                    </Button>
                    <Button onClick={selectedType}>
                        <img src={plane} alt="shipment-type-plane"/>
                        <p>Air</p>
                    </Button>
                    <Button onClick={selectedType}>
                        <img src={truck} alt="shipment-type-truck"/>
                        <p>Land</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}
ShipmentTypeModal.propTypes = {
    chooseType: PropTypes.func
};
