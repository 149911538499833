import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Member
} from 'components';
import { Input } from 'components/common';

export const AssigneeList = ({ 
    className = '', 
    title, 
    assignees = [], 
    skip, 
    isClosed,
    shipmentId,
    hasOrgName = false,
    characters,
    searchValue,
    onRemove = () => {},
    setSearchValue = () => {}
}) => {
    const [assigneeSearched, setAssigneeSearched] = useState(assignees);
    const [searchAssignee, setSearchAssignee] = useState(false);
    

    useEffect(() => {
        setAssigneeSearched(assignees)
    }, [assignees])

    const handleSearchAssignees = (value) => {
        const filteredArray = assignees.filter(assignee => assignee.name.includes(value) || (assignee.email ? assignee.email.includes(value) : (
            assignee.orgName && assignee.orgName.includes(value))));
        setSearchAssignee(true);
        setSearchValue(value);
        setAssigneeSearched(filteredArray);
    }
    return (
        <div className={`tr__assignee-list ${className}`}>
            {title && (
                <h3 className="h3 f-medium mbx2">{title}</h3>
            )}
            <div className="search">
                <Input
                    icon="icon-search"
                    iconPosition="right"
                    placeholder="Search by name"
                    onChange={e => handleSearchAssignees(e.target.value)}
                    value={searchValue}
                />
            </div>
            <div className="list">
                {assigneeSearched && assigneeSearched.length > 0 ? assigneeSearched.map((member, memberIndex) => (
                    <Member
                        key={memberIndex}
                        member={member}
                        onRemove={onRemove}
                        roles={member.roles}
                        isClosed={isClosed}
                        hasOrgName={hasOrgName}
                        characters={characters}
                    />
                )) : (
                    <div className="d-flex justify-content-center mtx2">
                        {searchAssignee ? (
                            <p>No search result</p>
                        ) : ('')}
                    </div>
                )}
            </div>
        </div>
    )
};

AssigneeList.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    assignees: PropTypes.array,
    skip: PropTypes.number,
    isClosed: PropTypes.bool,
    shipmentId: PropTypes.string,
    onRemove: PropTypes.func,
    hasOrgName: PropTypes.bool,
    characters: PropTypes.number
};