import axios from 'axios';
import { authHeaders } from 'actions';
import {
    NOTIFICATION_GET_CONFIG,
    NOTIFICATIONS_GET,
    NOTIFICATIONS_MARK_AS_READ,
    NOTIFICATIONS_MARK_ALL_AS_READ,
    NOTIFICATIONS_DELETE,
    NOTIFICATIONS_DISABLE
} from './constants';

/** Get config of pusher
*/
export const getConfig = (token) => axios.get(NOTIFICATION_GET_CONFIG, authHeaders(token));

/** Get my notifications
 */
export const getNotifications = (token) => axios.get(NOTIFICATIONS_GET, authHeaders(token));

export const maskAsRead = (token, ids) => axios.put(NOTIFICATIONS_MARK_AS_READ, { ids }, authHeaders(token));

export const maskAllAsRead = (token) => axios.put(NOTIFICATIONS_MARK_ALL_AS_READ, authHeaders(token));

export const deleteNotification = (token, id) => axios.delete(NOTIFICATIONS_DELETE + '/' + id, authHeaders(token));

export const disableNotification = (id, token) => axios.put(NOTIFICATIONS_DISABLE.replace(':id', id), {}, authHeaders(token));
