import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    AuthContext,
    HomeContext,
    MemberContext,
    ModalContext,
    OrganizationContext,
    TeamContext
} from 'contexts';
import {
    Modal,
    MemberDetails,
    RoleList,
    DeleteConfirmation
} from 'components';
import {
    getRole,
    getRoleList
} from 'utils';
import { ADMIN, MEMBER, MEMBER_PAGE_SIZE, ORGANIZATION, ORGANIZATIONS_LIST_URL, OWNER, TEAM } from 'actions';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

export const MemberSettings = ({ open = false, onCancel }) => {
    const { userOrg, userInfo } = useContext(AuthContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { setMemberSettings, changeOwner, setChangeOwner } = useContext(ModalContext);
    const { organization, userOrgRole } = useContext(OrganizationContext);
    const { team, userTeamRole, doGetTeam } = useContext(TeamContext);
    const { member, doUpdateMember, doGetOrgMembers, doRemoveMembers } = useContext(MemberContext);

    const { handleSubmit, register, errors, formState, reset } = useForm();
    const [orgTeam, setOrgTeam] = useState(null);
    const [tempPayload, setTempPayload] = useState();
    const [removeMemberOrg, setRemoveMemberOrg] = useState(false);
    const [teamRemove, setTeamRemove] = useState(null);
    const [removeMemberTeam, setRemoveMemberTeam] = useState(false);
	const history = useHistory();

    /*eslint-disable */
    useEffect(() => {
        if (team && team.organization && member) {
            setOrgTeam(member.orgTeams ? member.orgTeams.find(org => org.orgId === team.organization._id) : null);
        }
    }, [team, member])
    /*eslint-enable */

    const getMemberOrgs = () => organization && member && member.orgTeams ? member.orgTeams.filter(org => org.orgId === organization._id) : [];
    const getMemberTeams = () => {
        if (orgTeam && team) return orgTeam.teams.filter(t => t.teamId === team._id);
        const orgs = getMemberOrgs();
        const teamOrgs = team && member && member.orgTeams ? member.orgTeams.filter(org => org.teams.some(t => t.teamId === team._id)) : []
        return orgs.length > 0 ? orgs[0].teams : (teamOrgs.length > 0 && team ? teamOrgs[0].teams : []);
    }

    const updateMember = payload => doUpdateMember(payload, () => {
        setMemberSettings(false);
        setNotificationMessage(`
            <p>Member settings updated successfully!</p>
        `)
    })

    const handleUpdateMember = data => {
        const memberTeams = getMemberTeams();
        const payload = {
            memId: member._id
        }
        
        if (memberTeams.length > 0) {
            payload.teamRoles = memberTeams.map(team => {
                return {
                    teamId: team.teamId,
                    roleName: data[`teamRole${team.teamId}`]
                }
            })
        }

        if (organization) {
            const { _id } = organization;

            payload.orgId = _id
            payload.orgRole = data[`orgRole${_id}`];
            payload.type = ORGANIZATION;
        }

        if (team) {
            const { _id } = team.organization;
            // const orgRole = orgTeam ? orgTeam.roleName : '';

            payload.orgId = _id
            // payload.orgRole = orgRole;
            payload.type = TEAM;
        }
        
        if (payload.orgRole && getRole(payload.orgRole) === OWNER && userInfo._id !== payload.memId && organization) {
            setTempPayload(payload);
            setChangeOwner(true);
        } else {
            updateMember(payload);
        }
    }

    const confirmUpdateMember = () => {
        if (!tempPayload) return;
        setChangeOwner(false);
        updateMember(tempPayload);
    }

    const getOrgMembers = () => {
        const params = {
            orgId: organization._id,
            page : 0,
            limit: MEMBER_PAGE_SIZE,
            isSearching: true
        };
        doGetOrgMembers(params);
    }

    const handleRemoveMemberOrg = () => {
        if(member && organization) {
            const { title } = organization;
            const { _id } = member;
            const payload = {
				memberIds: [_id],
				orgIds: [organization._id]
            };
           
            setRemoveMemberOrg(false);
            doRemoveMembers(payload, () => {
                onCancel();
                reset();
                getOrgMembers();
                if (userInfo && _id === userInfo._id) {
                    setNotificationMessage(`You has left ${title} organization!`);
                    history.push(ORGANIZATIONS_LIST_URL);
                } else {
                    setNotificationMessage(`Member has been removed from ${title} organization!`);
                }
            })
        }
    }

    const handleRemoveMemberTeam = () => {
        if(member && !isEmpty(teamRemove)) {
            const { id, title } = teamRemove;
            const { _id } = member;
            const payload = {
                memberIds: [_id],
				teamIds: [id]
            };
            doRemoveMembers(payload, () => {
                setRemoveMemberTeam(false);
                reset();
                onCancel();
                setNotificationMessage(`Member has been removed from ${title} team!`);
                if (team) {
                    doGetTeam(team._id);
                } else {
                    getOrgMembers();
                }
            }, () => {
                setRemoveMemberTeam(false);
            })
        }
    }

    return (
        <>
            <Modal
                open={open}
                className="tr__member-settings no-padding"
                onCancel={() => {
                    reset();
                    onCancel();
                }}
                title="Member settings"
                submitButton
                submitButtonLabel="Save Changes"
                isBackDropClickable={false}
                btnClasses="justify-content-center"
                renderForm={children => (
                    <form onSubmit={handleSubmit(handleUpdateMember)}>
                        {children}
                    </form>
                )}
                submitButtonDisabled={!formState.isDirty}
            >
                <MemberDetails
                    title="User details"
                    member={member}
                />
                {organization && !team && (
                    <RoleList
                        title="Organizations"
                        note="Change role in organizations"
                        name="orgRole"
                        items={getMemberOrgs().map(org => {
                            return {
                                id: org.orgId,
                                title: org.orgName,
                                role: getRole(org.roleName),
                                disabled: getRole(userOrgRole) === MEMBER
                                    || (userInfo && member && userOrg && member._id === userInfo._id && org.orgId === userOrg.orgId && getRole(org.roleName) === OWNER)
                                    || (getRole(org.roleName) === OWNER && getRole(userOrgRole) === ADMIN),
                                options: (getRole(userOrgRole) === OWNER || getRole(org.roleName) === OWNER) ? getRoleList(org.status === "INVITED" ? ADMIN : OWNER) : getRoleList(userOrgRole)
                            }
                        })}
                        register={register}
                        errors={errors}
                        isRemove={getRole(userOrgRole) === OWNER || getRole(userOrgRole) === ADMIN}
                        onRemove={item => setRemoveMemberOrg(true)}
                    />
                )}
                {getMemberTeams().length > 0 && (
                    <RoleList
                        title="Teams"
                        note="Change role in teams"
                        items={getMemberTeams().map(team => {
                            const currentUserInTeam = userOrg ? userOrg.teams.find(t => t.teamId === team.teamId) : null;
                            return {
                                id: team.teamId,
                                title: team.teamName,
                                role: getRole(team.roleName),
                                disabled: (!(currentUserInTeam && getRole(currentUserInTeam.roleName) !== MEMBER) && getRole(userOrgRole) === MEMBER) || (getRole(userTeamRole) === MEMBER && team && !organization),
                                options: getRole(userOrgRole) !== MEMBER ? getRoleList(ADMIN) : (currentUserInTeam ? getRoleList(currentUserInTeam.roleName) : getRoleList(team.roleName, false))
                            }
                        })}
                        register={register}
                        errors={errors}
                        isRemove={getRole(userOrgRole) === OWNER || getRole(userOrgRole) === ADMIN}
                        onRemove={item => {
                            setRemoveMemberTeam(true);
                            setTeamRemove(item);
                        }}
                    /> 
                )}
            </Modal>
            <DeleteConfirmation
                open={changeOwner}
                onCancel={() => setChangeOwner(false)}
                onSubmit={confirmUpdateMember}
                title="Change role"
                message={`You are making ${member ? member.name : ''} the owner of your organization. Your role will be changed to admin. Do you want to continue?`}
            />
            <DeleteConfirmation
                open={removeMemberOrg}
                onCancel={() => setRemoveMemberOrg(false)}
				onSubmit={handleRemoveMemberOrg}
				title="Remove confirmation"
				message={`Are you sure you want to remove member from organization?`}
            />
            <DeleteConfirmation
                open={removeMemberTeam}
                onCancel={() => setRemoveMemberTeam(false)}
				onSubmit={handleRemoveMemberTeam}
				title="Remove confirmation"
				message={`Are you sure you want to remove member from team?`}
            />
        </>
    )
};