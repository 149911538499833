import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { DocumentTable } from './components';
import { Button } from 'components/common';
import { DocumentsContext, HomeContext, ModalContext } from 'contexts';

export const VerifyInvoices = ({
    register = () => {},
    errors = [],
    getValues = () => {},
    removeFile = () => {},
    files,
    setValue = () => {},
    watch,
    ftaAdvisor,
    clearErrors = () => {},
}) => {
    const [invoices, setInvoices] = useState([0]);
    const { setViewExtractedInfo } = useContext(ModalContext);
    const { setFileId, setIsExtractedFta } = useContext(HomeContext);
    const { setDocumentPermission } = useContext(DocumentsContext);

    /*eslint-disable */
    useEffect(() => {
        if(files.length > 0){
            setInvoices([...Array(files.length).keys()]);
        }
    },[])
    /*eslint-enable */

    const removeInvoice = invoice => {
        setInvoices(old => [...old.filter(i => i !== invoice)]);
        if(files.length > 0) removeFile(files[invoice]);
    }

    return (
        <div className="tr__verify-invoices">
            {invoices && invoices.length > 0 ? invoices.map(invoice => {
                    return (
                        <React.Fragment key={invoice}>
                            <DocumentTable
                                className="mtx3"
                                register={register}
                                errors={errors}
                                invoice={invoice}
                                rows={invoice.items}
                                setInvoices={setInvoices}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                                ftaAdvisor={ftaAdvisor}
                                files={files}
                                clearErrors={clearErrors}
                            />
                            {files.length > 0 && 
                                <div className="d-flex align-items-center justify-content-between mtx2">
                                    <Button 
                                        className="secondary outline"
                                        onClick={() => {
                                            setViewExtractedInfo(true);
                                            setFileId(files[invoice].id);
                                            setIsExtractedFta(true);
                                            setDocumentPermission(true);
                                        }}
                                    >
                                        View extracted information
                                    </Button>
                                    {files.length > 1 && 
                                        <Button className="danger" onClick={() => removeInvoice(invoice)}>
                                            Delete Invoice
                                        </Button>
                                    }     
                                </div>
                            }

                        </React.Fragment>
                    )
                }) : null    
            }
        </div>
    )
};
VerifyInvoices.propTypes = {
    register: PropTypes.func,
    getValues: PropTypes.func,
    errors: PropTypes.object,
    ftaAdvisor: PropTypes.any,
    invoices: PropTypes.array,
    setInvoices: PropTypes.func,
};