import React, { useContext, useState, useEffect } from 'react';

import {
    Tab,
    TabPane,
    ShipmentList
} from 'components';
import { useHistory } from 'react-router-dom';
import { HomeContext, ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import {
    ACTIVE_SHIPMENT_PAGE_SIZE,
    ACTIVE_SHIPMENT_TABS,
} from 'actions';

export const ArchiveTabs = () => {
    const history = useHistory();
    const { isArchive, setShipment, seachParamActive } = useContext(HomeContext);
    const { setTrackers, setSelectedTracker, setRoutes } = useContext(TrackShipmentContext);
    const { 
        doGetActiveShipments, 
        activeShipments, 
        setNeedAttentionActiveShipments,
        setActiveShipments,
        totalPages,
        currentPage
    } = useContext(ActiveShipmentsContext);
    const [tabs,setTabs] = useState([ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.COMPLETED, ACTIVE_SHIPMENT_TABS.CANCELED]);
    const [currentTab, setCurrentTab] = useState([ACTIVE_SHIPMENT_TABS.COMPLETED, ACTIVE_SHIPMENT_TABS.CANCELED]);
        
    /*eslint-disable */
    useEffect(() => {
        setNeedAttentionActiveShipments([]);
        setActiveShipments([]);
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            status: currentTab,
        });
    }, [seachParamActive, currentTab])
    
    useEffect(() => {
        setTabs([ACTIVE_SHIPMENT_TABS.ALL, 'Completed' , 'Cancelled']);
    }, [isArchive])
    /*eslint-enable */

    const handleShowDetails = shipmentId => {
        if (shipmentId) {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
            history.push(`/${'archive'}/${shipmentId._id}`);
            setShipment(shipmentId);
        }
    }

    const handleTabChange = tab => {
        switch(tab) {
            case ACTIVE_SHIPMENT_TABS.ALL:
                setCurrentTab([ACTIVE_SHIPMENT_TABS.COMPLETED,ACTIVE_SHIPMENT_TABS.CANCELED]);
                break;
            case "Completed":
                setCurrentTab(ACTIVE_SHIPMENT_TABS.COMPLETED);
                break;
            case "Cancelled":
                setCurrentTab(ACTIVE_SHIPMENT_TABS.CANCELED);
                break;
            default:
        }
    }
    const handlePageChange = (page) => {
        doGetActiveShipments({
            page: page,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            // tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: currentTab,
            name: seachParamActive
        });
        console.log(page)
    }

    return (
        <div className="tr__shipment-tabs mtx2">
            <Tab initialTab={0} onTabClick={handleTabChange}>
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={tab.toLowerCase().replace('_', ' ')}
                        tabSlug={tab}
                    >
                        <>
                            <ShipmentList
                                className='mbx4'
                                title="Other Shipments"
                                shipments={activeShipments}
                                onShowDetails={handleShowDetails}
                                isArchive={isArchive}
                                seachParamActive={seachParamActive}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};