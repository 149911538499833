import React, { useContext, useEffect, useRef, useState } from 'react';

import './index.scss';
import {
    Table,
    TableFooter,
    Button
} from 'components';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { ActivitiesLogContext, ShipmentInfoContext } from 'contexts';

export const ShipmentLog = ({ isArchive = false}) => {
    const { shipmentId } = useParams();
    const csvInstance  = useRef();
    const {
        doGetLogsShipment, 
        logs,  
        totalPagesLog,  
        currentPageLog
    } = useContext(ActivitiesLogContext);
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const [csvData, setCsvData] = useState([]);

    const headers = [
        { label: "Name", key: "username" },
        { label: "Email", key: "email" },
        { label: "Activities", key: "activities" },
        { label: "Date & Time", key: "dateTime" }
    ];
    /*eslint-disable*/
    useEffect(() => {
        if (csvData && csvInstance && csvInstance?.current && csvInstance?.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
      }, [csvData]);
      /*eslint-enable */

    /*eslint-disable */
    useEffect(() => {
        doGetLogsShipment(shipmentId, {
            page: 0,
            limit: 6
        }, () => {
            
        }, true)
    },[])
    /*eslint-enable */
    const columns = [
        {
            dataIndex: 'username',
            render: name => (
                <p>{name}</p>
            )
        },
        {
            dataIndex: 'email',
            render: email => (
                <p>{email}</p>
            )
        },
		{
			dataIndex: 'activity,description,dueDate',
			render: (activity, description, dueDate) => (
                <p>{checkActionType(activity?.actionType) ? description + moment(dueDate).format('DD MMM YYYY') + '.' : description}</p>
            )
        },
        {
			dataIndex: 'dateTime',
			render: dateTime => (
                <p>{moment(dateTime).format('DD MMM YYYY | hh:mm A')}</p>
            )
        }
    ];

    const checkActionType = (action) => {
        switch (action) {
            case "UPLOAD_DOCUMENTS":
                return true;
            case "UPLOAD_DOCUMENT":
                return true;
            case "CHANGE_STATUS_TASK":
                return true;
            case "ADD_EVENT":
                return true;
            default:
                return false;
        }
    }

    const handleExportCsv = () => {
        doGetLogsShipment(shipmentId, {
            page: 0,
        }, (data) => {
            setCsvData(data.items.map(item => {
                return {
                    username: item.username,
                    email: item.email,
                    activities: (item?.dueDate &&  checkActionType(item?.activity?.actionType))  ? item.description + moment(item.dueDate).format('DD MMM YYYY | hh:mm A') : item.description,
                    dateTime: moment(item.dateTime).format('DD MMM YYYY | hh:mm A')
                }
                   
            }))
        }, false)
    }

    const handlePageChange = page => {
        console.log(page);
        doGetLogsShipment(shipmentId, {
            page: page,
            limit: 6
        }, () => {
            
        }, true)
	}

    return (
        <div
            className="tr__shipment-log"
        >
            <div className="tr__shipment-log--title d-flex align-items-center justify-content-between">
                <h2 className="h2 f-medium">Shipment Activities Log</h2>
                <div className="d-flex">
                    {!isArchive && (
                        <>
                            <Button className="mlx4" onClick={handleExportCsv}> Export to CSV</Button>
                            {csvData.length > 0 &&
                                <CSVLink
                                    data={csvData}
                                    headers={headers}
                                    filename={`${shipmentInfo?.shipmentName.replace(" ","")}_${moment().format('YYMMDD')}.csv`}
                                    ref={csvInstance}
                                >
                                </CSVLink>
                            }
                        </>
                    )
                        
                    } 
                </div>
            </div>
            <div className="tr__shipment-log--content" id="shipment-logs">
                <Table
                    className="shipment-logs"
                    dataSource={logs}
                    columns={columns}
                >
                   <div className="tr__shipment-log--table-header flex align-items-center justify-content-between table-header">
                        <div className="column name">
                            <p>Name</p>
                        </div>
                        <div className="column email">
                            <p>Email</p>
                        </div>
                        <div className="column activity">
                            <p>Activities</p>
                        </div>
                        <div className="column date">
                            <p>Date &amp; Time</p>
                        </div>
                    </div>
                </Table>
            </div>
            <div className="tr__shipment-log--footer d-flex align-items-center justify-content-end">
            {totalPagesLog > 1 && (
                <TableFooter
                    currentPage={currentPageLog}
                    totalPages={totalPagesLog}
                    setCurrentPage={handlePageChange}
                />
            )}
                {/* <Pagination
                    anchorElement="#shipment-logs"
                    current={currentPage}
                    size={10}
                    total={500}
                    onChange={page => setCurrentPage(page)}
                /> */}
            </div>
        </div>
    )
};