import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import moment from 'moment';

import './index.scss'
import { Datepicker, RangeSlider, CheckboxGroup } from 'components';
import { VesselScheduleContext } from 'contexts';
import { DEPARTURE, EMPTY, SERVICE_PROVIDERS_PAGE_SIZE } from 'actions';

export const FilterPanel = ({
    dates,
    type
}) => {
    const {
        clearFilters,
        allVesselSchedules,
        minDuration,
        maxDuration,
        setVesselSchedule,
        setClearFilters,
    } = useContext(VesselScheduleContext);

    const [selectedServices, setSelectedServices] = useState([]);
    const [durationFrom, setDurationFrom] = useState();
    const [durationTo, setDurationTo] = useState();
    const [serviceProviders, setServiceProviders] = useState([]);
    const [viewMoreProviders, setViewMoreProviders] = useState(false);
    /* const [selectedPort, setselectedPort] = useState([]);
    const [selectedDestination, setselectedDestination] = useState([]);
    const [originPorts, setOriginPorts] = useState([]);
    const [destinationPorts, setDestinationPorts] = useState([]); */
    const [cargoDate, setCargoDate] = useState();
    const [deliveryDate, setDeliveryDate] = useState();

    const [minCargoDate, setMinCargoDate] = useState();
    const [minDeliveryDate, setMinDeliveryDate] = useState();
    const [maxCargoDate, setMaxCargoDate] = useState();
    const [maxDeliveryDate, setMaxDeliveryDate] = useState();

    const [transhipped, setTranshipped] = useState([]);
    const [selectedTranshipped, setSelectedTranshipped] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (allVesselSchedules.length > 0) {
            const rawProviders = allVesselSchedules.map(v => {
                const { scac, name } = v.carrier;
                return {
                    id: scac,
                    name
                }
            });

            const sps = distinctValues(rawProviders);
            setServiceProviders(sps);
            setViewMoreProviders(sps.length > SERVICE_PROVIDERS_PAGE_SIZE);

            /* let rawOrigins = [];
            let rawDestinations = [];
            allVesselSchedules.forEach(v => {
                v.legs.forEach(leg => {
                    rawOrigins.push({
                        id: leg.start.port.code,
                        name: leg.start.port.code
                    });
                    rawDestinations.push({
                        id: leg.end.port.code,
                        name: leg.end.port.code
                    });
                })
            });

            if (!originPorts.length) setOriginPorts(distinctValues(rawOrigins));
            if (!destinationPorts.length) setDestinationPorts(distinctValues(rawDestinations)); */

            const rawTranshipped = allVesselSchedules.map(v => v.legs ? v.legs.length - 1 : 0).filter((value, index, self) => self.indexOf(value) === index);

            setTranshipped(rawTranshipped.map(t => {
                return {
                    id: t,
                    name: `${t} Transit${t > 1 ? 's' : ''}`
                }
            }))
        }
    }, [allVesselSchedules])

    useEffect(() => {
        setDurationFrom(minDuration);
    }, [minDuration])

    useEffect(() => {
        setDurationTo(maxDuration);
    }, [maxDuration])

    useEffect(() => {
        if (clearFilters) {
            setClearFilters(false);
            setSelectedServices([]);
            setSelectedTranshipped([]);
            setDurationFrom(minDuration);
            setDurationTo(maxDuration);
            setVesselSchedule(allVesselSchedules);
            setCargoDate(null);
            setDeliveryDate(null);
        }
    }, [clearFilters])
    
    useEffect(() => {
        if (dates && (!minCargoDate || !maxCargoDate || !minDeliveryDate || !maxDeliveryDate)) {
            const dateParts = dates.split('+');
            if (dateParts && dateParts.length === 2) {
                const s = new Date(dateParts[0]);
                const e = new Date(dateParts[1]);

                if (type === DEPARTURE) {
                    setMinCargoDate(s);
                    setMaxCargoDate(e);

                    if (allVesselSchedules && allVesselSchedules.length > 0) {
                        const max = allVesselSchedules.map(v => new Date(v.legs[v.legs.length - 1].end.date).getTime());

                        setMinDeliveryDate(s);
                        setMaxDeliveryDate(new Date(Math.max(...max)));
                    }
                } else {
                    setMinDeliveryDate(s);
                    setMaxDeliveryDate(e);

                    if (allVesselSchedules && allVesselSchedules.length > 0) {
                        const min = allVesselSchedules.map(v => new Date(v.legs[0].start.date).getTime());

                        setMinCargoDate(new Date(Math.min(...min)));
                        setMaxCargoDate(e);
                    }
                }
            }
        }
    }, [dates, type, allVesselSchedules])

    useEffect(() => {
        setClearFilters(true);
    }, [])
    /*eslint-enable */

    const distinctValues = arrs => arrs.filter((p, i, self) => self.findIndex(v => v.id === p.id) === i);

    const filterResults = (min, max, services, cargo, delivery, noOfTransits = []) => {
        if (allVesselSchedules.length > 0) {
            setVesselSchedule(allVesselSchedules.filter(v => {
                const validDuration = v.duration >= min && v.duration <= max;
                const validServiceProviders = services.length > 0 ? services.findIndex(s => s.id === v.carrier.scac) > -1 : true;
                const validCargoDate = cargo ? moment(v.legs[0].start.date).diff(moment(cargo)) >= 0 : true;
                const validDeliveryDate = delivery ? moment(v.legs[v.legs.length - 1].end.date).diff(moment(delivery)) <= 0 : true;
                const validTranshipped = noOfTransits.length > 0 && v.legs ? noOfTransits.some(t => t.id === v.legs.length - 1) : true;

                return validDuration && validServiceProviders && validCargoDate && validDeliveryDate && validTranshipped;
            }));
        }
    }

    const handleDurationChange = debounce(values => {
        const { min, max } = values;
        setDurationFrom(min);
        setDurationTo(max);

        filterResults(min, max, selectedServices, cargoDate, deliveryDate);
    }, 100);

    const handleSelectServiceProviders = debounce(services => {
        setSelectedServices(services);

        filterResults(durationFrom, durationTo, services, cargoDate, deliveryDate);
    }, 100);

    const handleCargoDateChange = date => {
        setCargoDate(date);
        filterResults(durationFrom, durationTo, selectedServices, date, deliveryDate);
    }

    const handleDeliveryDateChange = date => {
        setDeliveryDate(date);
        filterResults(durationFrom, durationTo, selectedServices, cargoDate, date);
    }

    const handleChangeTranshipped = items => {
        setSelectedTranshipped(items);
        filterResults(durationFrom, durationTo, selectedServices, cargoDate, deliveryDate, items);
    }

    return (
        <div className="tr__filter--panel">
            <div className="tr__filter--panel__header">
                <h4 className="h4 f-medium box-header">Filters</h4>
            </div>
            <div className="tr__filter--panel__container">
                <div className="tr__filter--panel__container__box mbx3">
                    <h5 className="f-medium mbx2">
                        Date range
                    </h5>
                    <div className="dates">
                        <div className="mbx3">
                            <Datepicker
                                label="Cargo pickup date"
                                name="pickupCargoDate"
                                placeholder="Pickup Cargo date"
                                onDatesChange={handleCargoDateChange}
                                defaultValue={cargoDate ? cargoDate : EMPTY}
                                minDate={minCargoDate}
                                maxDate={maxCargoDate}
                            />
                        </div>
                        <div>
                            <Datepicker
                                label="Target delivery date"
                                name="targetDeliverydate"
                                placeholder="Target delivery date"
                                onDatesChange={handleDeliveryDateChange}
                                defaultValue={deliveryDate ? deliveryDate : EMPTY}
                                minDate={minDeliveryDate}
                                maxDate={maxDeliveryDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="tr__filter--panel__container__box mbx3">
                    <h5 className="f-medium mbx1">
                        Service provider
                    </h5>
                    <CheckboxGroup
                        name="serviceProvider"
                        items={!viewMoreProviders ? serviceProviders : serviceProviders.slice(0, SERVICE_PROVIDERS_PAGE_SIZE)}
                        type="checkbox"
                        values={selectedServices}
                        onChange={handleSelectServiceProviders}
                    />
                    {viewMoreProviders && (
                        <p className={`tr__link mtx1 ${viewMoreProviders ? 'hidden' : ''}`} onClick={() => setViewMoreProviders(false)}>View more</p>
                    )}
                </div>
                <div className="tr__filter--panel__container__box mbx3">
                    <h5 className="f-medium mbx1">
                        Duration
                    </h5>
                    <RangeSlider
                        min={minDuration}
                        max={maxDuration}
                        from={durationFrom}
                        to={durationTo}
                        onFormat={value => `${value} days`}
                        onChange={handleDurationChange}
                    />
                </div>
                <div className="tr__filter--panel__container__box">
                    <h5 className="f-medium mbx1">
                        Number of Transhipped
                    </h5>
                    <CheckboxGroup
                        name="noOfTranshipped"
                        items={transhipped}
                        type="checkbox"
                        values={selectedTranshipped}
                        onChange={handleChangeTranshipped}
                    />
                </div>
                {/* <div className="tr__filter--panel__container__box mbx3">
                    <h5 className="f-medium mbx1">
                        Origin port
                    </h5>
                    <CheckboxGroup
                        name="originPort"
                        items={originPorts}
                        type="checkbox"
                        values={selectedPort}
                        onChange={ports => setselectedPort(ports)}
                    />
                </div>
                <div className="tr__filter--panel__container__box">
                    <h5 className="f-medium mbx1">
                        Destination port
                    </h5>
                    <CheckboxGroup
                        name="destinationPort"
                        items={destinationPorts}
                        type="checkbox"
                        values={selectedDestination}
                        onChange={destinations => setselectedDestination(destinations)}
                    />
                </div> */}
            </div>
        </div>
    )
};

FilterPanel.propTypes = {
    dates: PropTypes.string,
    type: PropTypes.string
};