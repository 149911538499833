import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import {
    ShipmentSteps,
    Input,
    Button
} from 'components';
import { ActiveShipmentsContext, DocumentsContext, HomeContext, ModalContext, ShipmentInfoContext, TemplateContext, TrackShipmentContext } from 'contexts';
import { roles } from 'utils';
import { useLocation } from 'react-router-dom';
import { ALL, DOCUMENT_PAGE_SIZE, LOCODE_PAGE_SIZE, MINE } from 'actions';

export const ShipmentDetailsCard = ({ className = '', shipment, onShowDetails, isDetails = false, isCardDetails = false }) => {
    const { isArchive } = useContext(HomeContext);
    const { doUpdateShipmentNote } = useContext(ShipmentInfoContext);
    const [isEditBC, setIsEditBC] = useState(false);
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const [shipmentNote, setShipmentNote] = useState();
    const { setAddLink } = useContext(ModalContext);
    const { doGetTemplates } = useContext(TemplateContext);
    const { doGetLocodes } = useContext(TrackShipmentContext);
    const { linkedShipment } = useContext(ActiveShipmentsContext);
    const { doGetDocuments } = useContext(DocumentsContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    console.log(shipmentId, 'shipmentId')

    /*eslint-disable */
    useEffect(() => {
        setShipmentNote(shipmentInfo && shipmentInfo.note ? shipmentInfo.note.content : '')
    }, [shipmentInfo])
    /*eslint-enable*/

    const toggleEditBC = () => {
        if (!isArchive) {
            setShipmentNote(shipmentNote);
            setIsEditBC(!isEditBC);
        }
    };

    const handleUpdateShipmentNote = () => {
        shipmentInfo.note = shipmentNote;
        doUpdateShipmentNote(shipment._id, shipmentNote, () => {
            setShipmentNote(shipmentNote);
            setIsEditBC(false);
        });
    }

    const getShipmentStep = () => {
        if (!shipmentInfo || !shipmentInfo.addresses) return null;
        const { addresses, _id } = shipmentInfo;
        const { prepol, pol, pod, postpod } = addresses;
        const newPrepol = {
            id: `${_id}-step1`,
            icon: 'icon-containers',
            title: prepol && prepol.location && pol && pol.location && prepol.location.locode !== pol.location.locode
                ? `${prepol.location.name}, ${prepol.location.country} (${prepol.location.locode})`
                : 'PREPOL',
            label: 'Arr: ',
            date: prepol && prepol.date
                ? moment.utc(prepol.date).format('YYYY-MM-DD HH:mm')
                : '',
            showHours: true,
            actual: prepol ? prepol.actual : (pol ? pol.actual : false),
        };
        const newPol = {
            id: `${_id}-step2`,
            icon: 'icon-anchor',
            title: `${pol && pol.location && pol.location.name}, ${pol && pol.location && pol.location.country} (${pol && pol.location && pol.location.locode})`,
            label: 'Dept: ',
            date: moment.utc(shipmentInfo.departureDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pol ? pol.actual : false,
        };
        const newPod = {
            id: `${_id}-step4`,
            icon: 'icon-anchor',
            title: `${pod && pod.location && pod.location.name}, ${pod && pod.location && pod.location.country} (${pod && pod.location && pod.location.locode})`,
            label: 'Est Arr: ',
            date: moment.utc(shipmentInfo.arrivalDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pod ? pod.actual : false,
        };
        const newPostPod = {
            id: `${_id}-step5`,
            icon: 'icon-containers',
            title: postpod && postpod.location && pod && pod.location && postpod.location.locode !== pod.location.locode
                ? `${postpod.location.name}, ${postpod.location.country} (${postpod.location.locode})`
                : 'POSTPOD',
            label: 'Est. Arr: ',
            date: postpod && postpod.date
                ? moment.utc(postpod.date).format('YYYY-MM-DD HH:mm')
                : '',
            showHours: true,
            actual: postpod ? postpod.actual : (pod ? pod.actual : false),
        };

        return [
            newPrepol,
            newPol,
            {
                id: `${shipmentId}-step3`,
                icon: 'icon-ship',
                actual: newPol.actual && newPod.actual
            },
            newPod,
            newPostPod,
        ];
    }

    return shipmentInfo ? (
        <div className={`tr__active-shipment-item ${className}`}>
            <div className="tr__active-shipment-item--body">
                <div className="tr__active-shipment-item--header box-header d-flex align-items-center">
                    {shipmentInfo.addresses && shipmentInfo.addresses.pol && shipmentInfo.addresses.pod && (
                        <h4 className="h4 f-medium">
                            {isArchive && !isDetails && shipmentInfo.status && (
                                <span className={`status-tag text-capitalize ${shipmentInfo.status}`}>{shipmentInfo.status}</span>
                            )}
                            {shipmentInfo.shipmentName}
                        </h4>
                    )}
                    <div className="note d-flex">
                        {isEditBC ? (
                            <div className="edit-form d-flex align-items-center">
                                <Input
                                    defaultValue={shipmentInfo && shipmentInfo.note && shipmentInfo.note.content}
                                    placeholder='Add note'
                                    value={shipmentNote}
                                    onChange={e => setShipmentNote(e.target.value)}
                                />
                                <Button
                                    className="mlx1 outline"
                                    onClick={handleUpdateShipmentNote}
                                >
                                    Save
                                </Button>
                            </div>
                        ) : (
                            <>
                                {shipmentInfo.note && (
                                    <span>{shipmentNote || ''}</span>
                                )}
                                <>
                                    <i className="icon-pencil" onClick={toggleEditBC} />
                                </>
                            </>
                        )}
                    </div>
                    <div className="shipment d-flex align-items-center">
                        <div className="info">
                            {!isArchive && (
                                <i className={shipmentInfo && shipmentInfo.shippingDetails && shipmentInfo.shippingDetails.shipmentType === 'FCL' ? 'icon-container-solid' : 'icon-boxes-solid'} />
                            )}
                            <span>{shipmentInfo && shipmentInfo.shippingDetails && shipmentInfo.shippingDetails.shipmentType}</span>

                            {(!!shipmentInfo.containersNumb || !!shipmentInfo.packetsNumb) && (
                                <span className='no-of-product'>
                                    {(shipmentInfo.containersNumb) ? `${shipmentInfo.containersNumb} Container` : `${shipmentInfo.packetsNumb} Packet`}{(shipmentInfo.containersNumb > 1 || shipmentInfo.packetsNumb > 1) ? 's' : ''}
                                </span>
                            )}
                        </div>
                        {shipmentInfo.openIssuesNumber > 0 && (
                            <span className="task-warning d-flex align-items-center mlx2">
                                <i className="icon-warning mrx1" />
                                <span>{shipmentInfo.openIssuesNumber} open</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="tr__active-shipment-item--steps d-flex" onClick={() => onShowDetails ? onShowDetails(shipment._id) : null}>
                    {getShipmentStep() && getShipmentStep().length > 0 && getShipmentStep().map((step, stepIndex) => (
                        <ShipmentSteps
                            key={stepIndex}
                            step={step}
                            active={step.actual}
                            align={stepIndex > 2 ? 'right' : 'left'}
                        />
                    ))}
                </div>
            </div>
            <div className="tr__active-shipment-item--footer">
                {isDetails ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="shipment-teams d-flex align-items-center">
                            <i className="icon-shipper active" />
                            <i className="icon-box active" />
                            <i className="icon-truck" />
                            <i className="icon-box-export" />
                            <i className="icon-box-import" />
                            <i className="icon-export" />
                            <i className="icon-import" />
                        </div>                       
                    </div>
                ) : (
                    <>
                        {isCardDetails && <div className="tr__active-shipment-item--footer--icon d-flex align-items-center justify-content-between">
                                <div className ="d-flex">
                                    {roles && roles.length > 0 && (
                                        <>
                                            {roles.map((role, roleIndex) => (
                                                <>
                                                    {(shipmentInfo && shipmentInfo.currentUserRoleType) && (
                                                        <div key={roleIndex} className={`bg-icon ${shipmentInfo.currentUserRoleType.includes(role.id) ? 'active' : ''}`}>
                                                            <i className={role.icon} />
                                                        </div>
                                                    )

                                                    }
                                                </>
                                            ))}
                                        </>
                                    )}
                                </div>
                            <div>
                            {linkedShipment ? <Button
                                className="tr__link-shipment-create outline mrx3"
                                onClick={() => setAddLink(true)}
                            >
                            Linke Shipment : {linkedShipment.shipmentName}
                            </Button> : 
                                !isArchive && <Button
                                className="tr__link-shipment-create outline mrx3"
                                onClick={() => {
                                    doGetLocodes({ limit: LOCODE_PAGE_SIZE });
                                    doGetTemplates({
                                        tab: MINE,
                                        limit: 9999,
                                        page: ALL,
                                    });
                                    doGetDocuments(shipmentId, {
                                        page: 0,
                                        limit: DOCUMENT_PAGE_SIZE
                                    }, () => {
                                    })
                                    // useEffect(() => {
                                    //     doGetOrganizations({ page: 0, limit: ORGANIZATION_PAGE_SIZE });
                                    
                                
                                    //     if (firstLocode && firstLocode.length > 0) {
                                    //         setOrigins(firstLocode);
                                    //         setDestinations(firstLocode);
                                    //     }
                                    // }, [])
                                    setAddLink(true);
                                }}
                            >
                                Create A Linked Shipment
                            </Button>
                            }
                            </div>
                        </div>
                        }
                    </>
                )}
            </div>
        </div>
    ) : '';
};

ShipmentDetailsCard.propTypes = {
    isDetails: PropTypes.bool,
    className: PropTypes.string,
    shipment: PropTypes.object,
    onShowDetails: PropTypes.func
};
