import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';

export const TabAddress = ( { addresses= {} } ) => {

    const getLocationValue = (data) => {
        const { name, country, locode} = {...data.location};
        return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
    }

    return <InfoBlock
        className="tr__details-box"
        title="Addresses"
    >
         <InfoRow >
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="PRE POL"
                        value={addresses.prepol ? getLocationValue(addresses.prepol) : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="POL"
                        value={addresses.pol ? getLocationValue(addresses.pol) : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="POD"
                        value={addresses.pod ? getLocationValue(addresses.pod) : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="POST POD"
                        value={addresses.postpod ? getLocationValue(addresses.postpod) : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Shipper Pick-up Address"
                        value={addresses.shipperPickupAdd ? addresses.shipperPickupAdd : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Consignee Delivery Address"
                        value={addresses.consigneeDeliveryAdd ? addresses.consigneeDeliveryAdd : ''}
                    />
                </div>
            </div>
        </InfoRow>
        
    </InfoBlock>
};

TabAddress.propTypes = {
    addresses: PropTypes.object
}