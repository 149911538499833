import React, { useContext, useEffect, useState } from 'react'

import './index.scss';
import { ActiveShipmentsContext, DocumentsContext, HomeContext, ModalContext, ShipmentInfoContext, TemplateContext, TrackShipmentContext } from 'contexts';
import {
    Modal,
    StepsBreadcrum,
    Button,
    AddPartnersForm,
    VerifyDetailsForm
} from 'components';
import avatar from 'assets/images/avatar.png';
import {
    breadcrumsMock,
    steps,
    trimArrayItems
} from 'utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DONE, MARK_NEW, MARK_TRACK_SHIPMENT, MARK_BOOKING_REQUEST, MEMBER, ACTIVE, SHIPMENT_DETAILS, EDIT_REQUEST_URL } from 'actions';
import { useForm } from 'react-hook-form';
import { AddShipmentDetailsForm } from 'components/pages/TrackShipment';

export const LinkTaskModal = ({ open = false }) => {
    const { setAddLink } = useContext(ModalContext);
    const { handleSubmit, register, errors, getValues, reset, watch, setError, clearErrors } = useForm();
    const [documentFiles, setDocumentFiles] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [transhipmentInfos, setTranshipmentInfos] = useState([0]);
    const [departureLocation, setDepartureLocation] = useState({});
    const [vessels, setVessels] = useState([0]);
    const [loading] = useState(false);
    const [containers, setContainers] = useState([0]);
    const [arrivalLocation, setArrivalLocation] = useState({});
    const { templates } = useContext(TemplateContext);
    const { doCreateLinkedShipment } = useContext(ActiveShipmentsContext);
    const { trackerParams, carriers } = useContext(TrackShipmentContext);
    const { documents } = useContext(DocumentsContext);
    const { type } = useParams();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    delete shipmentInfo?.addresses?.pod?._id;
    delete shipmentInfo?.addresses?.pol?._id;
    delete shipmentInfo?.addresses?.pod?.location?._id;
    delete shipmentInfo?.addresses?.pol.location?._id;
    delete shipmentInfo?.addresses?._id;

    const {
        markAsShipmentType,
        markAsShipmentTemplate,
        setMarkAsShipmentType,
        setBreadcrums,
        setNotificationMessage,
        trackerTemplate,
        setSeachParamActive,
    } = useContext(HomeContext);
    const [shipmentDetails, setShipmentDetails] = useState();
    const [step, setStep] = useState(0);

    const history = useHistory();

    const handleCancel = () => {
        reset();
        if (step === 1) {
            setStep(step - 1);
        } else {
            setAddLink(false);
            setStep(0);
        }
    }

    const onCancel = () => {
        setAddLink(false);
        reset();
        setStep(0);
    }

    const createLinkedShipment = async (data) => {
        const {
            destinationPort,
            organization,
            originPort,
            shipmentRole,
            shipperAddress,
            carrierName,
            scacCode,
            bookingConfirmation,
            consigneeDeliveryAdress,
            houseBill,
            masterBillOfLading,
            invoiceNumber,
            template,
            documentType,
            prePol,
            postPod,
            bookingDate,
            shipper,
            eta,
            etd,
            contactShipperAdd,
            consignee,
            consigneeAddress,
            notifyParty,
            notifyPartyAddress,
            serviceContractNumber,
            shipperContact,
            shipperFax,
            consigneeContact,
            consigneeFax,
            notifyContact,
            notifyFax,
            vgmSubmissionTimeline,
            siSubmission,
            cutOffDate,
            cutOffTime,
            measurement,
            containerYard,
            commodityDescription,
            grossWeightUnit,
            grossWeightValue,
            serviceType,
            hsCode,
            numberOfContainers,
        } = data;
        let params = null;
        if (step === 0) {
            params = {
                ...shipmentDetails,
                creatorRole: shipmentRole,
                status: ACTIVE,
                shippingDetails: {
                    shipmentMode: shipmentInfo && shipmentInfo.shippingDetails && shipmentInfo.shippingDetails.shipmentMode,
                    shipmentType: shipmentInfo && shipmentInfo.shippingDetails && shipmentInfo.shippingDetails.shipmentType
                },
                originPort,
                destinationPort,
                departureDate : shipmentInfo && shipmentInfo.departureDate,
                arrivalDate : shipmentInfo && shipmentInfo.arrivalDate,
                routes: shipmentInfo && shipmentInfo.addresses,
                documentType,
            };
            if (organization) params.orgId = organization;
            if (template && (!selectedValues || (selectedValues && !selectedValues.length) || (shipmentDetails && shipmentDetails.templateId !== template))) {
                params.templateId = template;
                const selectedTemplate = templates.find(t => t._id === template);
                if (selectedTemplate) setSelectedValues(selectedTemplate.roleInTemplates.map(role => {
                    return {
                        roleType: role.roleType,
                        partners: role.collaborators.map(c => {
                            return c.type === MEMBER.toUpperCase() ? {
                                id: c.collaboratorId,
                                description: c.email,
                                title: c.username,
                                icon: c.avatar || avatar,
                                type: c.type
                            } : {
                                id: c.collaboratorId,
                                description: c.organization ? c.organization.name : '',
                                title: c.name,
                                icon: 'icon-users',
                                type: c.type
                            }
                        })
                    }
                }))
            }
            if (markAsShipmentTemplate && markAsShipmentType === MARK_BOOKING_REQUEST) params.bookingReqId = markAsShipmentTemplate._id;

            // Upload documents to S3
            if (documentFiles && documentFiles.length) {
                params.documentFiles = await Promise.all(documentFiles.map(async doc => {
                    // const { id, name } = await doUploadFile({
                    //     type: FILE_TYPE_SHIPMENT_DETAIL_DOCS,
                    //     subType: FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
                    //     ocrRequired: true,
                    //     fileName: doc.name.split('.').slice(0, -1).join('.'),
                    //     fileExtension: doc.name.split('.').pop(),
                    // }, doc.data, loggedInUser);

                    return {
                        fileName: doc.name,
                        typeOfDocument: documentType,
                        awsId: doc.id,
                    };
                }));
            }
            // !!! Upload documents to S3

            setShipmentDetails(params);
            setStep(oldStep => oldStep + 1);
            reset();
        } else if (step === 1) {
            if (!shipmentDetails) return;
            params = {
                ...shipmentDetails
            };
            if (selectedValues.length > 0) params.roleInShipments = selectedValues;
            setShipmentDetails(params);
            setStep(oldStep => oldStep + 1);
            reset();
        } else if ( step === 2) {
            if (!shipmentDetails) return;
            const confirmationNo = bookingConfirmation?.trim() ? trimArrayItems(bookingConfirmation.split(',')) : null;
            const masterBoL = masterBillOfLading?.trim() ? trimArrayItems(masterBillOfLading.split(',')) : null;
            const houseBoL = houseBill?.trim() ? trimArrayItems(houseBill.split(',')) : null;
            const invoices = invoiceNumber?.trim() ? trimArrayItems(invoiceNumber.split(',')) : null;
            const selectedVessels = vessels.map(v => {
                const name = data[`vesselName${v}`];
                const imo = data[`vesselImo${v}`];
                const voyage = data[`vesselVoyage${v}`];
                const departure = departureLocation[`vesselDeparture${v}`];
                const arrival = arrivalLocation[`vesselArrival${v}`];
                let newParams = {};

                if (name) newParams.name = name;
                if (imo) newParams.imo = imo;
                if (voyage) newParams.voyage = voyage;
                if (departure) newParams.departure = departure;
                if (arrival) newParams.arrival = arrival;
                return Object.keys(newParams).length === 5 ? newParams : null;
            }).filter(v => v);

            params = {
                ...shipmentDetails,
            };

            if (selectedVessels && selectedVessels.length > 0) params.vessels = selectedVessels;

            if (shipperAddress) params.routes = {
                ...params.routes,
                shipperPickupAdd: shipperAddress
            }

            if (consigneeDeliveryAdress) params.routes = {
                ...params.routes,
                consigneeDeliveryAdd: consigneeDeliveryAdress
            }
            
            params.bookingDetail = {};
            if (carrierName && scacCode) {
                params.bookingDetail = {
                    carrierName,
                    scacCode
                };
            }

            if (confirmationNo) params.bookingDetail = {
                ...params.bookingDetail,
                confirmationNo
            };

            if (masterBoL && masterBoL.length > 0) params.bookingDetail = {
                ...params.bookingDetail,
                masterBoL
            };

            if (houseBoL && houseBoL.length > 0) params.bookingDetail = {
                ...params.bookingDetail,
                houseBoL
            };

            if (masterBoL && masterBoL.length > 0) params.bookingDetail = {
                ...params.bookingDetail,
                masterBoL
            };


            if (invoices && invoices.length > 0) params.invoices = invoices;

            let newParams = {
                ...params
            };
            if (params && params.roleInShipments && params.roleInShipments.length > 0) {
                newParams.roleInShipments = params.roleInShipments.map(r => {
                    return {
                        ...r,
                        partners: r.partners.map(rp => {
                            return rp.id ? {
                                id: rp.id,
                                type: rp.type
                            } : {
                                email: rp.email,
                                type: rp.type
                            }
                        })
                    }
                });
            }

            if (prePol && newParams.routes.prepol) {
                newParams.routes.prepol = {
                    actual: false,
                    ...newParams.routes.prepol
                }
            }

            if (postPod && newParams.routes.postpod) {
                newParams.routes.postpod = {
                    actual: false,
                    ...newParams.routes.postpod
                }
            }

            if (bookingDate) newParams.shippingDetails = {
                ...newParams.shippingDetails,
                bookingDate: new Date(bookingDate).getTime()
            };

            if (shipper) newParams.shippingDetails = {
                ...newParams.shippingDetails,
                shipper
            };

            if (eta) newParams.shippingDetails = {
                ...newParams.shippingDetails,
                eta
            };

            if (etd) newParams.shippingDetails = {
                ...newParams.shippingDetails,
                etd
            };

            if (contactShipperAdd) newParams.contactDetail = {
                ...newParams.contactDetail,
                shipperAddress: contactShipperAdd
            };

            if (consignee) newParams.contactDetail = {
                ...newParams.contactDetail,
                consignee
            };

            if (consigneeAddress) newParams.contactDetail = {
                ...newParams.contactDetail,
                consigneeAddress
            };

            if (notifyParty) newParams.contactDetail = {
                ...newParams.contactDetail,
                notifyParty
            };

            if (notifyPartyAddress) newParams.contactDetail = {
                ...newParams.contactDetail,
                notifyPartyAddress
            };

            if (serviceContractNumber) newParams.contactDetail = {
                ...newParams.contactDetail,
                serviceContractNumber
            };

            if (shipperContact) newParams.contactDetail = {
                ...newParams.contactDetail,
                shipperContact
            };

            if (shipperFax) newParams.contactDetail = {
                ...newParams.contactDetail,
                shipperFax
            };

            if (consigneeContact) newParams.contactDetail = {
                ...newParams.contactDetail,
                consigneeContact
            };

            if (consigneeFax) newParams.contactDetail = {
                ...newParams.contactDetail,
                consigneeFax
            };

            if (notifyContact) newParams.contactDetail = {
                ...newParams.contactDetail,
                notifyContact
            };

            if (notifyFax) newParams.contactDetail = {
                ...newParams.contactDetail,
                notifyFax
            };

            if (siSubmission) newParams.cutOff = {
                ...newParams.cutOff,
                siSubmission
            };

            if (vgmSubmissionTimeline) newParams.cutOff = {
                ...newParams.cutOff,
                vgmSubmissionTimeline
            };

            if (cutOffDate) newParams.cutOff = {
                ...newParams.cutOff,
                date: cutOffDate,
            };

            if (cutOffTime) newParams.cutOff = {
                ...newParams.cutOff,
                time: cutOffTime
            };

            const transhipmentInfo = transhipmentInfos.map(v => {
                const tsPort = data[`tsPort${v}`];
                const tsNextPort = data[`tsNextPort${v}`];
                const tsVessel = data[`tsVessel${v}`];
                const tsVesselIMO = data[`tsVesselIMO${v}`];
                const tsETD = data[`tsETD${v}`];
                const tsETA = data[`tsETA${v}`];
                const newParams = {};

                if (tsPort) newParams.tsPort = tsPort;
                if (tsNextPort) newParams.tsNextPort = tsNextPort;
                if (tsVessel) newParams.tsVessel = tsVessel;
                if (tsVesselIMO) newParams.tsVesselIMO = tsVesselIMO;
                if (tsETD) newParams.tsETD = tsETD;
                if (tsETA) newParams.tsETA = tsETA;
                return Object.keys(newParams).length > 0 ? newParams : null;
            }).filter(v => v);

            if(transhipmentInfo && transhipmentInfo.length > 0) newParams.transhipmentInfo = transhipmentInfo;

            const container = containers.map(v => {
                const containerNumber = data[`containerNumber${v}`];
                const carrierSealNumber = data[`carrierSealNumber${v}`];
                const containerType = data[`containerType${v}`];
                const weightUnit = data[`weightUnit${v}`];
                const weightValue = data[`weightValue${v}`];
                const volumeUnit = data[`volumeUnit${v}`];
                const volumeValue = data[`volumeValue${v}`];
                const newParams = {};

                if (containerNumber) newParams.containerNumber = containerNumber;
                if (carrierSealNumber) newParams.carrierSealNumber = carrierSealNumber;
                if (containerType) newParams.containerType = containerType;
                if (weightUnit) newParams.weight = {
                    ...newParams.weight,
                    weightUnit: weightUnit,
                };
                if (weightValue) newParams.weight = {
                    ...newParams.weight,
                    value: weightValue
                };
                if (volumeUnit) newParams.volume = {
                    ...newParams.volume,
                    unit: volumeUnit,
                };
                if (volumeValue) newParams.volume = {
                    ...newParams.volume,
                    value: volumeValue
                };
                return Object.keys(newParams).length > 0 ? newParams : null;
            }).filter(v => v);

            newParams.containerTracking = {};

            if (measurement)  newParams.containerTracking = {
                ...newParams.containerTracking,
                measurement
            };

            if (containerYard)  newParams.containerTracking = {
                ...newParams.containerTracking,
                containerYard
            };

            if (commodityDescription)  newParams.containerTracking = {
                ...newParams.containerTracking,
                commodityDescription
            };

            if (serviceType)  newParams.containerTracking = {
                ...newParams.containerTracking,
                serviceType
            };

            if (hsCode)  newParams.containerTracking = {
                ...newParams.containerTracking,
                hsCode
            };

            if (numberOfContainers)  newParams.containerTracking = {
                ...newParams.containerTracking,
                numberOfContainers
            };

            if (grossWeightUnit)  newParams.containerTracking.grossWeight = {
                ...newParams.containerTracking.grossWeight,
                weightUnit: grossWeightUnit,
            };

            if (grossWeightValue)  newParams.containerTracking.grossWeight = {
                ...newParams.containerTracking.grossWeight,
                value: grossWeightValue
            };

            if (container && container.length > 0) newParams.containerTracking.containers = container;

            delete newParams.originPort;
            delete newParams.destinationPort;

            delete newParams.documentType;

            doCreateLinkedShipment(shipmentId, newParams, () => {
                setAddLink(false);
                reset();
                setStep(0);
                setSeachParamActive("")
                setNotificationMessage(`A linked shipment was created successfully`);
                // history.push(ACTIVE_SHIPMENTS_URL);
            });
        }
    }

    /*eslint-disable */
	// useEffect(() => {
    //     setBreadcrums(breadcrumsMock.shipmentDetails);
    // }, [])

    useEffect(() => {
        if (type) {
            if (type === SHIPPING_BOOKING_REQUEST && !markAsShipmentTemplate) history.replace(BOOKING_REQUEST_URL);
            if (type === SHIPPING_TRACKER && !trackerTemplate) history.replace(TRACK_SHIPMENT_URL);
        } else {
            setMarkAsShipmentType(MARK_NEW);
        }
    }, [type])

    // useEffect(() => {
    //     if (!carriers || (carriers && !carriers.length)) doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    // }, [carriers])

    useEffect(() => {
        switch (markAsShipmentType) {
            case MARK_NEW:
                // setBreadcrums(breadcrumsMock.shipmentDetails);
                setShipmentDetails(null);
                break;
            case MARK_BOOKING_REQUEST:
                if (markAsShipmentTemplate) {
                    setBreadcrums([
                        ...breadcrumsMock.editRequest,
                        {
                            name: `Reference no.: ${markAsShipmentTemplate.referenceNumber}`,
                            url: EDIT_REQUEST_URL.replace(':brId', markAsShipmentTemplate._id)
                        },
                        {
                            name: 'Mark booking request as active shipment',
                            url: SHIPMENT_DETAILS
                        }
                     ]);
                }
                break;
            case MARK_TRACK_SHIPMENT:
                if (trackerTemplate && trackerParams) {
                    const { carrierName, containerId, pod, pol, postPod, prePol, vessels } = trackerTemplate;
                    const selectedCarrier = carriers.find(c => c.scacCode === carrierName);
                    let parameter = null;
                    switch(trackerParams[0]) {
                        case "BK":
                            parameter = {
                                carrierNo: trackerParams[1],
                                bkNum: trackerParams[2]
                            }
                            break;
                        case "BL":
                            parameter = {
                                carrierNo: trackerParams[1],
                                blNum: trackerParams[2]
                            }
                            break;
                        case "BC":
                            parameter = {
                                carrierNo: trackerParams[1],
                                containerNo: trackerParams[2],
                                originCode: trackerParams[3],
                                destCode: trackerParams[4]
                            }
                            break;
                        default:
                            break;
                    }
                    setBreadcrums([
                        breadcrumsMock.shipmentDetails[0],
                        {
                            name: `Mark container no. ${trackerTemplate.containerId} as active shipment`,
                            url: SHIPMENT_DETAILS
                        }
                    ])
                    setShipmentDetails(old => {
                        const newState = {
                            ...old,
                            bookingDetail: {
                                carrierName: selectedCarrier.carrierName,
                                scacCode: carrierName
                            },
                            routes: {
                                prepol: {
                                    actual: prePol.status === DONE,
                                    location: {
                                        name: prePol.location.name,
                                        country: prePol.location.country,
                                        locode: prePol.location.locode
                                    }
                                },
                                postpod: {
                                    actual: postPod.status === DONE,
                                    location: {
                                        name: postPod.location.name,
                                        country: postPod.location.country,
                                        locode: postPod.location.locode
                                    }
                                },
                                pol,
                                pod
                            },
                            containerTracking: {
                                containers: [{
                                    containerNumber: containerId
                                }]
                            },
                            vessels: vessels.map(v => {
                                return {
                                    name: v.name,
                                    imo: `${v.imo}`,
                                    voyage: `${v.mmsi}`
                                }
                            }),
                            searchParameter: {
                                type: trackerParams[0],
                                parameter
                            }
                        };

                        if (prePol.date) newState.routes.prepol.date = new Date(prePol.date).getTime();
                        if (postPod.date) newState.routes.postpod.date = new Date(postPod.date).getTime();

                        return newState;
                    })
                }
                break;
            default:
        }
    }, [markAsShipmentType, markAsShipmentTemplate])
    /*eslint-enable */

    return (

        <Modal
            open={open}
            className="tr__add-link-task"
            onCancel={onCancel}
            onCancelClick={onCancel}
            title="Create A Linked Shipment"
            footerLabel=""
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(createLinkedShipment)}>
                    {children}
                </form>
            )}
        >
            <div className="tr__add-link-task__form" >
                <StepsBreadcrum
                    steps={steps}
                    activeStep={step}

                />
                {step === 0 && (
                    <AddShipmentDetailsForm
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        shipmentDetails={shipmentDetails}
                        documents={documentFiles}
                        setDocuments={setDocumentFiles}
                        isLinkedShipment={true}
                        documentFile={documents}
                    />
                )}
                
                {step === 1 && (
                    <AddPartnersForm 
                        register={register}
                        shipmentDetails={shipmentDetails}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                    />
                )}
                {step === 2 && (
                    <VerifyDetailsForm 
                        register={register}
                        shipmentDetails={shipmentDetails}
                        setShipmentDetails={setShipmentDetails}
                        vessels={vessels}
                        setVessels={setVessels}
                        errors={errors}
                        getValues={getValues}
                        watch={watch}
                        containers={containers}
                        setContainers={setContainers}
                        transhipmentInfos={transhipmentInfos}
                        setTranshipmentInfos={setTranshipmentInfos}
                        setError={setError}
                        clearErrors={clearErrors}
                        setDepartureLocation={setDepartureLocation}
                        departureLocation={departureLocation}
                        setArrivalLocation={setArrivalLocation}
                        arrivalLocation={arrivalLocation}
                    />
                )}

            </div>
            <div className="tr__add-link-task__footer d-flex align-items-center justify-content-between">
                <div></div>
                <div>
                    <p 
                        className="tr__link secondary f-medium" 
                        onClick={handleCancel}
                    >
                        {step === 1 ? 'Back' : 'Cancel'}
                    </p>
                    {/* <Button 
                        isSubmitBtn={true}
                        onClick={handleNext}>
                        {step === 2 ? "Save" : "Continue"}
                    </Button> */}
                    <Button
                        isSubmitBtn={true}
                        loading={loading}
                    >
                         {step === 2 ? "Save" : "Continue"}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}