import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';

export const AISTracking = ({ aisTracking = [] }) => {
    const [aisTrackings,setAisTrackings] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if(aisTracking && aisTracking.length > 0) setAisTrackings(aisTracking)
    }, [aisTracking])
    /*eslint-enable */
    
    const getLocationValue = location => {
        if (location) {
            const { name, country, locode } = location;
            return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
        }
        return '';
    }

    return <InfoBlock
        className="tr__details-box"
        title="AIS Tracking"
    >
        <InfoRow>
            {aisTrackings.length > 0 ? aisTrackings.map((vessel, vesselIndex) => (
                <div className={`d-flex ${vesselIndex > 0 ? 'mtx3' : ''}`} key={vesselIndex}>
                    <div className="col-12 col-sm-3">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Vessel name"
                                value={vessel.name}
                            />
                            <InfoCell
                                className="col-sm-6"
                                label="IMO"
                                value={vessel.imo ? `${vessel.imo}` : ''}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Voyage"
                                value={vessel.voyage ? `${vessel.voyage}` : ''}
                            />
                            <InfoCell
                                className="col-sm-6"
                                label="Departure"
                                value={getLocationValue(vessel.departure)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Arrival"
                                value={getLocationValue(vessel.arrival)}
                            />
                        </div>
                    </div>
                </div>
            )) : ''}
        </InfoRow>
    </InfoBlock> 
};

AISTracking.propTypes = {
    aisTracking: PropTypes.array
};