import React, { useContext, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

import {
  Modal,
  Dropdown
} from 'components';
import { HomeContext, MemberContext, ModalContext, OrganizationContext, TeamContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { getErrorMessage, roleList } from 'utils';
import avatar from 'assets/images/avatar.png';
import { GET_FILE, MEMBER_PAGE_SIZE, OWNER } from 'actions';

export const ViewJoinRequest = ({ open = false, onCancel, onSubmit }) => {
  const { handleSubmit, register, errors, getValues } = useForm();
  const { setNotificationMessage, setPayloadConfirm } = useContext(HomeContext);
  const { organization, requesterInfo, doApproveJoinRequest, doCancelJoinRequest } = useContext(OrganizationContext);
  const { teams } = useContext(TeamContext);
  const { doGetOrgMembers } = useContext(MemberContext);
  const { setConfirmApprove } = useContext(ModalContext);
  const [rowTeams, setRowTeams] = useState([0]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTeamRoles, setSelectedTeamRoles] = useState([]);

  const isTeamNameDirty = row => {
      const name = getValues(`teamName-${row}`);
      return name;
  }

  const handleApprove = (data) => {
    const { organizationRole } = data;
    const { receiverId, requester } = requesterInfo;
    const orgId = organization?._id;

    let teamPayload = [];


    rowTeams.forEach((t, tIndex) => {
      if(selectedTeams[tIndex] && selectedTeamRoles[tIndex]) {
        teamPayload = [
          ...teamPayload,
          {
            id: selectedTeams[tIndex],
            role: selectedTeamRoles[tIndex]
          }
        ]
      }
      
    })

    const payload = {
      role: organizationRole.toUpperCase(),
      teams: teamPayload,
    }
    // console.log(payload)

    if (organizationRole === OWNER) {
      setConfirmApprove(true);
      setPayloadConfirm(payload);
    } else {
      doApproveJoinRequest(receiverId, requester._id, payload, () => {
        setRowTeams([0]);
        onCancel();
        setNotificationMessage(`<p> Request has been approved successfully!</p>`)
        doGetOrgMembers({
          orgId,
          page: 0,
          limit: MEMBER_PAGE_SIZE,
          isSearching: true
        })
      }, false)
    }
  }

  const handleReject = () => {
    const { receiverId, requester } = requesterInfo;
    const orgId = organization?._id;
    doCancelJoinRequest(receiverId, requester._id, () => {
      setRowTeams([0]);
      onCancel();
      doGetOrgMembers({
        orgId,
        page: 0,
        limit: MEMBER_PAGE_SIZE,
        isSearching: true
    })
      setNotificationMessage(`<p> Request has been rejected successfully!</p>`)
  }, false)
  }

  const handleAddMoreTeam = () => {
    setRowTeams(old => [...old, old.length])
  }

  const filterTeams = () => {
    return teams.filter(t => !selectedTeams.some(s => s === t._id)).map(t => {
      return {
        key: t._id,
        value: t.name
      }
    });
  }

  const handleSelectTeam = (team, teamIndex) => {
    setSelectedTeams(oldTeams => rowTeams.map((t, tIndex) => {
      if (teamIndex === tIndex) return team.key;
      if (oldTeams[tIndex]) return oldTeams[tIndex];
      return null;
    }))
  }
  const handleSelectRoleTeam = (team, teamIndex) => {
    setSelectedTeamRoles(oldTeams => rowTeams.map((t, tIndex) => {
      if (teamIndex === tIndex) return team.key.toUpperCase();
      if (oldTeams[tIndex]) return oldTeams[tIndex];
      return null;
    }))
  }

  return (
    <Modal
      open={open}
      className="tr__view-request-organization"
      onCancel={() => {
        setRowTeams([0]);
        onCancel();
      }}
      title={`Request To Join ${organization?.name}`}
      btnClasses="justify-content-end"
      submitButton
      submitButtonLabel="Approve"
      onSubmitClick={onSubmit}
      cancelButton={true}
      cancelButtonLabel="Reject"
      cancelButtonType="primary"
      cancelButtonClassNames="label"
      onCancelClick={handleReject}
      isBackDropClickable={false}
      renderForm={children => (
        <form onSubmit={handleSubmit(handleApprove)}>
            {children}
        </form>
    )}
    >
      <div className="tr__view-request-organization__box">
        <div className="tr__view-requester d-flex align-items-center">
            <div className="tr__view-requester--avatar">
            <img src={requesterInfo && requesterInfo.requester?.avatar !== "" ? GET_FILE + requesterInfo.requester?.avatar : avatar} alt={requesterInfo ? requesterInfo.requester?.name : ""}/>
            </div>
            <div className="tr__view-requester--info">
                <h4 className="h4 f-medium mbx1 has-tooltip">{requesterInfo ? requesterInfo.requester?.name : ''}</h4>
                <h5>{requesterInfo ? requesterInfo.requester?.email : ''}</h5>
                <p className="h2 mtx2">{requesterInfo ? requesterInfo.message : ''}</p>
            </div>
        </div>
        <h6 className="h6 mtx2  mbx4 f-medium text-left">Role in Organization</h6>

        <Dropdown
          name="organizationRole"
          icon="icon-chevron-down"
          iconPosition="right"
          className="mtx3 mbx4"
          placeholder="Role in Organization"
          options={requesterInfo && requesterInfo.currentUserRole.toLowerCase() === OWNER ? roleList : roleList.slice(1,3)}
          error={!!errors.organizationRole}
          refs={register({ required: true})}
          errorMessage={getErrorMessage(errors.organizationRole, "Organization Role", "This field cannot be blank")}
        />
        {rowTeams.map((row, rowIndex) => (
          <div className="row" key={rowIndex}>
            <Dropdown
              name={`teamName-${row}`}
              icon="icon-chevron-down"
              iconPosition="right"
              className="mtx3 mbx4 w-50"
              placeholder="Choose a team"
              options={teams && teams.length > 0 ? filterTeams() : []}
              // value={selectedTeams[row]}
              error={!!errors.teamName}
              refs={register()}
              errorMessage={getErrorMessage(errors.teamName, "Team Name", "This field cannot be blank")}
              onChange={team => handleSelectTeam(team, rowIndex)}
              />
              <Dropdown
                name={`teamRole-${row}`}
                icon="icon-chevron-down"
                iconPosition="right"
                // value={selectedTeams[row]}
                className="mtx3 mbx4 w-50"
                placeholder="Choose a role"
                options={roleList.slice(1,3)}
                error={!!errors[`teamRole-${row}`]}
                refs={register({required:isTeamNameDirty(row)})}
                errorMessage={getErrorMessage(errors[`teamRole-${row}`], "Role Team", "This field cannot be blank")}
                onChange={role => handleSelectRoleTeam(role, rowIndex)}
                  // let team = [...selectedTeams];
                  // team[rowIndex] = {
                  //   ...team[rowIndex],
                  //     role: data.key.toUpperCase()
                  // };
                  // setSelectedTeams(team)
                
              />
          </div>
        ))}
        
        <p className={`tr__link ${teams && teams.length === rowTeams.length ? "disabled" : ""}`} onClick={handleAddMoreTeam}><i className="icon-plus"></i>Add more team</p>
      </div>
    </Modal>
  )
};

ViewJoinRequest.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};