import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { DetailsTableHeader, LogItem, TableFooter } from 'components';
import { LOG_PAGE_SIZE, ORGANIZATIONS_LIST_URL, ORG_LOGS, TEAM_LOGS } from 'actions';
import { ActivitiesLogContext, HomeContext, OrganizationContext, TeamContext } from 'contexts';

export const ActivitiesLog = ({ backUrl = ORGANIZATIONS_LIST_URL }) => {
    const { logType } = useContext(HomeContext);
    const { 
        team,
    } = useContext(TeamContext);
    const { organization } = useContext(OrganizationContext);
    const { 
        logs, 
        doGetLogsTeam,
        totalPagesLog,  
        currentPageLog,
        doGetLogsOrg
    } = useContext(ActivitiesLogContext);

    const [exportData, setExportData] = useState([]);
    const [fileName, setFileName] = useState();

    /*eslint-disable */
    useEffect(() => {
        switch (logType) {
            case TEAM_LOGS:

                doGetLogsTeam(team && team._id, {
                    page: 0,
                    limit: LOG_PAGE_SIZE,
                }, () => {}, true)
                setFileName(team && team.name);
                break;
            case ORG_LOGS:
                doGetLogsOrg(organization && organization._id, {
                    page: 0,
                    limit: LOG_PAGE_SIZE,
                }, () => {}, true)
                setFileName(organization && organization.name);
                break;
            default:
                break;
        }
    }, [logType]); 
    /*eslint-enable*/
    const handlePageChange = page => {
        console.log(page)
        switch (logType) {
            case TEAM_LOGS:
                doGetLogsTeam(team && team._id, {
                    page: page,
                    limit: LOG_PAGE_SIZE,
                }, () => { }, true)
                break;
            case ORG_LOGS:
                doGetLogsOrg(organization && organization._id, {
                    page: page,
                    limit: LOG_PAGE_SIZE,
                }, () => {}, true)
                break;
            default:
                break;
        }
		
	}

    const generateTitle = () => {
        switch (logType) {
            case TEAM_LOGS:
                return "Team Activities Log";
            case ORG_LOGS:
                return "Organization Activities Log";
            default:
                return "Activities Log";
        }
    }

    const onExportCSV = (callback) => {
        if (logType === TEAM_LOGS) {
            doGetLogsTeam(team && team._id, {
                page: 0,
            }, (data) => {
                callback(data);
                setExportData(data.items);
            })
        } else {
            doGetLogsOrg(organization && organization._id, {
                page: 0,
            }, (data) => {
                callback(data);
                setExportData(data.items);
            })
        }
        
    }
    
    return (
        <div className="tr__activities-log">
            <div className="wrapper">
                <DetailsTableHeader
                    title={generateTitle()}
                    buttonAdd={false}
                    isExportCSV={true}
                    actionButton={false}
                    backUrl={backUrl}
                    exportData={exportData}
                    setExportData={setExportData}
                    fileName={fileName}
                    onExportCSV={onExportCSV}
                />
                <div className="page-box">
                    <LogItem logs={logs} />
                </div>
            </div>
            {totalPagesLog > 1 && (
                <TableFooter
                    currentPage={currentPageLog}
                    totalPages={totalPagesLog}
                    setCurrentPage={handlePageChange}
                />
            )}
        </div>
    )
};

ActivitiesLog.propTypes = {
    backUrl: PropTypes.func
};