import React, { useContext } from 'react';

import './index.scss';
import {
    UploadInvoicesForm,
    Button,
    Collapse
} from 'components';
import {
    formatPrice,
} from 'utils';
import { ExtractedContext, FtaAdvisorContext, HomeContext, ModalContext } from 'contexts';

export const FTADetails = ({ 
    register = () => {},
    errors,
    ftaAdvisor,
    files,
    setFiles = () => {},
    handleChangeSection= () => {},
    trigger = () => {},
    setFtaAdvisor = () => {},
    getValues = () => {},
}) => {
    const { setAddTask, setDocument } = useContext(ModalContext);
    const { setExtractedFtaData, doGetOcrFtaData } = useContext(ExtractedContext);
    const { ftaDetails, setDocumentFta } = useContext(FtaAdvisorContext);
    const { setIsAddTaskFta } = useContext(HomeContext);
    
    const handleSearchAgain = async () => {
        const isValid = await trigger();
        if (isValid) {
            setExtractedFtaData([]);
            let params = {};
            const ftaDestination = getValues('ftaDestination');
            const ftaOrigin = getValues('ftaOrigin');
            params = {...params, 
                ftaOrigin, ftaDestination
            };
            if(files.length > 0) await doGetOcrFtaData(files);
            setFtaAdvisor(params);
            handleChangeSection(-1);
        };
    }

    return (
        <div className="tr__fta-details">
            <div className="row">
                <div className="col-12 col-sm-3">
                    <div className="tr__fta-details--form">
                        <UploadInvoicesForm
                            register={register}
                            errors={errors}
                            ftaAdvisor={ftaAdvisor}
                            files={files}
                            setFiles={setFiles}
                        />
                        <div className="tr__fta-details--form__footer">
                            <p className="mbx2">All uploaded invoices shall be stored under document tab.</p>
                            <Button className="w-100" onClick={handleSearchAgain}>
                                Search Again
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    {ftaDetails && ftaDetails.length > 0 ? ftaDetails.map((invoice, invoiceIndex) => (
                        <div className={`tr__fta-details--invoice ${invoiceIndex < ftaDetails.length - 1 ? 'mbx5' : ''}`} key={invoiceIndex}>
                            {invoice.invoiceNumber && 
                                <div className='invoice-header'>
                                    <h1 className="h1 f-medium mbx3 text-center">
                                        <small>Invoice No.:</small>
                                        <span>{invoice.invoiceNumber}</span>
                                    </h1>
                                </div>
                            }
                            {invoice.items.length > 0 && invoice.items.map((item, itemIndex) => (
                                <div key={itemIndex}>
                                    <div className="invoice-table mtx3">
                                        {item.goodsType && (
                                            <h5 className="h5 f-medium mbx2">
                                                <span>Goods type:</span>
                                                <span>{item.goodsType}</span>
                                            </h5>
                                        )}
                                        <Collapse
                                            initialOpen={true}
                                            className="details no-padding"
                                            renderHeader={() => (
                                                <div className="good-title d-flex align-items-center justify-content-between w-100">
                                                    <h5 className="h5 f-medium">
                                                        <span>{item.hsCode}</span>
                                                    </h5>
                                                    <p className="toggle-btn">
                                                        <i className="icon-chevron-down" />
                                                    </p>
                                                </div>
                                            )}
                                        >
                                            {item.taxSet  && item.taxSet.length > 0 && item.taxSet.map((group, groupIndex) => (
                                                <Collapse
                                                    key={groupIndex}
                                                    className="invoice-table--collapse no-padding"
                                                    disableHeaderClick={!(group.tax.length > 1)}
                                                    renderHeader={handleCollapse => (
                                                        <div className="invoice-table--group d-flex w-100">
                                                            <div className="info">
                                                                <div className="d-flex align-items-center justify-content-between mbx2">
                                                                    <h3 className="h3 f-medium">
                                                                        <span className="mrx1">Group:</span>
                                                                        <span>{group.groupName}</span>
                                                                    </h3>
                                                                    {
                                                                        group.tax.length > 1 &&
                                                                        <p className="tr__link more-link secondary" onClick={handleCollapse}>
                                                                            <span className="show">Show more</span>
                                                                            <span className="hide">Show less</span>
                                                                        </p>
                                                                    }
                                                                </div>
                                                                <div className="d-flex align-items-center info-row">
                                                                    <p className="label info-row--label">Custom duty</p>
                                                                    <p className="info-row--value">{group.tax[0].taxFormula}</p>
                                                                </div>
                                                            </div>
                                                            <div className="price text-right">
                                                                {groupIndex > 0 && group.amountSave !== null ? (
                                                                    <>
                                                                        <h4 className="h4 f-medium">{item.currency}</h4>
                                                                        <h4 className="h4 f-medium">{formatPrice(group.amountSave)} saved</h4>
                                                                    </>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    <div className="invoice-table--group details d-flex w-100">
                                                        <div className="info">
                                                            <div className="info--wrapper">
                                                                <p className="label mbx1">Others</p>
                                                                {group.tax && group.tax.length > 0 ? group.tax.map((row, rowIndex) => { return rowIndex > 0 ? (
                                                                    <div className="info-row d-flex align-items-center mbx2" key={rowIndex}>
                                                                        <p className="info-row--label prx2">{row.taxName}</p>
                                                                        <p className="info-row--value">{row.taxFormula}</p>
                                                                    </div>
                                                                ) : null}) : (
                                                                        <p className="no-result">No details</p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className="price"></div>
                                                    </div>
                                                </Collapse>
                                            ))}
                                        </Collapse>
                                    </div>
                                    {item.docDetFta.length > 0 &&
                                        <div className="invoice-table-documnents">
                                            <h3 className="h3 f-medium mtx3 mbx2">Documents</h3>
                                            <div className="table--content">
                                                <div className="table--content--header f-medium">
                                                    Document Name
                                            </div>
                                                {item.docDetFta[0] && item.docDetFta[0].docDetSet && item.docDetFta[0].docDetSet.length > 0 && 
                                                    item.docDetFta[0].docDetSet.map((document, documentIndex) => (
                                                        <div className="table--content--body d-flex table-body justify-content-between" key={documentIndex}>
                                                            <div className="column name">
                                                                {document.docName}
                                                            </div>
                                                            <div className="column link  justify-content-between">
                                                                <p className="tr__link mrx2" 
                                                                    onClick={() => {
                                                                        setDocumentFta(document);
                                                                        setDocument(true);
                                                                    }}
                                                                >
                                                                    Details
                                                                </p>
                                                                <a className="tr__link" target="noreferrer" href={document.docLink}>Sample doc</a>
                                                            </div>
                                                            <div className="column action-button">
                                                                <Button
                                                                    className="outline"
                                                                    type="secondary"
                                                                    onClick={() => {
                                                                        setDocumentFta(document);
                                                                        setIsAddTaskFta(true);
                                                                        setAddTask(true);
                                                                    }}
                                                                >
                                                                    Add To Task
                                                                </Button>
                                                            </div>
                                                        </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    )) : <div>No FTA found</div>}
                </div>
            </div>
        </div>
    )
};