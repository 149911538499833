import React, { useState } from 'react';

import './index.scss';

import { PropTypes } from 'prop-types';
import { getErrorMessage } from 'utils';
import { Input } from 'components/common';

export const ExtractedInfoFta = ({ 
    register = () => {},
    errors = [],
    extractedInfo = {},
    watch = () => {},
}) => {
    const [items, setItems] = useState([0,1]);
    const [extractedData, setExtractedData] = useState([]);

    useState(() => {
        let data = [];
        for (let key in extractedInfo) {
            if (key !== "id") {
                data.push({
                    name: key.charAt(0).toUpperCase() + key.slice(1),
                    value: extractedInfo[key]
                });
            }
        }
        setExtractedData(data)
    },[extractedInfo])

    // const addNewLine = () => {
    //     let item = 1;
    //     if(items.length > 0) item = Math.max(...items) + 1;
    //     setItems(old => [...old, item]);
    // }

    const removeLine = (item) => {
        setItems(old => [...old.filter(o => o !== item)]);
    }

    const handleError = (index,field) => {
        return errors.items && errors.items[index] ? errors.items[index][field] : null;
    }

    return(
            <div className="tr__extracted-info-table">
                <div className="tr__extracted-info-list">
                    <div className="tr__extracted-info-list--header d-flex justify-content-between align-items-center">
                        <div className="d-flex f-medium">
                        </div>
                    </div>
                    <div className="tr__extracted-info-list--table">
                    {items.length > 0 && items.map(item => {
                            const items = `items[${item}]`;

                            return (
                                <div className="table-content d-flex justify-content-between" key={item}>
                                    <div className="col-6 column name d-flex align-items-center">
                                        <i className="icon-times mrx2" onClick={() => removeLine(item)}/>
                                        <Input
                                            className="full"
                                            name={`${items}.key`}
                                            maxLength={50}
                                            refs={register({required: watch(`${items}.value`)})}
                                            error={handleError(item,'key')}
                                            errorMessage={getErrorMessage(handleError(item,'key'), "key")}
                                            defaultValue={extractedData[item] ? 
                                                extractedData[item].name : ""
                                            }
                                        />
                                    </div>
                                    <div className="col-6 column">
                                        <Input
                                            name={`${items}.value`}
                                            refs={register()}
                                            defaultValue={extractedData[item] ? 
                                                extractedData[item].value : ""
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        {/* {items.length < 8 &&
                            <div className="d-flex align-items-center add-new-line" onClick={() => addNewLine()}>
                                <i className="icon-plus mrx1"/>
                                <h4 className="f-medium">Add New Line</h4>
                            </div>
                        } */}
                    </div>
                </div>
            </div>
    );
};

ExtractedInfoFta.propTypes = {
    extractedInfo: PropTypes.any
}