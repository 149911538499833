import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import avatar from 'assets/images/avatar.png';
import {
    Modal,
    MultiSelect,
    ItemCard
} from 'components';
import {
    AuthContext,
    BookingRequestContext,
    HomeContext,
    MemberContext,
    ModalContext,
    TeamContext
} from 'contexts';
import { MEMBER, TEAM, GET_FILE } from 'actions';
import { useLocation } from 'react-router-dom';

const MEMBER_U = MEMBER.toUpperCase();
const TEAM_U = TEAM.toUpperCase();

export const AddBRCollaborators = ({ open = false, onCancel }) => {
    const { setNotificationMessage } = useContext(HomeContext)
    const { userInfo } = useContext(AuthContext);
    const { members, doGetMembers } = useContext(MemberContext);
    const { teams, doGetTeams } = useContext(TeamContext);
    const { setAddBRCollaborators } = useContext(ModalContext);
    const { bookingRequest, doUpdateBookingRequestCollaborators, doGetBookingRequestLogs } = useContext(BookingRequestContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const brId = pathnameArr[pathnameArr.length - 1];

    const { handleSubmit, register, reset, errors, formState } = useForm();
    const [teamLoading, setTeamLoading] = useState(false);
    const [memberLoading, setMemberLoading] = useState(false);
    const [dropdownList, setDropdownList] = useState([]);
    const [collaborators, setCollaborators] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (open) {
            doGetMembers({
                isSearching: true
            });
            doGetTeams({
                isSearching: true
            })
        }
    }, [open])

    useEffect(() => {
        setDropdownList([...(members || []).map(mem => {
            return {
                ...mem,
                type: MEMBER_U
            }
        }), ...(teams || []).map(team => {
            return {
                ...team,
                type: TEAM_U
            }
        })].sort((a, b) => a?.name?.localeCompare(b.name)));
    }, [members, teams])
    /*eslint-enable */

    const getMembersTeams = (value) => {
        setTeamLoading(true);
        setMemberLoading(true);

        doGetMembers({
            term: value,
            isSearching: true
        }, () => {
            setMemberLoading(false);
        }, () => {
            setMemberLoading(false);
        }, false);

        doGetTeams({
            term: value,
            isSearching: true
        }, () => {
            setTeamLoading(false);
        }, () => {
            setTeamLoading(false);
        }, false);
    }

    const onHideAddMemberModal = () => {
        reset();
        onCancel();
    }

    const filteredOptions = (options, selectedOptions) => {
        if (!userInfo || !bookingRequest) return options;
        const memberInfos = bookingRequest.memberInfo;
        const afterSelectedOptions = options.filter(item => !selectedOptions.some(i => i.id === item._id) && userInfo._id !== item._id && !memberInfos.some(m => m.id === item._id));
        return afterSelectedOptions;
    }

    const handleAddCollaborators = () => {
        if (collaborators.length > 0 && bookingRequest) {
            let memberIds = [];
            let teamIds = [];

            collaborators.forEach(c => {
                switch(c.type) {
                    case MEMBER_U:
                        memberIds.push(c.id)
                        break;
                    case TEAM_U:
                        teamIds.push(c.id)
                        break;
                    default:
                        break;
                }
            });

            let payload = {};
            if (memberIds.length > 0) payload.memberIds = memberIds;
            if (teamIds.length > 0) payload.teamIds = teamIds;

            doUpdateBookingRequestCollaborators(bookingRequest._id, payload, () => {
                setNotificationMessage(`${collaborators.length} collaborator${collaborators.length > 1 ? 's' : ''} has been added successfully!`);
                doGetBookingRequestLogs(brId);
                reset();
                setCollaborators([]);
                setAddBRCollaborators(false);
            });
        }
    }
    
    return (
        <Modal
            open={open}
            className="tr__add-member"
            onCancel={onHideAddMemberModal}
            title={`Add collaborators`}
            submitButton
            submitButtonLabel="Add"
            footerLabel="We will invite all new members on behalf of you"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleAddCollaborators)}>
                    {children}
                </form>
            )}
        >
            <MultiSelect
                className="mbx4"
                value={collaborators}
                name="consignee"
                label='Assign collaborators'
                addButton={true}
                options={filteredOptions(dropdownList, collaborators).map(item => {
                    return {
                        id: item._id,
                        icon: item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users',
                        title: item.name,
                        description: item.type === MEMBER_U ? item.email : (item.organization ? item.organization.name : ''),
                        type: item.type
                    }
                })}
                addButtonLabel="Add"
                onInputChange={getMembersTeams}
                searchLoading={teamLoading || memberLoading}
                labelIcon='icon-box'
                placeholder='You can search by name, email, team name or can invite new people...'
                register={register}
                errors={errors}
                formState={formState}
                onChange={items => setCollaborators(oldValue => [...oldValue, ...items])}
                renderList={members => (
                    <div className="tr__partners d-flex flex-wrap mtx1">
                        {members.map((member, memberIndex) => (
                            <ItemCard
                                key={memberIndex}
                                item={member}
                                onRemove={item => setCollaborators(oldValue => [...oldValue.filter(val => val.id !== item.id)])}
                                cardOnly={true}
                                register={register}
                            />
                        ))}
                    </div>
                )}
            />
        </Modal>
    )
};