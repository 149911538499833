import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dropdown,
    FileUploader
} from 'components';
import { countryList, getErrorMessage } from 'utils';
import { FtaAdvisorContext, ShipmentInfoContext } from 'contexts';
import { 
    DEBOUNCE_TIME, 
    DESTINATION, 
    LOCODE_PAGE_SIZE, 
    MAX_FILE_FTA_ADVISOR_DOCS, 
    MAX_SIZE_SHIPMENT_DETAIL_DOC, 
    ORIGIN, 
    FILE_TYPE_SHIPMENT_DETAIL_DOCS, 
    FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS 
} from 'actions';

export const UploadInvoicesForm = ({
    register = () => {},  
    errors = [],
    ftaAdvisor,
    files = [],
    setFiles = () => {},  
    setValue = () => {},
    isArchive = false
}) => {
    const [selectOrigin, setSelectOrigin] = useState();
    const [selectDestination, setSelectDestination] = useState();
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);

    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const {  
        destinationCountries,
        originCountries,
        doGetCountries
     } = useContext(FtaAdvisorContext);


    /*eslint-disable */
     useEffect(() => {
        doGetCountries();
     },[])

    useEffect(() => {
        if(ftaAdvisor && ftaAdvisor.ftaOrigin && ftaAdvisor.ftaDestination){
            const selectOrigin = countryList.find(item => item.locode === ftaAdvisor.ftaOrigin);
            const selectDest = countryList.find(item => item.locode === ftaAdvisor.ftaDestination);
            
            setSelectOrigin({
                key: selectOrigin.locode,
                value: selectOrigin.name
            });
            setSelectDestination({
                key: selectDest.locode,
                value: selectDest.name
            });
        }else if(!ftaAdvisor && shipmentInfo && shipmentInfo.addresses && shipmentInfo.addresses.pol && shipmentInfo.addresses.pod ){
            setSelectOrigin({
                key: shipmentInfo.addresses.pol.location.locode.slice(0,2),
                value: shipmentInfo.addresses.pol.location.country
            });
            setSelectDestination({
                key: shipmentInfo.addresses.pod.location.locode.slice(0,2),
                value: shipmentInfo.addresses.pod.location.country
            });
        }else{
            setSelectOrigin({});
            setSelectDestination({});
        }
    },[shipmentInfo,ftaAdvisor])
    /*eslint-enable */

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
            const currentItem = uploadFiles[i];
            if (!currentItem.name) return
            const isExist = files.find(f => f.name === currentItem.name && f.size === currentItem.size);
            if (!isExist) fileList.push(currentItem)
        }
        setFiles(oldFiles => [...oldFiles, ...fileList])
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const onSearchOriginCountries = value => {
        setOriginLoading(true);
        setValue('ftaOrigin', '', { shouldValidate: true });
        setTimeout(() => {
            doGetCountries({limit: LOCODE_PAGE_SIZE,value},() => {
                setOriginLoading(false);
            }, ORIGIN);
        }, DEBOUNCE_TIME);
    }

    const onSearchDestCountries = value => {
        setDestinationLoading(true);
        setValue('ftaDestination', '', { shouldValidate: true });
        setTimeout(() => {
            doGetCountries({limit: LOCODE_PAGE_SIZE,value},() => {
                setDestinationLoading(false);
            }, DESTINATION);
        }, DEBOUNCE_TIME);
    }

    return (
        <div className="tr__upload-invoices-form">
            <Dropdown
                className="select mbx4"
                icon="icon-chevron-down"
                iconPosition="right"
                mode="input"
                name="ftaOrigin"
                label="Origin"
                required
                options={originCountries && originCountries.length > 0 ? originCountries.map(item => {
                    return {
                        key: item.locode,
                        value: item.name,
                    }
                }) : []}
                placeholder="Select origin"
                dropdownPosition="center"
                refs={register({required: true})}
                disabled={isArchive}
                error={!!errors.ftaOrigin}
                errorMessage={getErrorMessage(errors.ftaOrigin, "Origin")}
                defaultValue={selectOrigin}
                searchLoading={originLoading}
                onInputChange={onSearchOriginCountries}
            />
            <Dropdown
                className="select mbx4"
                icon="icon-chevron-down"
                iconPosition="right"
                mode="input"
                name="ftaDestination"
                label="Destination"
                required
                options={destinationCountries && destinationCountries.length > 0 ? destinationCountries.map(item => {
                    return {
                        key: item.locode,
                        value: item.name,
                    }
                }) : []}
                placeholder="Select destination"
                dropdownPosition="center"
                refs={register({required: true})}
                disabled={isArchive}
                error={!!errors.ftaDestination}
                errorMessage={getErrorMessage(errors.ftaDestination, "Destination")}
                defaultValue={selectDestination}
                searchLoading={destinationLoading}
                onInputChange={onSearchDestCountries}
            />
            <FileUploader
                name="invoiceDocs"
                label="Upload Invoices"
                className="big-label"
                handleDrop={handleFileUpload}
                handleRemove={handleFileRemove}
                uploadedFiles={files}
                mode="list"
                refs={register()}
                disabled={isArchive}
                outputFormat='base64'
                limitNumberUploadFile={MAX_FILE_FTA_ADVISOR_DOCS}
                limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                fileTypeApproved={['image', 'pdf']}
                ocrRequired={true}
                uploadInner={true}
                uploadFileType={FILE_TYPE_SHIPMENT_DETAIL_DOCS}
                uploadFileSubType={FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS}
            />
        </div>
    )
};
UploadInvoicesForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    ftaAdvisor: PropTypes.any,
};
