import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const ShipmentTag = ({ tag }) => {
    return (
        <div className={`tr__shipment-tag task-status ${tag.id.toLowerCase()}`}>
            <span>{tag.num}</span>
            <span>{tag.id}</span>
        </div>
    )
};

ShipmentTag.propTypes = {
    tag: PropTypes.object
}