import React, { useContext, useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';

import './index.scss';
import { Input, Dropdown, Button } from 'components';
import { CARRIER_PAGE_SIZE, TAB_BL_NUMBER } from 'actions';
import { useForm } from 'react-hook-form';
import { TrackShipmentContext } from 'contexts';

export const BLNumberForm = ({ onSubmit, trackerParams }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const { handleSubmit, register, errors, reset } = useForm();
    const { carriers, doGetCarriers, doGetTrackAndTraceV2 } = useContext(TrackShipmentContext)

    /*eslint-disable */
     useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        doGetTrackAndTraceV2({
            type: 'ocean',
            ...data
        }, () => {
            reset();
            setLoading(false);
            onSubmit(TAB_BL_NUMBER);
        }, () => {
            reset();
            setLoading(false);
        }, false);
    }

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        });
    }

    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="scac"
                        label="Select carrier"
                        error={!!errors.carrier}
                        errorMessage={getErrorMessage(errors.carrier, "Carrier")}
                        refs={register({ required: true })}
                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {
                            return {
                                key: carrier.scacCode,
                                value: `${carrier.carrierName} (${carrier.scacCode})`
                            }
                        }) : []}
                        placeholder="You can select any of the carrier..."
                        dropdownPosition="center"
                        onInputChange={onSearchCarrier}
                        searchLoading={searchLoading}
                        defaultValue={trackerParams && trackerParams.carrier ? trackerParams.carrier : ""}
                    />
                </div>
                <div className="tr__container-form--group">
                    <Input
                        label="Enter bill of lading number"
                        name="mblNo"
                        placeholder="e.g. MBL2472530"
                        refs={register({ required: true })}
                        error={!!errors.billOfLadingNumber}
                        errorMessage={getErrorMessage(errors.billOfLadingNumber, "Bill of Lading Number")}
                        defaultValue={trackerParams && trackerParams.billOfLadingNumber ? trackerParams.billOfLadingNumber : ""}
                    />
                </div>
            </div>
            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    Search shipment
                </Button>
            </div>
        </form>
    )
};
