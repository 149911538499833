import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.scss';
import {
    Statuses,
    Tooltip
} from 'components';

import { ManagementContext} from 'contexts';
import { SHIPMENT_STATUS } from 'actions';

export const DetailsHeader = ({ shipment, shipmentInfo, statuses, nameShipment }) => {
    const [totalTasks, setTotalTasks] = useState(0);
    const { taskManagements } = useContext(ManagementContext);

    const mapStatuses = () => {

        const taskArr = taskManagements.map(item => item.tasks.events.concat(item.tasks.documents));
        const taskStatus = [].concat.apply([], taskArr); 

        const statuses = [
            {
                id: SHIPMENT_STATUS.INPROGRESS.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.PENDING.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.STUCK.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.DONE.toLowerCase(),
                num: 0
            }
        ];
        
        taskStatus.forEach(d => {
            switch (d.status.taskStatus) {
                case SHIPMENT_STATUS.INPROGRESS:
                    statuses[0].num += 1;
                    break;
                case SHIPMENT_STATUS.PENDING:
                    statuses[1].num += 1;
                    break;
                case SHIPMENT_STATUS.STUCK:
                    statuses[2].num += 1;
                    break;
                case SHIPMENT_STATUS.DONE:
                    statuses[3].num += 1;
                    break;
                default:
            }
        })
        return statuses;
    }
    /*eslint-disable */
    useEffect(() => {
        if (mapStatuses() && mapStatuses().length > 0) {
            let total = 0;
            mapStatuses().forEach(status => total += status.num);
            setTotalTasks(total);
        }
    }, [taskManagements])
    /*eslint-enable */

    return (
        <div className='tr__details-header d-flex align-items-end justify-content-between mtbx2'>
            <div className="d-flex align-items-center">
                <span className='h1 f-medium'>{nameShipment}</span>
                <p> </p>
                {/* {statuses && ( */}
                    <Tooltip
                        renderContent={() => (
                            <div className="statistics">
                                <div className="statistics--header d-flex align-items-center justify-content-between">
                                    <p className="label f-medium">Total</p>
                                    <p className="num">{totalTasks} task{totalTasks > 1 ? 's' : ''}</p>
                                </div>
                                <div className="statistics--content">
                                    {mapStatuses().map((status, statusIndex) => (
                                        <div className="statistics--item mtx05 d-flex align-items-center justify-content-between" key={statusIndex}>
                                            <p className="label">{status.id}</p>
                                            <p>
                                                {status.num - 1 > 0 && <span>{status.num - 1} event{status.num - 1 > 1 ? 's' : ''}</span>}
                                                <span>1 doc</span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    >
                        {taskManagements && taskManagements.length > 0 && <Statuses
                            className="bar mlx2"
                            statuses={mapStatuses()}
                            showLabel={true}
                            suffix="%"
                        />}
                    </Tooltip>
                {/* )} */}
            </div>
            {shipmentInfo && (
                <p className="f-medium mlx3 creator_shipment">
                    {shipmentInfo.creatorName && shipmentInfo.creatorName.length >= 30 ? (
                        <Tooltip content={shipmentInfo.creatorName}>
                            <span className={` has-tooltip`}>
                                {shipmentInfo.creatorName}
                            </span>
                        </Tooltip>
                    ) : (
                            <span className={``}>
                                {shipmentInfo.creatorName}
                            </span>
                        )}
                    &nbsp;
                    have added this shipment on {moment(shipmentInfo.base && shipmentInfo.base.createdDate).format('DD MMM YYYY')}
                </p>
            )}

            {/* <span>{shipmentInfo.creatorName}</span> */}

        </div>
    );
};

DetailsHeader.propTypes = {
    shipment: PropTypes.object,
    note: PropTypes.string,
};
