import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import './index.scss';
import {
    Dropdown,
    Button,
    Datepicker,
    RadioGroup,
    NoResultSearch
} from 'components';
import { datesCheckbox, getErrorMessage } from 'utils';
import {
    VESSEL_SCHEDULE_RESULTS_URL,
    LOCODE_PAGE_SIZE,
    ORIGIN,
    DESTINATION,
    DEPARTURE
} from 'actions';


import {
    ModalContext,
    TrackShipmentContext,
    VesselScheduleContext
} from 'contexts';
import { useForm } from 'react-hook-form';

export const SearchVesselForm = () => {
    const history = useHistory();
    const { noResultSearch, setNoResultSearch } = useContext(ModalContext);
    const { origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { vesselSchedule, doGetVesselSchedule } = useContext(VesselScheduleContext);

    const { handleSubmit, register, errors } = useForm();
    const [loading, setLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(datesCheckbox[0]);
    const [currentParams, setCurrentParams] = useState();

    /*eslint-disable */
    useEffect(() => {
        if (currentParams) {
            if (!vesselSchedule.length) {
                setNoResultSearch(true);
            } else {
                const { originPort, destinationPort, dates, vesselType } = currentParams;
                
                history.push({ 
                    pathname: VESSEL_SCHEDULE_RESULTS_URL
                        .replace(':type', vesselType)
                        .replace(':origin', originPort)
                        .replace(':destination', destinationPort)
                        .replace(':dates', dates)
                });
            }
        }
    }, [vesselSchedule, currentParams])
    /*eslint-enable */

    const onSearchOrigin = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }
  
    const onSearchDestination = value => {
        setDestinationLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    const handleSearch = (data) => {
        setLoading(true);
        setCurrentParams(null);

        const { origin, destination, vesselType, vesselScheduleRange } = data;
        let dates = vesselScheduleRange.split('-');

        if (dates && (dates.length <= 0 || dates.length > 2)) return;
        else {
            dates = dates.length === 1 ? [...dates, ...dates] : dates;
            const from = moment(dates[0]).format('YYYY-MM-DD');
            const to = moment(dates[1]).format('YYYY-MM-DD');
            let datesStr = `${from}+${to}`;

            const params = {
                originPort: origin,
                destinationPort: destination,
            }
    
            if (vesselType === DEPARTURE) {
                params['start_date.gte'] = from;
                params['start_date.lte'] = to;
            } else {
                params['end_date.gte'] = from;
                params['end_date.lte'] = to;
            }

            console.log(datesStr, data)
            
            doGetVesselSchedule(params, () => {
                setLoading(false);
                setCurrentParams({
                    ...params,
                    dates: datesStr,
                    vesselType
                });
            }, () => {
                setLoading(false);
            }, false, true);
        }
    }

/*eslint-disable */
    useEffect(()=>{
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE
        });
    },[]);
/*eslint-enable */

    return (
        <div className="tr__search-forms">
            <h4 className="h4 box-header">Search Vessel Schedule</h4>
            <form className="tr__container-form tr__vessel-schedule-form" onSubmit={handleSubmit(handleSearch)}>
                <div className="form-group">
                    <div className="tr__container-form--group route">
                        <span className="route-icon">
                            <i className="icon-location" />
                            <i className="icon-location" />
                        </span>
                        <Dropdown
                            className="select"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="input"
                            name="origin"
                            label="Vessel route"
                            horizontalLabel="Origin:"
                            error={!!errors.origin}
                            errorMessage=" "
                            searchLoading={originLoading}
                            refs={register({ required: true })}
                            onInputChange={onSearchOrigin}
                            options={origins && origins.length > 0 ? origins.map(locode => {
                                return {
                                    key: locode.locode,
                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                    item: locode
                                }
                            }) : []}
                            placeholder="Search by country or port"
                            dropdownPosition="center"
                        />
                        <Dropdown
                            className="select"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="input"
                            name="destination"
                            horizontalLabel="Destination:"
                            error={!!errors.destination}
                            errorMessage=" "
                            searchLoading={destinationLoading}
                            refs={register({ required: true })}
                            onInputChange={onSearchDestination}
                            options={destinations && destinations.length > 0 ? destinations.map(locode => {
                                return {
                                    key: locode.locode,
                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                    item: locode
                                }
                            }) : []}
                            placeholder="Search by country or port"
                            dropdownPosition="center"
                        />
                    </div>
                    <div className="tr__container-form--group mtbx4">
                        <RadioGroup
                            name="vesselType"
                            buttonMode={false}
                            type="default"
                            horizontal
                            items={datesCheckbox}
                            value={selectedDate}
                            refs={register()}
                            onChange={date => setSelectedDate(date)}
                        />
                    </div>
                    <div className="tr__container-form--group">
                        <Datepicker
                            name="vesselScheduleRange"
                            label="Range"
                            placeholder="You can search by the date range"
                            refs={register({ required: true })}
                            error={!!errors.vesselScheduleRange}
                            errorMessage={getErrorMessage(errors.vesselScheduleRange, 'Date Range')}
                            range={true}
                        />
                    </div>
                </div>
            
                <div className="form-btns">
                    <Button
                        isSubmitBtn={true}
                        loading={loading}
                    >
                        Search schedule
                    </Button>
                </div>
            </form>

            <NoResultSearch
                open={noResultSearch}
                onCancel={() => setNoResultSearch(false)}
                title="Search Vessel Schedule"
                cancelButton={true}
                cancelButtonLabel="OK"
                cancelButtonType="primary"
            />
        </div>
    );
};