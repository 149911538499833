import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import {
    ShipmentSteps,
    Collapse,
    ShipmentTags,
    ShipmentProgresses,
    Input,
    Button
} from 'components';
import { ACTIVE, ACTIVE_SHIPMENT_PAGE_SIZE, SHIPMENT_STATUS } from 'actions';
import { ActiveShipmentsContext, HomeContext, ModalContext, ShipmentInfoContext } from 'contexts';

export const ShipmentCard = ({ 
    className = '', 
    shipment, 
    onShowDetails, 
    isDetails = false, 
    isCardDetails = false, 
    onStarShipment = () => {},
    isStar = false,
    currentTab,
    currentPage
}) => {
    const { isArchive } = useContext(HomeContext);
    const { doUpdateShipmentNote } = useContext(ShipmentInfoContext);
    const [isEditBC, setIsEditBC] = useState(false);
    const [shipmentNote, setShipmentNote] = useState(shipment.note && shipment.note.content);
    const { setAddLink } = useContext(ModalContext);
    const { 
        doGetActiveShipments
    } = useContext(ActiveShipmentsContext);

    const getTags = () => {
        if (!shipment) return;
        const { taskNumber } = shipment;
        const { done, inprogress, pending, stuck } = taskNumber;
        let tags = [];

        if (inprogress > 0) tags.push({
            id: SHIPMENT_STATUS.INPROGRESS,
            num: inprogress
        });

        if (pending > 0) tags.push({
            id: SHIPMENT_STATUS.PENDING,
            num: pending
        });

        if (stuck > 0) tags.push({
            id: SHIPMENT_STATUS.STUCK,
            num: stuck
        });

        if (done > 0) tags.push({
            id: SHIPMENT_STATUS.DONE,
            num: done
        });

        return tags;
    }

    const toggleEditBC = () => {
        if (!isArchive) {
            setShipmentNote(shipmentNote);
            setIsEditBC(!isEditBC);
        }
    };

    const handleUpdateShipmentNote = () => {
        shipment.note = shipmentNote;
        doUpdateShipmentNote(shipment._id, shipmentNote, () => {
            // setShipmentNote(shipmentNote);
            doGetActiveShipments({
                page: currentPage,
                limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                tab: currentTab,
                status: ACTIVE,
                // name: seachParamActive
            });
            setIsEditBC(false);
        });
    }

    // const getTaskOpen = taskNumber => {
    //     if (!taskNumber) return 0;
    //     return taskNumber.stuck + taskNumber.pending + taskNumber.inprogress;
    // }

    const getShipmentStep = () => {
        if (!shipment) return null;
        const { routes, _id } = shipment;
        const { prepol, pol, pod, postpod } = routes;
        const newPrepol = {
            id: `${_id}-step1`,
            icon: 'icon-containers',
            title: prepol && prepol.location && pol && pol.location && prepol.location.locode !== pol.location.locode
                ? `${prepol.location.name}, ${prepol.location.country} (${prepol.location.locode})`
                : 'PREPOL',
            label: 'Arr: ',
            date: prepol && prepol.date
                ? moment.utc(prepol.date).format('YYYY-MM-DD HH:mm')
                : (prepol && pol && prepol.location && pol.location && prepol.location.locode === pol.location.locode
                    ? moment.utc(pol.date).format('YYYY-MM-DD HH:mm')
                    : ''),
            showHours: true,
            actual: prepol ? prepol.actual : (pol ? pol.actual : false),
        };
        const newPol = {
            id: `${_id}-step2`,
            icon: 'icon-anchor',
            title: `${pol && pol.location && pol.location.name}, ${pol && pol.location && pol.location.country} (${pol && pol.location && pol.location.locode})`,
            label: 'Dept: ',
            date: moment.utc(shipment.departureDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pol ? pol.actual : false,
        };
        const newPod = {
            id: `${_id}-step4`,
            icon: 'icon-anchor',
            title: `${pod && pod.location && pod.location.name}, ${pod && pod.location && pod.location.country} (${pod && pod.location && pod.location.locode})`,
            label: 'Est Arr: ',
            date: moment.utc(shipment.arrivalDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pod ? pod.actual : false,
        };
        const newPostPod = {
            id: `${_id}-step5`,
            icon: 'icon-containers',
            title: postpod && postpod.location && pod && pod.location && postpod.location.locode !== pod.location.locode
                ? `${postpod.location.name}, ${postpod.location.country} (${postpod.location.locode})`
                : 'POSTPOD',
            label: 'Est. Arr: ',
            date: postpod && postpod.date
                ? moment.utc(postpod.date).format('YYYY-MM-DD HH:mm')
                : (postpod && pod && postpod.location && pod.location && postpod.location.locode === pod.location.locode
                    ? moment.utc(pod.date).format('YYYY-MM-DD HH:mm')
                    : ''),
            showHours: true,
            actual: postpod ? postpod.actual : (pod ? pod.actual : false),
        };

        return [
            newPrepol,
            newPol,
            {
                id: `${_id}-step3`,
                icon: 'icon-ship',
                actual: pol.actual && pod.actual,
            },
            newPod,
            newPostPod,
        ];
    }

    const mappingSectionsData = sections => {
        if (!sections || !sections.length) return [];

        return sections.map(section => {
            return {
                title: section.name,
                icon: 'icon-containers',
                statuses: [
                    {
                        id: SHIPMENT_STATUS.INPROGRESS.toLowerCase(),
                        num: section.inprogressNumb
                    },
                    {
                        id: SHIPMENT_STATUS.PENDING.toLowerCase(),
                        num: section.pendingNumb
                    },
                    {
                        id: SHIPMENT_STATUS.STUCK.toLowerCase(),
                        num: section.stuckNumb
                    },
                    {
                        id: SHIPMENT_STATUS.DONE.toLowerCase(),
                        num: section.doneNumb
                    }
                ],
            };
        });
    }

    const handleStarShipment = (shipment) => {
        onStarShipment(shipment);
    }

    return shipment ? (
        <div className={`tr__active-shipment-item ${className}`}>
            <div className="tr__active-shipment-item--body">
                <div className="tr__active-shipment-item--header box-header d-flex align-items-center">
                    {shipment.routes && shipment.routes.pol && shipment.routes.pod && (
                        <h4 className="h4 f-medium">
                            {isArchive && !isDetails && shipment.status && (
                                <span className={`status-tag text-capitalize ${shipment.status.toLowerCase()}`}>{shipment.status==='COMPLETE' ? shipment.status.replace('COMPLETE', 'Completed') : shipment.status.replace('CANCELED', 'Cancelled') }</span>
                            )}
                            {/*{`${shipment.routes.pol.location.locode}-${shipment.routes.pod.location.locode}-${moment(shipment.departureDate).format('DDMMYYYY')} (${shipment._id.substr(shipment._id.length - 4, shipment._id.length - 1)})`}*/}
                            {shipment.shipmentName}
                        </h4>
                    )}
                    <div className="note d-flex">
                        {isEditBC ? (
                            <div className="edit-form d-flex align-items-center">
                                <Input
                                    value={shipmentNote}
                                    placeholder='Add note'
                                    onChange={e => setShipmentNote(e.target.value)}
                                    defaultValue={shipment.note ? shipment.note.content : ''}
                                />
                                <Button
                                    className="mlx1 outline"
                                    onClick={handleUpdateShipmentNote}
                                >
                                    Save
                                </Button>
                            </div>
                        ) : (
                            <>
                                {shipment.note && shipment.note.content ? (
                                    <span>{shipment.note.content}</span>
                                ) : ''}
                                <>
                                    <i className="icon-pencil" onClick={toggleEditBC} />
                                </>
                            </>
                        )}
                    </div>
                    <div className="shipment d-flex align-items-center">
                        <div className="info">
                            {!isArchive && (
                                <i className={shipment.shippingDetails.shipmentType === 'FCL' ? 'icon-container-solid' : 'icon-boxes-solid'} />
                            )}
                            <span>{shipment.shippingDetails.shipmentType}</span>
                            {(!!shipment.containersNumb || !!shipment.packetsNumb) && (
                                <span className='no-of-product'>
                                    {(shipment.containersNumb) ? `${shipment.containersNumb} Container` : `${shipment.packetsNumb} Packet`}{(shipment.containersNumb > 1 || shipment.packetsNumb > 1) ? 's' : ''}
                                </span>
                            )}
                        </div>
                        {shipment.openIssuesNumber > 0 && (
                            <span className="task-warning d-flex align-items-center mlx2">
                                <i className="icon-warning mrx1" />
                                <span>{shipment.openIssuesNumber} open</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="tr__active-shipment-item--steps d-flex" onClick={() => onShowDetails ? onShowDetails(shipment._id) : null}>
                    {getShipmentStep().length > 0 && getShipmentStep().map((step, stepIndex) => (
                        <ShipmentSteps
                            key={stepIndex}
                            step={step}
                            active={step.actual}
                            align={stepIndex > 2 ? 'right' : 'left'}
                        />
                    ))}
                </div>
            </div>
            <div className="tr__active-shipment-item--footer">
                {isDetails ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="shipment-teams d-flex align-items-center">
                            <i className="icon-shipper active" />
                            <i className="icon-box active" />
                            <i className="icon-truck" />
                            <i className="icon-box-export" />
                            <i className="icon-box-import" />
                            <i className="icon-export" />
                            <i className="icon-import" />
                        </div>
                        <Button
                            className="tr__link-shipment-create outline mrx3"
                            onClick={() => setAddLink(true)}
                        >
                            Create A Linked Shipment
                        </Button>
                    </div>
                ) : (
                    <>
                    {!isCardDetails && shipment && shipment.sections && shipment.sections.length > 0 && <>
                    <Collapse 
                        title="Shipment progress" 
                        className="blue-bg"
                        // disableHeaderClick={shipment && shipment.sections && shipment.sections.length > 0 ? false : true}
                    >
                        <div className="tr__active-shipment-item--details">
                            <ShipmentProgresses
                                progresses={mappingSectionsData(shipment.sections)}
                            />
                        </div>
                    </Collapse>
                    <ShipmentTags tags={getTags()}/></>}
                    {isCardDetails && <> role</>}
                    </>
                )}

            </div>
            {!isArchive && (
                <div 
                    className={`tr__active-shipment-item--star ${isStar ? 'active-star' : ''} d-flex justify-content-center align-items-center`}
                    onClick={() => handleStarShipment(shipment)}
                >
                    <i className="icon-star"></i>
                </div>
            )
            }
           
        </div>
    ) : '';
};

ShipmentCard.propTypes = {
    isDetails: PropTypes.bool,
    className: PropTypes.string,
    shipment: PropTypes.object,
    onShowDetails: PropTypes.func
};
