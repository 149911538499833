import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  ExtractedInfoFta,
  EditExtractedTable
} from 'components';
import { ModalContext, ExtractedContext, HomeContext, DocumentsContext } from 'contexts';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { SHIPMENT_INFO_URL } from 'actions';

export const ViewExtractedInfo = ({ open = false, onCancel }) => {
  const { fileId, isExtractedFta, setIsExtractedFta, isDocumentTab, setIsDocumentTab, isArchive } = useContext(HomeContext);
  const { viewExtractedInfo, setViewExtractedInfo,  setViewAllDocuments } = useContext(ModalContext);
  const { extractedData, fileData, doGetOcrData, setOcrData, doGetOcrFtaData, setExtractedFtaData, ocrFtaData, setOcrFtaData, extractedFtaData, doUpdateOcr } = useContext(ExtractedContext);
  const { documentPermission } = useContext(DocumentsContext);
  const [file, setFile] = useState("");
  const [sections, setSections] = useState([]);
  const { register, handleSubmit, errors, watch, getValues, clearErrors } = useForm();
  const location = useLocation();
  const history = useHistory();
  
  const handleCloseModal = () => {
    onCancel();
    setOcrData({});
    setOcrFtaData({});
    onReset();
  }

  const handleCancel = () => {
    setOcrFtaData({});
    setOcrData({});
    onReset();
  }

  const onReset = () => {
    setViewExtractedInfo(false);
    setIsExtractedFta(false);
    setIsExtractedFta(false);
    setIsDocumentTab(false);
  }

  const convertToOrcData = data => {
    let term = {};
    const sections = [...data.sections]; 
    sections.forEach(elm => {
      if(elm){
        let items = [];
        if(elm.items && elm.items.length > 0){
          elm.items.forEach(item => {
            if(item.key && item.key.trim() !== ''){
              const i = _.findIndex(items,{'key': item.key});
              if(items[i]){
                items[i] = {...items[i], value: items[i].value +", "+item.value};
              }else{
                items = [...items, {...item}];
              }
            }
          });
        }
        if(elm.isNewSection){
          term.newSections = {
            ...term.newSections,
          }
          term.newSections[elm.sectionName] = items.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value.trim() }), {});
        }else{
          term[elm.sectionName] = items.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value.trim() }), {});
        }
      }
    });
    const container = {
      ...term.Container,
      Volume: {
        Unit: term.Container.VolumeUnit,
        Value: term.Container.VolumeValue
      },
      Weight: {
        Unit: term.Container.WeightUnit,
        Value: term.Container.WeightValue
      }
    }
    delete term.Container;
    term.Containers = [container];
    doUpdateOcr(term,fileId);
  }

  const checkValidPermission = () => isArchive || documentPermission;

  const handleContinue = (data) => {
    if(isExtractedFta){
      if(!_.isEmpty(ocrFtaData)) {
        let ocrData = extractedFtaData.filter(item => item.id !== ocrFtaData.id);
        let exData = [];
        data.items.forEach(item => {
          const i = _.findIndex(exData,{'key': item.key});
          if(exData[i]){
            exData[i] = {...exData[i], value: exData[i].value +", "+item.value};
          }else{
            exData = [...exData, {...item}];
          }
        })
        let obj =exData.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value.trim() }), {});
        obj.id = ocrFtaData.id;
        ocrData = [...ocrData,obj];
        setExtractedFtaData(ocrData);
      }
      setOcrFtaData({});
    } else {
      if(extractedData.length > 0){
        let exData = [];
        const sections = [...data.sections.filter(i => i)];
        let term = {};
        term.newSections = [];
        sections.forEach(dt => {
          if(dt){
            if(dt.isNewSection){
              const items = dt.items.filter(i => i.key.trim() !== '');
              term.newSections.push({...dt, items});
            }else{
              if(dt.items && dt.items.length > 0){
                dt.items.forEach(item => {
                  const i = _.findIndex(exData,{'key': item.key});
                  if(exData[i]){
                    exData[i] = {...exData[i], value: exData[i].value +", "+item.value};
                  }else{
                    exData = [...exData, {...item}];
                  }
                })
              }
            } 
          }
        });
        term = exData.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value.trim() }), term);
        setOcrData(term);
        convertToOrcData(data);
      }
      if(isDocumentTab){
        const pathnameArr = location.pathname.split('/');
        const shipmentId = pathnameArr[pathnameArr.length - 2];
        history.push(SHIPMENT_INFO_URL.replace(':shipmentId', shipmentId));
        setViewAllDocuments(false);
      }
    }
    onReset();
  }

  const refreshOcrData = () => {
    if (!isExtractedFta){
      doGetOcrData(fileId);
    }else{
      doGetOcrFtaData(fileId);
    }
  }

  const handleAddSection = () => {
    let section = 1;
    if(sections.length > 0) section = Math.max(...sections) + 1;
    setSections(old => [...old, section]);
  }

  const handleRemoveSection = (section) => {
    setSections(old => [...old.filter(o => o !== section)]);
}

  /*eslint-disable */
  useEffect(() => {
    if (viewExtractedInfo && fileId) {
      if(!isExtractedFta){
        doGetOcrData(fileId);
      }else{
        doGetOcrFtaData(fileId);
        setSections([0]);
      }
    }
  }, [viewExtractedInfo])

  useEffect(() => {
    setFile(fileData);
  }, [fileData])

  useEffect(() => {
    if (extractedData.length > 0) {
      setSections([...Array(extractedData.length).keys()]);
    }
  }, [extractedData])
  /*eslint-enable */

  return (
    <Modal
      open={open}
      className="tr__edit-extracted-info no-padding"
      onCancel={handleCloseModal}
      title={isArchive ? "View Extracted Info" : 'View And Edit Extracted Info'}
      isBackDropClickable={false}
      footerLabel=""
    >
      <form onSubmit={handleSubmit(handleContinue)}>
      <div className="page-box">
        <div className="tr__edit-extracted-info--title mtx2 mbx3 d-flex f-medium align-items-center">
          {/* <h4 className="h4">Invoice No..:</h4> */}
          {/* <h2 className="h2 mlx1">9340019971</h2> */}
        </div>
        <div className="d-flex justify-content-between">
          <div className="tax-image">
            <embed src={file.data} width="800px" height="1000px" type={file.contentType} />
          </div>
          <div className="tax-info">
            {
              !isExtractedFta && extractedData.length > 0 ?
                sections.length > 0 && sections.map(section => {
                  return  <EditExtractedTable
                              extractedInfo={extractedData}
                              section={section}
                              key={section} 
                              register={register}
                              errors={errors}
                              isArchive={!checkValidPermission()}
                              watch={watch}
                              getValues={getValues}
                              clearErrors={clearErrors}
                              removeSection={handleRemoveSection}
                          />
                }) :
              isExtractedFta && !_.isEmpty(ocrFtaData) ?
                <ExtractedInfoFta 
                  register={register}
                  errors={errors} 
                  extractedInfo={ocrFtaData}
                  watch={watch}
                /> : 
                <div className="d-flex align-items-center">
                  <Button onClick={refreshOcrData}>Refresh</Button>
                  <p className="message plx2 f-medium">Reading OCR file might takes time. Please wait a moment!</p>
                </div>
            }
            
          </div>
        </div>
      </div>
      <div className="tr__add-link-task__footer d-flex align-items-center justify-content-between">
        {!isExtractedFta && checkValidPermission() &&
          <Button
            className="icon"
            type="primary"
            icon="icon-plus"
            onClick={() => handleAddSection()}
          >
            Add New Section
          </Button>
        }
        <div></div>
        <div>
          <p className="tr__link secondary f-medium" onClick={handleCancel}>Cancel</p>
          <Button isSubmitBtn disabled={!checkValidPermission()}>
            Save changes
          </Button>
        </div>
      </div>
      </form>
    </Modal>
  )
};

ViewExtractedInfo.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
}
