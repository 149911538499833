import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    RadioGroup,
    Dropdown,
    Datepicker,
    FileUploader,
    Checkbox,
    Button,
    CheckboxGroup
} from 'components';
import {
    roles,
    shipmentTypes,
    reuploadDocument,
    getErrorMessage,
    linksCheckbox,
} from 'utils';
import { AuthContext, HomeContext, ModalContext, OrganizationContext, TemplateContext, TrackShipmentContext } from 'contexts';
import { 
    DESTINATION,
    LOCODE_PAGE_SIZE,
    MARK_BOOKING_REQUEST,
    MARK_NEW,
    MARK_TRACK_SHIPMENT,
    MINE,
    ORGANIZATION_PAGE_SIZE,
    ORIGIN,
    TEMPLATE_PAGE_SIZE,
    MAX_FILE_SHIPMENT_DETAIL_DOCS,
    MAX_SIZE_SHIPMENT_DETAIL_DOC,
    FILE_TYPE_SHIPMENT_DETAIL_DOCS,
    FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
    ALL,
    DEFAULT,
} from 'actions';
import { ShipmentDocument } from 'components/pages/ActiveShipmentsDetails';

export const AddShipmentDetailsForm = ({
    register = () => {},
    errors,
    getValues = () => {},
    shipmentDetails,
    documents = [],
    setDocuments = () => {},
    isLinkedShipment = false,
    bookingRequest = {},
    documentFile = []
}) => {
    const { userInfo } = useContext(AuthContext);
    const { markAsShipmentType, markAsShipmentTemplate, trackerTemplate, setIdTemplate, idTemplate} = useContext(HomeContext);
    const { organizations, doGetOrganizations } = useContext(OrganizationContext);
    const { origins, setOrigins, destinations, setDestinations, firstLocode, doGetLocodes } = useContext(TrackShipmentContext);
    const { templates, doGetTemplates, doGetTemplatesDetails } = useContext(TemplateContext);
    const { setPreviewTempalates } = useContext(ModalContext);

    const [templateChooser, setTemplateChooser] = useState(false);
    const [roleOptions, setRoleOptions] = useState(isLinkedShipment ? roles.slice(0, 3) : roles.slice(0, 2));
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(reuploadDocument[0]);
    const [selectedShipmentType, setSelectedShipmentType] = useState(shipmentTypes[0]);
    const [orgLoading, setOrgLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [maxDepartureDate, setMaxDepartureDate] = useState();
    const [minArrivalDate, setMinArrivalDate] = useState();
    const [departureDate, setDepartureDate] = useState();
    const [arrivalDate, setArrivalDate] = useState();
    const [selectedOrigin, setSelectedOrigin] = useState();
    const [selectedDestination, setSelectedDestination] = useState();
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [selectedLink, setSelectedLink] = useState(linksCheckbox[0]);
    const doc = () => {
        let docs = documentFile.filter( doc => doc.type === DEFAULT);
        let dts = [];
        docs.forEach(doc =>{
            if(doc.documents.length > 0)
            dts = [...dts, ...doc.documents]
        } )
        return dts;
    }
    const [documentChecked, setDocumentChecked] = useState(doc());

     /*eslint-disable */

     useEffect(() => {
        setSelectedRole(roles.slice(0,1))
     },[])
     useEffect(() => {
        doGetOrganizations({ page: 0, isSearching: true });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        doGetTemplates({
            tab: MINE,
            limit: 9999,
            page: ALL,
        });

        if (firstLocode && firstLocode.length > 0) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }, [])

    useEffect(() => {
        if (templates && templates.length > 0 && shipmentDetails && shipmentDetails.templateId) {
            setSelectedTemplate(templates.find(t => t._id === shipmentDetails.templateId));
        }
    }, [templates])

    useEffect(() => {
        if (shipmentDetails) {
            const { creatorRole, routes, departureDate, arrivalDate, shippingDetails, orgId, documentType } = shipmentDetails;
            const sOrg = organizations.find(o => o._id === orgId);
            const currentRoleOption = roleOptions.find(r => r.id === creatorRole);
            const allRoleOption = creatorRole ? roles.find(r => r.id === creatorRole) : null;

            if (!currentRoleOption && allRoleOption) setRoleOptions(roles);
            setTemplateChooser(!!shipmentDetails.templateId);
            setSelectedRole(roles.slice(0, 1));
            setSelectedOrigin({
                key: routes?.pol?.location?.locode,
                value: `${routes?.pol?.location?.name}, ${routes?.pol?.location?.country} (${routes?.pol?.location?.locode})`,
                item: routes?.pol?.location
            });
            setSelectedDestination({
                key: routes?.pod?.location?.locode,
                value: `${routes?.pod?.location?.name}, ${routes?.pod?.location?.country} (${routes?.pod?.location?.locode})`,
                item: routes?.pod?.location
            });
            setDepartureDate(new Date(departureDate));
            setArrivalDate(new Date(arrivalDate));
            if (shippingDetails) setSelectedShipmentType(shipmentTypes.find(t => t.id === shippingDetails.shipmentType));
            setSelectedOrg(sOrg ? {
                key: sOrg._id,
                value: sOrg.name
            } : null);
            if (documentType) setSelectedDocument(reuploadDocument.find(d => d.id === documentType));
        }

        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {
                        const { pol, pod, prePol, postPod } = trackerTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: prePol.location.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: postPod.location.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedOrigin({
                            key: prePol.location.locode,
                            value: `${prePol.location.name}, ${prePol.location.country} (${prePol.location.locode})`,
                            item: prePol.location
                        });
                        setSelectedDestination({
                            key: postPod.location.locode,
                            value: `${postPod.location.name}, ${postPod.location.country} (${postPod.location.locode})`,
                            item: postPod.location
                        });
                        setDepartureDate(new Date(pol.date));
                        setArrivalDate(new Date(pod.date));
                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate) {
                        const { creatorRole, loadingPort, dischargePort, type } = markAsShipmentTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: loadingPort.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: dischargePort.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedRole(old => [...old, Object.assign(roles.find(r => r.id === creatorRole), {disabled: true}) ]);
                        setSelectedOrigin({
                            key: loadingPort.locode,
                            value: `${loadingPort.city}, ${loadingPort.country} (${loadingPort.locode})`,
                            item: loadingPort
                        });
                        setSelectedDestination({
                            key: dischargePort.locode,
                            value: `${dischargePort.city}, ${dischargePort.country} (${dischargePort.locode})`,
                            item: dischargePort
                        });
                        setSelectedShipmentType(shipmentTypes.find(t => t.id === type));

                        if (bookingRequest) {
                            // const { departureDate, arrivalDate } = shipmentDetails;
                            setDepartureDate(bookingRequest && from ? new Date(from?.date) : "");
                            setArrivalDate(bookingRequest && to ? new Date(to?.date)  : "");
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate, shipmentDetails])

    useEffect(() => {
        if (shipmentDetails && organizations && organizations.length > 0) {
            if (shipmentDetails.orgId) {
                const sOrg = organizations.find(o => o._id === shipmentDetails.orgId);
                if (sOrg) {
                    setSelectedOrg({
                        key: sOrg._id,
                        value: sOrg.name
                    })
                }
            }
        }
    }, [shipmentDetails, organizations])
    /*eslint-enable */

    const from = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[0].start;
    const to = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[bookingRequest.linkedVesselSchedule.legs.length - 1].end;

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setDocuments(oldFiles => [...oldFiles, ...fileList])
    }

    const handleChooseOption = (item) => {
        setIdTemplate(item.key);
        // doGetTemplatesDetails(item.key);
    }

    const handleFileRemove = file => {
        if (!file) return;
        setDocuments(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const handleShowAllRoles = () => {
        setRoleOptions(roles);
    }

    const onSearchOrganization = (value) => {
        setOrgLoading(true);
        doGetOrganizations({
			page: 0,
			limit: ORGANIZATION_PAGE_SIZE,
            name: value.trim(),
			isSearching: true
		}, () => {
			setOrgLoading(false);
		}, false);
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }
    const onSearchTemplate = (value) => {
        setTemplateLoading(true);

        doGetTemplates({
            limit: TEMPLATE_PAGE_SIZE,
            name: value.trim(),
            tab: MINE
        }, () => {
            setTemplateLoading(false);
        }, false);
    }

    const isMarkNewShipment = () => markAsShipmentType === MARK_NEW;
    const isMarkTrackShipment = () => markAsShipmentType === MARK_TRACK_SHIPMENT;
    const isMarkBRShipment = () => markAsShipmentType === MARK_BOOKING_REQUEST;

    return (
        <div className="tr__add-shipment-details-form">
            <div className="tr__add-shipment-details-form--group width-form mbx4">
                <Dropdown
                    className="select big-label"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="organization"
                    label="Choose Your Organization"
                    defaultValue={selectedOrg}
                    options={organizations && organizations.length > 0 ? organizations.map(org => {
                        return {
                            key: org._id,
                            value: org.name
                        }
                    }) : []}
                    placeholder="Select Organization"
                    dropdownPosition="center"
                    onInputChange={onSearchOrganization}
                    searchLoading={orgLoading}
                    // refs={register({ required: !markAsShipmentTemplate })}
                    refs={register({ required: true })}
                    error={!!errors.organization}
                    errorMessage={getErrorMessage(errors.organization, "Organization")}
                />
                {userInfo && userInfo.orgTeams && userInfo.orgTeams.length === 0 && (
                    <p className="text-error mtx3">You don't belong to any organizations!</p>
                )}
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                {/* <RadioGroup
                    label="Your role in this shipment"
                    name="shipmentRole"
                    className="big-label"
                    horizontal
                    items={roleOptions}
                    value={selectedRole}
                    disabled={isMarkBRShipment()}
                    onChange={isMarkBRShipment() ? null : role => setSelectedRole(role)}
                    refs={register({ required: true })}
                    error={!!errors.shipmentRole}
                    errorMessage={getErrorMessage(errors.shipmentRole, "Shipment Role")}
                /> */}
                <CheckboxGroup
                    label="Your role in this shipment"
                    className="big-label"
                    name="shipmentRole"
                    type="checkbox"
                    horizontal
                    items={roleOptions}
                    values={selectedRole}
                    refs={register({ required: true })}
                    error={!!errors.shipmentRole}
                    errorMessage={getErrorMessage(errors.taskRoles, "task Roles")}
                    onChange={roles => setSelectedRole(roles)}
                    // disabled={isMarkBRShipment()}
                    buttonMode={true}
                    
                />
               {!isLinkedShipment && roleOptions.length < roles.length && (
                    <p
                        className="tr__link"
                        onClick={handleShowAllRoles}
                    >
                        Select other role
                    </p>
                )}


            </div>
            
            {isLinkedShipment ? '' : <div className="tr__add-shipment-details-form--group mbx4">
                <RadioGroup
                    label="Select type of shipment"
                    name="shipmentType"
                    className="big-label"
                    horizontal
                    items={shipmentTypes}
                    value={selectedShipmentType}
                    disabled={isMarkBRShipment()}
                    onChange={isMarkBRShipment() ? null : type => setSelectedShipmentType(type)}
                    refs={register({ required: true })}
                    error={!!errors.shipmentType}
                    errorMessage={getErrorMessage(errors.shipmentType, "Shipment Type")}
                />
            </div>}
                    
            {isLinkedShipment ? '' :   <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="originPort"
                        label="Origin port"
                        comparable={true}
                        placeholder="You can search by name or port code"
                        disabled={!isMarkNewShipment()}
                        dropdownPosition="center"
                        error={!!errors.originPort}
                        defaultValue={selectedOrigin}
                        errorMessage={getErrorMessage(errors.originPort, "Origin", "Origin port and Destination port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('destinationPort') })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                </div>
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destinationPort"
                        label="Destination port"
                        comparable={true}
                        disabled={!isMarkNewShipment()}
                        placeholder="You can search by name or port code"
                        dropdownPosition="center"
                        error={!!errors.destinationPort}
                        defaultValue={selectedDestination}
                        errorMessage={getErrorMessage(errors.destinationPort, "Destination Port", "Origin port and Destination port can't be the same")}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        refs={register({ required: true, validate: value => value !== getValues('originPort') })}
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
            </div>}
            {isLinkedShipment ? "" :   <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Datepicker
                        name="vesselDepartureDate"
                        label="Vessel departure date"
                        placeholder="Add departure date"
                        disabled={isMarkTrackShipment() || from}
                        start={departureDate}
                        error={!!errors.vesselDepartureDate}
                        errorMessage={getErrorMessage(errors.vesselDepartureDate, "Vessel Departure Date")}
                        refs={register({ required: true })}
                        maxDate={maxDepartureDate}
                        onDatesChange={dates => setMinArrivalDate(dates)}
                    />
                </div>
                <div className="col-6">
                    <Datepicker
                        name="vesselArrivalDate"
                        label="Vessel arrival date"
                        placeholder="Add arrival date"
                        disabled={isMarkTrackShipment() || to}
                        start={arrivalDate}
                        error={!!errors.vesselArrivalDate}
                        errorMessage={getErrorMessage(errors.vesselArrivalDate, "Vessel Arrival Date")}
                        refs={register({ required: true })}
                        minDate={minArrivalDate}
                        onDatesChange={dates => setMaxDepartureDate(dates)}
                    />
                </div>
            </div>}
          
            {/*** Upload document ***/}

            {isLinkedShipment ? <div className="mbx4">  
            <div className="col-12 mtx4">
                    <RadioGroup
                        label="Shipment Information"
                        className="large-label"
                        name="vesselType"
                        buttonMode={false}
                        type="default"
                        horizontal
                        items={linksCheckbox}
                        value={selectedLink}
                        onChange={e => setSelectedLink(e)}
                        description="All shipment information will be kept as original shipment. You can always edit later. Do you want to keep all the documents from SGSIN-AUSYD-25092019 (1234)?"
                    />
                </div>
                {selectedLink.id === 'yes' && (
                    <div className="col-12 mtx2 tr__add-link__list-file">
                        <ShipmentDocument
                            icon="icon-file"
                            values={documentChecked}
                            items={doc()}
                            onChange={(docs => setDocumentChecked(docs))}
                        />
                    </div>
            )} </div> : ""}

            
            {isLinkedShipment ? '' : <>  
                <div className="tr__add-shipment-details-form--group">
                    <RadioGroup
                        name="documentType"
                        buttonMode={false}
                        label="Upload Document"
                        className='big-label'
                        type="default"
                        horizontal
                        items={reuploadDocument}
                        value={selectedDocument}
                        error={!!errors.documentType}
                        errorMessage={getErrorMessage(errors.documentType, "Document Type")}
                        refs={register({ required: true })}
                        onChange={doc => setSelectedDocument(doc)}
                    />
                </div>
                <div className="tr__add-shipment-details-form--group mbx4">
                    <FileUploader
                        name="shipmentDetailsDocs"
                        className="big-label"
                        handleDrop={handleFileUpload}
                        handleRemove={handleFileRemove}
                        uploadedFiles={documents}
                        mode="list"
                        multiple={false}
                        error={!!errors.shipmentDetailsDocs}
                        errorMessage={getErrorMessage(errors.shipmentDetailsDocs, "ShipmentDetails Docs Type")}
                        refs={register}
                        outputFormat='base64'
                        limitNumberUploadFile={MAX_FILE_SHIPMENT_DETAIL_DOCS}
                        limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                        fileTypeApproved={['image', 'pdf']}
                        uploadInner={true}
                        uploadFileType={FILE_TYPE_SHIPMENT_DETAIL_DOCS}
                        uploadFileSubType={FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS}
                        ocrRequired={true}
                    />
                </div>
            </>}
            {/*** !!! Upload document ***/}


            <div className="tr__add-shipment-details-form--group mbx4">
                <div className="template-chooser">
                    <div className="template-chooser--header d-flex align-items-center justify-content-between">
                        <h4 className="h4 f-medium">
                            <span>Choose Template</span>
                        </h4>
                        {templates && templates.length > 0 && (
                            <Checkbox
                                name="templateChooser"
                                type="checkbox"
                                checked={templateChooser}
                                className="toggle right"
                                onChange={() => setTemplateChooser(!templateChooser)}
                                disabled={!(templates && templates.length > 0)}
                            />
                        )
                        //  :
                        //  (
                        //     <small className="f-regular">There is no template available</small>
                        // )
                        }
                    </div>
                    {templateChooser && (
                        <div className="template-chooser--content mtx2 d-flex align-items-center justify-content-between">
                            <Dropdown
                                className="select mrx4"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                name="template"
                                options={templates && templates.length > 0 ? templates.map(temp => {
                                    return {
                                        key: temp._id,
                                        value: temp.name,
                                    }
                                }) : []}
                                defaultValue={selectedTemplate ? {
                                    key: selectedTemplate._id,
                                    value: selectedTemplate.name
                                } : null}
                                mode="input"
                                placeholder="Select template"
                                dropdownPosition="top full"
                                onInputChange={onSearchTemplate}
                                onChange={(item) => {handleChooseOption(item)}}
                                error={!!errors.template}
                                errorMessage={getErrorMessage(errors.template, "Template")}
                                refs={register({ required: true })}
                                searchLoading={templateLoading}
                            />
                            <Button
                                className="small"
                                disabled={!idTemplate}
                                onClick={() => {
                                    setPreviewTempalates(true);
                                    doGetTemplatesDetails(idTemplate);
                                }}
                            >
                                Preview
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
AddShipmentDetailsForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    getValues: PropTypes.func,
    shipmentDetails: PropTypes.object,
    documents: PropTypes.array,
    setDocuments: PropTypes.func,
    bookingRequest: PropTypes.object
};
