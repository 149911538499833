import React, { useContext, useState, useEffect } from 'react';
import './index.scss';
import {
    Modal
} from 'components';
import { AssigneeList } from '../components';
import { CollaboratorsContext, HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { MEMBER, TASK_PAGE_SIZE, TEAM } from 'actions';
import { useLocation } from 'react-router-dom';

export const AllCollaborators = ({ open = false, onCancel }) => {
    const { setCollaboratorId, isTemplate, currentTabManagement } = useContext(HomeContext);
    const { setRemoveCollaborator } = useContext(ModalContext);
    const { collaborators } = useContext(CollaboratorsContext);
    const { collaboratorsTemp, doGetTemplatesDetails, templatesDetails }  = useContext(TemplateContext);
    const { doGetTaskManagements } = useContext(ManagementContext);
    const [members,setMembers] = useState([]);
    const [teams,setTeams] = useState([]);
    const [searchValueMember, setSearchValueMember] = useState("");
    const [searchValueTeam, setSearchValueTeam] = useState("");
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    
    /*eslint-disable */
    useEffect(() => {
        if (isTemplate === true) {
            setMembers(collaboratorsTemp.filter(collaborator => collaborator.type === MEMBER.toUpperCase())
                .map(collaborator => {
                    if (collaborator.base?.delete) {
                        collaborator.name = "deleted_" + collaborator._id;
                    }
                    return collaborator;
                })
                .filter(collaborator => collaborator?.name?.includes(searchValueMember) || (collaborator?.email ? collaborator?.email?.includes(searchValueMember) : (
                collaborator?.orgName && collaborator?.orgName?.includes(searchValueMember)))));
            setTeams(collaboratorsTemp.filter(collaborator => collaborator.type === TEAM.toUpperCase())
                .map(collaborator => {
                    if (collaborator.base?.delete) {
                        collaborator.name = "deleted_" + collaborator._id;
                    }
                    return collaborator;
                })
                .filter(collaborator => collaborator?.name?.includes(searchValueTeam) || (collaborator?.email ? collaborator?.email?.includes(searchValueTeam) : (
                collaborator?.orgName && collaborator?.orgName?.includes(searchValueTeam)))));
        } else {
            setMembers(collaborators.filter(collaborator => collaborator.type === MEMBER.toUpperCase())
                .map(collaborator => {
                    if (collaborator.base?.delete) {
                        collaborator.name = "deleted_" + collaborator._id;
                    }
                    return collaborator;
                })
                .filter(collaborator => collaborator?.name?.includes(searchValueMember) || (collaborator?.email ? collaborator?.email?.includes(searchValueMember) : (
                collaborator?.orgName && collaborator?.orgName?.includes(searchValueMember)))));
            setTeams(collaborators.filter(collaborator => collaborator.type === TEAM.toUpperCase())
                .map(collaborator => {
                    if (collaborator.base?.delete) {
                        collaborator.name = "deleted_" + collaborator._id;
                    }
                    return collaborator;
                })
                .filter(collaborator => collaborator?.name?.includes(searchValueTeam) || (collaborator?.email ? collaborator?.email?.includes(searchValueTeam) : (
                collaborator?.orgName && collaborator?.orgName?.includes(searchValueTeam)))));
        }

    }, [collaborators, searchValueMember, searchValueTeam, collaboratorsTemp])
    /*eslint-enable */
    const handleRemoveMember = (member) => {
        setCollaboratorId(member._id);
        setRemoveCollaborator(true);
    }
    return (
        <Modal
            open={open}
            className="tr__all-collaborators"
            onCancel={() => {
                const params = {
                    page: 0,
                    limit: TASK_PAGE_SIZE,
                    tab: currentTabManagement
                }
                if (isTemplate) {
                    doGetTemplatesDetails(templatesDetails && templatesDetails.id)
                } else {
                    doGetTaskManagements(shipmentId, params, () => { });

                }
                onCancel()
                setSearchValueMember("");
                setSearchValueTeam("");
            }}
            title='All Collaborators'
            isBackDropClickable={false}
        >
            <AssigneeList
                title="Individuals"
                assignees={members}
                onRemove={handleRemoveMember}
                setSearchValue={setSearchValueMember}
                searchValue={searchValueMember}
                characters={25}
            />
            <AssigneeList
                className="mtx3"
                title="Teams"
                assignees={teams}
                onRemove={handleRemoveMember}
                hasOrgName={true}
                setSearchValue={setSearchValueTeam}
                characters={15}
                searchValue={searchValueTeam}
            />
        </Modal>
    )
};
