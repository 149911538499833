import React, { useContext } from 'react';

import './index.scss';
import {
    Modal
} from 'components';
import { FtaAdvisorContext } from 'contexts';

export const DocumentModal = ({ open = false, onCancel }) => {
    const {documentFta} = useContext(FtaAdvisorContext);

    return (
        <Modal
            open={open}
            className="tr__document-modal no-padding"
            onCancel={onCancel}
            title='Document'
            isBackDropClickable={false}
        >
            <div className="tr__document-modal--item mbx1">
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Category</p>
                    <p className="value">{documentFta.docCategory ? documentFta.docCategory : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Code</p>
                    <p className="value">{documentFta.docCode ? documentFta.docCode : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Notes</p>
                    <p className="value">{documentFta.docExpNotes ? documentFta.docExpNotes : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Name</p>
                    <p className="value">{documentFta.docName ? documentFta.docName : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Responsible Party</p>
                    <p className="value">{documentFta.docResponsibleParty ? documentFta.docResponsibleParty : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">Source</p>
                    <p className="value">{documentFta.docSource ? documentFta.docSource : ''}</p>
                </div>
            </div>
        </Modal>
    )
};