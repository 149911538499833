import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const InfoBlock = ({ className = '', title, children }) => {
    return (
        <div className={`tr__info-block ${className}`}>
            {title && (
                <h3 className="h3 f-medium tr__info-block--label d-flex align-items-center mbx2">
                    <span>{title}</span>
                </h3>
            )}
            <div className="tr__info-block--content">
                <div className="row">
                    {children}
                </div>
            </div>
        </div>
    )
};

InfoBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
}