import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { ManagementContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { ARCHIVE_URL, CANCELED } from 'actions';

export const ConfirmStatusShipment = ({
    open = false,
    onCancel,
    ...rest
}) => {
    const history = useHistory();
    const { statusShipment, doChangeStatusShipment } = useContext(ManagementContext);
    const handleSubmit = () => {
        const payload = {
            status: statusShipment.status
        }
        doChangeStatusShipment(statusShipment.shipmentId, payload, () => {
            history.push(ARCHIVE_URL);
        })
        onCancel();
    }

    return (
        <Modal
            open={open}
            className="tr__delete-confirmation"
            onCancel={onCancel}
            title={statusShipment && statusShipment.status === CANCELED ? 'Cancel shipment' : 'Mark as complete'}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
            {...rest}
        >
            <div className="text-center delete-message">
                <h6 className="h6 mtbx2 f-medium">
                    {statusShipment && statusShipment.status === CANCELED 
                        ? 'Cancelled shipment will be moved to Archive list and stored for 60 days. Do you want to continue?' 
                        : 'Completed shipment will be moved to Archive list and stored for 60 days. Do you want to continue?'
                    }
                </h6>
            </div>
        </Modal>
    )
};

ConfirmStatusShipment.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
};