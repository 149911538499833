import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useHistory } from 'react-router-dom';
import { TemplateContext } from 'contexts';
import { MINE, OTHERS, TEMPLATE_PAGE_SIZE } from 'actions';

export const TemplateHeader = ({ className = '', backUrl = '/', name, description, isMyTemplate }) => {
    const history = useHistory();
    const { doGetTemplates } = useContext(TemplateContext);
    const handleGoBack = () => {
        history.goBack();
        doGetTemplates({ 
            tab: isMyTemplate ? MINE.toUpperCase() : OTHERS.toUpperCase(), 
            limit: TEMPLATE_PAGE_SIZE, 
            page: 0,
        });
    }
    return (
        <div className={`tr__template-header d-flex align-items-center ${className}`}>
            <div className="tr__template-header--title d-flex align-items-center">
                <p onClick={handleGoBack} className="tr__link tag">
                    <i className="icon-chevron-left" />
                    <span>Back</span>
                </p>
                <div className="mlx2">
                    <h2 className="h2 f-medium mbx1 has-tooltip">{name}</h2>
                    <h6 className="text-grey f-medium has-tooltip">{description}</h6>
                </div>
            </div>
        </div>
    )
};

TemplateHeader.propTypes = {
    className: PropTypes.string
};