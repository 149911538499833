import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce } from 'lodash';

import './index.scss';
import { Button, Input } from 'components';
import { DEBOUNCE_TIME } from 'actions';
import { CSVLink } from "react-csv";


export const DetailsTableHeader = ({
    label = '',
    rightLabel,
    onAddNewClick,
    title = '',
    labelButton = '',
    buttonAdd = true,
    backUrl = () => { },
    actionButton = true,
    isExportCSV,
    onSearch = () => { },
    debounceTime = DEBOUNCE_TIME,
    searchLoading = false,
    exportData,
    fileName = '',
    onExportCSV
}) => {
    const [searchTerm, setSearchTerm] = useState();

    const onInput = debounce(value => onSearch(value), debounceTime);

    const csvInstance  = useRef();
    const [csvData, setCsvData] = useState([]);

    const headers = [
        { label: "Name", key: "username" },
        { label: "Email", key: "email" },
        { label: "Activities", key: "activities" },
        { label: "Date & Time", key: "dateTime" }
    ];
    /*eslint-disable*/
    useEffect(() => {
        if (csvData && csvInstance && csvInstance?.current && csvInstance?.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
      }, [csvData]);
      /*eslint-enable */

    const handleExportCsv = () => {
        if (onExportCSV) onExportCSV(data => {
            if (data && data.items && data.items.length > 0) {
                setCsvData(data.items.map(item => {
                    return {
                        username: item.username,
                        email: item.email,
                        activities: item.description,
                        dateTime: moment(item.dateTime).format('DD MMM YYYY | hh:mm A')
                    }
                }));   
            }
        });
        else return false;
    }

    return (
        <div className="tr__details-table-header d-flex align-items-center justify-content-between">
            <div className="tr__details-table-header__title d-flex align-items-center">
                {backUrl && (
                    <div className="tr__link tag mrx2 text-center" onClick={backUrl}>
                        <i className="icon-chevron-left mrx1" />
                        <span className="f-medium">Back</span>
                    </div>
                )}
                <div>
                    <h2 className="h2 f-medium ">{title}</h2>
                    {label && <h6 className="h6 f-medium text-grey mtx1">{label}</h6>}
                </div>
            </div>
            {actionButton && <div className="d-flex align-items-center justify-content-between">
                <Input
                    icon="icon-search"
                    iconPosition="right"
                    name="organizationSearch"
                    placeholder="Search"
                    onIconClick={() => onInput(searchTerm)}
                    loading={searchLoading}
                    onKeyPress={e => {
                        if (e.which === 13) onInput(searchTerm);
                    }}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                {buttonAdd && (
                    <Button
                        className="icon"
                        onClick={onAddNewClick}
                    >
                        <i className="icon-plus" />
                        <span>{labelButton}</span>
                    </Button>
                )}


            </div>
            }
            {isExportCSV && (
                <>
                    <Button onClick={handleExportCsv}>
                        Export to CSV
                    </Button>
                    {csvData.length > 0 &&
                        <CSVLink
                            data={csvData}
                            headers={headers}
                            filename={`${fileName}_${moment().format('YYMMDD')}.csv`}
                            ref={csvInstance}
                        >
                        </CSVLink>
                    }
                    
                    
                </>


            )}
            {rightLabel && (
                <h6 className="h6 f-regular right-label">{rightLabel}</h6>
            )}
        </div>
    )
};

DetailsTableHeader.propTypes = {
    label: PropTypes.string,
    onAddNewClick: PropTypes.func,
    buttonAdd: PropTypes.bool,
    rightLabel: PropTypes.string,
    backUrl: PropTypes.func,
    onSearch: PropTypes.func,
    searchLoading: PropTypes.bool,
    exportData: PropTypes.array,
    fileName: PropTypes.string,
    onExportCSV: PropTypes.func,
};